import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// AUTHORIZATION
import { updateOpenModalUnsavedChangesDefinition } from "../../../redux/configurationFiles/configurationFilesSlice";
// UTILS
import { styleDeleteButton } from "../../../utils/utils";

library.add(faTrash, faTriangleExclamation);

function DefinitionsModalUnsaveChanges({ onClickSave, onClickDiscart }: any) {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const { openModalUnsavedChangesDefinition } = useSelector((state: any) => state.configurationFile);
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickOk = () => {
		dispatch(updateOpenModalUnsavedChangesDefinition(false));
		onClickSave();
	};

	const onClickClose = () => {
		dispatch(updateOpenModalUnsavedChangesDefinition(false));
		onClickDiscart();
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {}, [openModalUnsavedChangesDefinition]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal
			onOk={onClickClose}
			closable={false}
			onCancel={onClickClose}
			open={openModalUnsavedChangesDefinition}
			footer={null}
		>
			<Spin spinning={false}>
				<div>
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faTriangleExclamation}
							className='generalStyles__warning generalStyles__deleteItemIcon'
						/>
						<p className='generalStyles__warning mt-3'>{t("general.unsavedChangesDescription1")}</p>
						<p style={{ marginTop: "-13px" }}>{t("general.unsavedChangesDescription2")}</p>
					</div>
					<div className='generalStyles__flexEnd mt-4'>
						<div>
							<Button onClick={onClickClose} className='buttonStyle__4 generalStyles__mrFix'>
								{t("general.discard")}
							</Button>
							<Button className={styleDeleteButton(false, theme)} onClick={onClickOk}>
								{t("general.save")}
							</Button>
						</div>
					</div>
				</div>
			</Spin>
		</Modal>
	);
}

export default DefinitionsModalUnsaveChanges;
