import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import GroupsTable from "./GroupsTable";
import GroupDeleteModal from "./GroupDeleteModal";
import GroupsDrawer from "./GroupsDrawer";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";

const GroupsPage = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("view-edge-management-groups", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<GroupsTable />
					<GroupDeleteModal />
					<GroupsDrawer />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default GroupsPage;
