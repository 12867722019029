import React, { useEffect, useState } from "react";
// NAVIGATION
import { useLocation } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Table, Tag, Switch, Button, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	useLazyGetUsersByOrganizationQuery,
	usePostAssignNotificationToUserMutation,
	useDeleteAssignNotificationToUserMutation,
	notificationAPI,
} from "../../../redux/notifications/notificationAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
import GlobalAlert2 from "../../home/GlobalAlert2";
import NotificationUsersTableActions from "./NotificationUsersTableActions";
// INTERFACES
import { RootState } from "../../../app/store";
import { UserInterface, NotificationInterface } from "../../../interfaces/Notification.interface";

function NotificationUsersTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Column } = Table;
	const location = useLocation();
	const idOrganization = new URLSearchParams(location.search).get("idOrganization");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [users, setUsers] = useState<UserInterface[]>([]);
	const [total, setTotal] = useState(0);
	const [responseError, setResponseError] = useState<any>();
	const [disableSwitch, setDisableSwitch] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { notification } = useSelector((state: RootState) => state.notification);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetUsers,
		{ data: dataGetUsers, error: errorGetUsers, isError: isErrorGetUsers, isLoading: isLoadingGetUsers },
	] = useLazyGetUsersByOrganizationQuery();

	const [
		triggerPostAssignNotification,
		{
			isLoading: isLoadingPostAssign,
			isSuccess: isSuccessPostAssign,
			isError: isErrorPostAssign,
			error: errorPostAssign,
		},
	] = usePostAssignNotificationToUserMutation();

	const [
		triggerDeleteAssignNotification,
		{ isLoading: isLoadingPutAssign, isSuccess: isSuccessPutAssign, isError: isErrorPutAssign, error: errorPutAssign },
	] = useDeleteAssignNotificationToUserMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const postUser = async (user: UserInterface, notificationType: string) => {
		const index = notification.notifications.findIndex((x: any) => x.type === notificationType);
		const token = await GETJwtToken();
		triggerPostAssignNotification({
			token,
			body: {
				// muted: notification.notifications[index].muted,
				muted: notification.notifications[index].muted,
				notification_id: notification.notifications[index].id,
				user_id: user.id,
			},
		});
	};

	const putUser = async (user: UserInterface, notificationType: string) => {
		const index = notification.notifications.findIndex((x: any) => x.type === notificationType);
		const token = await GETJwtToken();
		triggerDeleteAssignNotification({
			token,
			notificationId: notification.notifications[index].id,
			userId: user.id,
		});
	};

	const getInitialData = async () => {
		dispatch(notificationAPI.util.resetApiState());
		const token = await GETJwtToken();
		const searchAndFilterString = `?filter_target=organization_id&filter=${idOrganization}&page=1&limit=99999`;
		triggerGetUsers({
			token,
			searchAndFilterString,
		});
	};

	const checkIfUserHasNotification = (user: UserInterface, notificationType: string) => {
		let notificationExist = false;
		if (
			user &&
			user.notifications &&
			user.notifications.length > 0 &&
			notification &&
			notification.notifications &&
			notification.notifications.length > 0
		) {
			user.notifications.map((item: NotificationInterface) => {
				if (
					item &&
					item.type === notificationType &&
					notification.notifications.some((obj: NotificationInterface) => obj.id === item.id)
				) {
					notificationExist = true;
				}
				return true;
			});
		}
		return notificationExist;
	};

	const buildUsers = (usersResponse: UserInterface[]) => {
		const newUsers: any = [];
		usersResponse.map((user: UserInterface) => {
			newUsers.push({
				isActiveWhatsapp: checkIfUserHasNotification(user, "WHATSAPP"),
				isActiveEmail: checkIfUserHasNotification(user, "EMAIL"),
				isActiveSms: checkIfUserHasNotification(user, "SMS"),
				...user,
			});
			return true;
		});
		setUsers(newUsers);
	};

	const disableNotificationSwitch = () => {
		let disableItem = true;
		if (notification && notification.notifications && notification.notifications.length > 0) {
			disableItem = false;
		}
		setDisableSwitch(disableItem);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(() => {
		if (errorGetUsers || errorPostAssign || errorPutAssign) {
			setResponseError(errorGetUsers || errorPostAssign || errorPutAssign);
		}
	}, [errorGetUsers, errorPostAssign, errorPutAssign]);

	useEffect(() => {
		if (dataGetUsers && dataGetUsers.data) {
			buildUsers(dataGetUsers.data);
		}
		if (dataGetUsers && dataGetUsers.total) {
			setTotal(dataGetUsers.total);
		}
	}, [dataGetUsers]);

	useEffect(() => {
		disableNotificationSwitch();
	}, [notification]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='generalStyles__mlFix mt-1'>
				<Button
					className='buttonStyle__8'
					icon={<FontAwesomeIcon icon={faArrowsRotate} className='generalStyles__mrFix' />}
					onClick={() => {
						getInitialData();
					}}
				>
					{t("general.refreshTable")}
				</Button>
			</div>
			<Table
				locale={{
					emptyText: (
						<TableNoDataBox
							isError={isErrorGetUsers}
							errorMessage={
								responseError && responseError.data && responseError.data.message ? responseError.data.message : "Error"
							}
							noItemsFound='No data found'
						/>
					),
				}}
				loading={isLoadingGetUsers || isLoadingPostAssign || isLoadingPutAssign}
				rowClassName={(record, index) => getTableRowClass(index, theme)}
				dataSource={users}
				size='small'
				className='mt-3'
				pagination={{
					showSizeChanger: true,
					pageSizeOptions: ["10", "20", "50", "100"],
				}}
				rowKey={(record) => record.email}
				scroll={{ x: 900 }}
				footer={() => (
					<div className='generalStyles__flexEnd'>
						<Tag>{total} Records</Tag>
					</div>
				)}
			>
				{/** USER EMAIL */}
				<Column
					title={t("general.email")}
					dataIndex='email'
					key='email'
					sorter={(a: UserInterface, b: UserInterface) => a.email.localeCompare(b.email)}
					render={(text: string, record: UserInterface) => <div>{record && record.email ? record.email : "--"}</div>}
				/>
				{/** SMS */}
				<Column
					title='SMS'
					dataIndex='isActiveSms'
					key='isActiveSms'
					render={(value, record: UserInterface) => (
						<Tooltip placement='topLeft' title={disableSwitch ? t("general.notificationNotAvailable") : ""}>
							<Switch
								disabled={disableSwitch}
								checked={value}
								onChange={(checked: boolean) => {
									if (checked) {
										postUser(record, "SMS");
									} else {
										putUser(record, "SMS");
									}
								}}
							/>{" "}
						</Tooltip>
					)}
				/>
				{/** EMAIL */}
				<Column
					title='Email'
					dataIndex='isActiveEmail'
					key='isActiveEmail'
					render={(value, record: UserInterface) => (
						<Tooltip placement='topLeft' title={disableSwitch ? t("general.notificationNotAvailable") : ""}>
							<Switch
								disabled={disableSwitch}
								checked={value}
								onChange={(checked: boolean) => {
									if (checked) {
										postUser(record, "EMAIL");
									} else {
										putUser(record, "EMAIL");
									}
								}}
							/>
						</Tooltip>
					)}
				/>
				{/** WHATSAPP */}
				<Column
					title='Whatsapp'
					dataIndex='isActiveWhatsapp'
					key='isActiveWhatsapp'
					render={(value, record: UserInterface) => (
						<Tooltip placement='topLeft' title={disableSwitch ? t("general.notificationNotAvailable") : ""}>
							<Switch
								disabled={disableSwitch}
								checked={value}
								onChange={(checked: boolean) => {
									if (checked) {
										postUser(record, "WHATSAPP");
									} else {
										putUser(record, "WHATSAPP");
									}
								}}
							/>
						</Tooltip>
					)}
				/>
				{/** SCHEDULES */}
				<Column
					title={t("general.schedules")}
					dataIndex='schedules'
					key='schedules'
					render={(value, record: UserInterface) => (
						<>
							{record && record.schedule && record.schedule.availability && record.schedule.availability.length > 0 ? (
								<Tag className='generalStyles__width50px generalStyles__textCenter' color='green'>
									<FontAwesomeIcon icon={faCircleCheck} />
								</Tag>
							) : (
								<Tag className='generalStyles__width50px generalStyles__textCenter' color='red'>
									<FontAwesomeIcon icon={faCircleXmark} />
								</Tag>
							)}
						</>
					)}
				/>
				{/** ACTIONS */}
				<Column
					title={t("adapter.actions")}
					key='action'
					width='100px'
					render={(_, record: UserInterface) => <NotificationUsersTableActions selectedItem={record} />}
				/>
			</Table>
			<GlobalAlert2
				isError={isErrorPostAssign}
				isSuccess={isSuccessPostAssign}
				requestType='POST'
				error={errorPostAssign}
				name={t("general.assignNotification")}
			/>
			<GlobalAlert2
				isError={isErrorPutAssign}
				isSuccess={isSuccessPutAssign}
				requestType='PUT'
				error={errorPutAssign}
				name={t("general.assignNotification")}
			/>
		</div>
	);
}

export default NotificationUsersTable;
