import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faUserShield,
	faBuildingCircleCheck,
	faUserLock,
	faCheckCircle,
	faBan,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerLift } from "../../../redux/lifts/liftSlice";
// COMPONENTS
import DrawerLiftInformation from "../../components/drawer/DrawerLiftInformation";
import DrawerBankInformation from "../../components/drawer/DrawerBankInformation";

library.add(faUserShield, faBuildingCircleCheck, faUserLock, faCheckCircle, faBan);

function LiftsDrawer() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>({});

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { lift, openDrawerLift } = useSelector((state: any) => state.lift);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerLift(false));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openDrawerLift) {
			if (lift) {
				setData(lift);
			}
		}
	}, [openDrawerLift]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer width='550' placement='right' onClose={onClickClose} closable={false} open={openDrawerLift}>
			{data && (
				<div>
					{/** ************************************************* */}
					{/** LIFT INFORMATION */}
					<DrawerLiftInformation data={lift || {}} />
					<br />
					{/** ************************************************* */}
					{/** BANK INFORMATION */}
					<DrawerBankInformation data={lift && lift.bank ? lift.bank : {}} />
				</div>
			)}
			{/** ************************************************* */}
			{/** CLOSE AND SUBMIT BUTTONS */}
			<div className='mt-4 generalStyles__width100'>
				<div>
					<Button type='default' onClick={onClickClose} className='buttonStyle__5'>
						{t("organizationManagement.close")}
					</Button>
				</div>
			</div>
		</Drawer>
	);
}

export default LiftsDrawer;
