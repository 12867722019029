import React from "react";
// ANT DESIGN COMPONENTS
import { Alert } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { faGear } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONETNS
import DrawerRowDivider from "./DrawerRowDivider";

function DrawerBanksActions({ data }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: any) => state.home);
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faGear} title={t("edgeManagement.banks.bankActions")} />
			{/** ************************************************* */}
			<div>
				{data && data.length > 0 ? (
					<>
						{data.map((action: any, index: number) => (
							<div key={index}>
								<div className={`drawer__box__${theme} mt-3`}>
									{/* ID */}
									<div className='generalStyles__flex'>
										<div>
											<p className='drawer__title'>Action ID</p>
										</div>
										<div className='drawer__textContainer'>
											<p className='drawer__text'>{action && action.action_id ? action.action_id : "--"}</p>
										</div>
									</div>
									{/* NAME */}
									<div className='generalStyles__flex'>
										<div>
											<p className='drawer__title'>{t("general.name")}</p>
										</div>
										<div className='drawer__textContainer'>
											<p className='drawer__text'>{action && action.name ? action.name : "--"}</p>
										</div>
									</div>
									{/* BUTTON TYPE */}
									<div className='generalStyles__flex'>
										<div>
											<p className='drawer__title'>{t("general.type")}</p>
										</div>
										<div className='drawer__textContainer'>
											<p className='drawer__text'>{action && action.button_type ? action.button_type : "--"}</p>
										</div>
									</div>
									{/* TIMER */}
									<div className='generalStyles__flex'>
										<div>
											<p className='drawer__title'>{t("general.timer")}</p>
										</div>
										<div className='drawer__textContainer'>
											<p className='drawer__text'>{action && action.timer ? action.timer : "--"}</p>
										</div>
									</div>
									{/* TOGGLE ACTION */}
									<div className='generalStyles__flex'>
										<div>
											<p className='drawer__title'>Toggle Action ID</p>
										</div>
										<div className='drawer__textContainer'>
											<p className='drawer__text'>
												{action && action.toggle_action_id ? action.toggle_action_id : "--"}
											</p>
										</div>
									</div>
								</div>
							</div>
						))}
					</>
				) : (
					<div className={`drawer__box__${theme}`}>
						<Alert message={t("edgeManagement.banks.actionsNoAssigned")} type='warning' showIcon />
					</div>
				)}
			</div>
		</div>
	);
}

export default DrawerBanksActions;
