import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { DatePicker } from "antd";
// MOMENT DATE FORMAT
import moment from "moment";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterDateRange() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { RangePicker } = DatePicker;
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [pickerType, setPickerType] = useState<
		"time" | "date" | "week" | "month" | "quarter" | "year" | undefined
	>("time");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters, blockFilters } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeDate = (value: any, dateString: [string, string] | string) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		// Generate today date
		if (filters.dateType === "TODAY") {
			copy.startDate = moment(dateString[0]).format(
				"YYYY-MM-DDTHH:mm:ss.SSS[Z]"
			);
			copy.endDate = moment(dateString[1]).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		}
		// Generate week date
		if (filters.dateType === "WEEK") {
			const [startYear, startWeek] = dateString[0].split("-");
			const startWeekNumber = startWeek.replace(/(st|th|nd)$/, "");
			const [endYear, endWeek] = dateString[1].split("-");
			const endWeekNumber = endWeek.replace(/(st|th|nd)$/, "");
			const date1 = moment()
				.year(parseInt(startYear, 10))
				.week(parseInt(startWeekNumber, 10))
				.startOf("week");
			const date2 = moment()
				.year(parseInt(endYear, 10))
				.week(parseInt(endWeekNumber, 10))
				.endOf("week");
			copy.startDate = moment(date1).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
			copy.endDate = moment(date2).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		}
		// Generate date by month
		else if (filters.dateType === "MONTH") {
			const startMonth = moment(dateString[0], "YYYY-MM");
			const endMonth = moment(dateString[1], "YYYY-MM");
			const startMonthNumber = startMonth.month() + 1;
			const endMonthNumber = endMonth.month() + 1;
			const startYear = startMonth.year();
			const endYear = endMonth.year();
			const date1 = moment()
				.month(startMonthNumber - 1)
				.year(startYear)
				.startOf("month")
				.startOf("day")
				.format("YYYY-MM-DD");
			const date2 = moment()
				.month(endMonthNumber - 1)
				.year(endYear)
				.endOf("month")
				.endOf("day")
				.format("YYYY-MM-DD");
			copy.startDate = moment(date1).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
			copy.endDate = moment(date2).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		}
		// Generate date by year
		else if (filters.dateType === "YEAR") {
			copy.startDate = moment()
				.year(parseInt(dateString[0], 10))
				.startOf("year")

				.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
			copy.endDate = moment()
				.year(parseInt(dateString[1], 10))
				.endOf("year")

				.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		}
		// Generate date by quarter
		else if (filters.dateType === "QUARTER") {
			// eslint-disable-next-line no-unused-vars
			const [yearStart, quarterStart] = dateString[0].split("-Q");
			// eslint-disable-next-line no-unused-vars
			const [yearEnd, quarterEnd] = dateString[1].split("-Q");
			const startMonth = moment(dateString[0], "YYYY-MM");
			const endMonth = moment(dateString[1], "YYYY-MM");
			const startYear = startMonth.year();
			const endYear = endMonth.year();
			// Determine the first month of the quarter
			let firstMonthOfQuarter: any;
			// eslint-disable-next-line default-case
			switch (parseInt(quarterStart, 10)) {
				case 1: // Q1
					firstMonthOfQuarter = 1; // January
					break;
				case 2: // Q2
					firstMonthOfQuarter = 4; // April
					break;
				case 3: // Q3
					firstMonthOfQuarter = 7; // July
					break;
				case 4: // Q4
					firstMonthOfQuarter = 10; // October
					break;
			}
			let lastMonthOfQuarter: any;
			// eslint-disable-next-line default-case
			switch (parseInt(quarterEnd, 10)) {
				case 1: // Q1
					lastMonthOfQuarter = 3; // March
					break;
				case 2: // Q2
					lastMonthOfQuarter = 6; // June
					break;
				case 3: // Q3
					lastMonthOfQuarter = 9; // October
					break;
				case 4: // Q4
					lastMonthOfQuarter = 12; // December
					break;
			}
			copy.startDate = moment()
				.month(firstMonthOfQuarter - 1)
				.year(startYear)
				.startOf("month")
				.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
			copy.endDate = moment()
				.month(lastMonthOfQuarter - 1)
				.year(endYear)
				.endOf("month")
				.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		}
		dispatch(updateFilters(copy));
	};

	const generateDefaultMonthDate = () => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		const startMonthNumber = moment().month() + 1;
		const endMonthNumber = moment().month() + 1;
		const date1 = moment()
			.month(startMonthNumber - 1)
			.startOf("month")
			.startOf("day");
		const date2 = moment()
			.month(endMonthNumber - 1)
			.endOf("month")
			.endOf("day");
		copy.startDate = moment(date1).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		copy.endDate = moment(date2).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		dispatch(updateFilters(copy));
	};

	const generateDefaultTodayDate = () => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.startDate = moment()
			.startOf("day")

			.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		copy.endDate = moment()
			.endOf("day")

			.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		dispatch(updateFilters(copy));
	};

	const generateDefaultYearDate = () => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.startDate = moment()
			.startOf("year")

			.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		copy.endDate = moment()
			.endOf("year")

			.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		dispatch(updateFilters(copy));
	};

	const generateDefaultQuarterDate = () => {
		const currentQuarter = moment().quarter();
		let firstMonthOfQuarter: any;
		let lastMonthOfQuarter: any;
		// eslint-disable-next-line default-case
		switch (currentQuarter) {
			case 1: // Q1
				firstMonthOfQuarter = "1"; // January
				lastMonthOfQuarter = "3"; // March
				break;
			case 2: // Q2
				firstMonthOfQuarter = "4"; // April
				lastMonthOfQuarter = "6"; // June
				break;
			case 3: // Q3
				firstMonthOfQuarter = "7"; // July
				lastMonthOfQuarter = "9"; // September
				break;
			case 4: // Q4
				firstMonthOfQuarter = "10"; // October
				lastMonthOfQuarter = "12"; // December
				break;
		}
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.startDate = moment()
			.month(firstMonthOfQuarter - 1)
			.startOf("month")
			.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		copy.endDate = moment()
			.month(lastMonthOfQuarter - 1)
			.endOf("month")
			.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		dispatch(updateFilters(copy));
	};

	const generateDefaultWeekDate = () => {
		const startWeekNumber = moment().week();
		const endWeekNumber = moment().week();
		const year = moment().year();
		const date1 = moment().year(year).week(startWeekNumber).startOf("week");
		const date2 = moment().year(year).week(endWeekNumber).endOf("week");
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.startDate = moment(date1).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		copy.endDate = moment(date2).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (filters.dateType === "MONTH") {
			generateDefaultMonthDate();
			setPickerType("month");
		} else if (filters.dateType === "TODAY") {
			generateDefaultTodayDate();
			setPickerType("date");
		} else if (filters.dateType === "YEAR") {
			generateDefaultYearDate();
			setPickerType("year");
		} else if (filters.dateType === "QUARTER") {
			generateDefaultQuarterDate();
			setPickerType("quarter");
		} else if (filters.dateType === "WEEK") {
			generateDefaultWeekDate();
			setPickerType("week");
		}
	}, [filters.dateType]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<RangePicker
				allowClear={false}
				className='generalStyles__width100'
				style={{ height: "24px" }}
				picker={pickerType}
				showTime={pickerType === "date"}
				value={[moment(filters.startDate), moment(filters.endDate)]}
				onChange={onChangeDate}
				disabled={blockFilters}
			/>
		</div>
	);
}

export default ReportsFilterDateRange;
