import React, { useEffect } from "react";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateConfigurationFileBreadcrumb } from "../../../redux/configurationFiles/configurationFilesSlice";
// COMPONENTS
import ConfigurationFilesHeader from "./ConfigurationFilesHeader";
import ConfigurationFilesExpandableRow from "./ConfigurationFilesExpandableRow";
import ConfigurationFilesDrawer from "./ConfigurationFilesDrawer";
import ConfigurationFilesDeleteModal from "./ConfigurationFilesDeleteModal";
import ConfigurationFilesCreateForm from "./ConfigurationFilesCreateForm";
import ConfigurationFilesDataBlockForm from "./ConfigurationFilesDataBlockForm";
import OffsetsDownloadConfiguration from "../offsets/OffsetsDownloadConfiguration";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

const ConfigurationFilesPage = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		dispatch(updateConfigurationFileBreadcrumb([]));
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("view-edge-management-catalog", permissions) ? (
				<div>
					{/** HEADER AND STEPER */}
					<div className={`layout__contentPage__${theme}`}>
						<ConfigurationFilesDrawer />
						<ConfigurationFilesCreateForm />
						<ConfigurationFilesDeleteModal />
						<ConfigurationFilesHeader />
						<ConfigurationFilesExpandableRow />
						<ConfigurationFilesDataBlockForm />
						<OffsetsDownloadConfiguration />
					</div>
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default ConfigurationFilesPage;
