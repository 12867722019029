import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Tree, Button } from "antd";
import type { DataNode } from "antd/es/tree";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBan,
	faCircleCheck,
	faAdd,
	faCircleExclamation,
	faWifi,
	faBuildingShield,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenModalEditRole, updateRole, updateOpenDrawerRole } from "../../../redux/roles/roleSlice";
// STYLES

function RolesStaticRolesTree({ roleData, connectionType }: any) {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const { openDrawerRole } = useSelector((state: any) => state.role);
	const { openDrawerUser } = useSelector((state: any) => state.user);
	const [treeData, setTreeData] = useState<DataNode[]>([]);
	const [openKeys, setOpenKeys] = useState([]);
	const showLeafIcon = false;
	const [t] = useTranslation("global");

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const buildActionsTree = (page: any, subPageName: string) => {
		const isActive = connectionType === "online" ? page.active : page.offline;
		const objectAction: any = {
			title: t(`organizationManagement.roles.${page.tree_key}`),
			key: subPageName + page.tree_key,
			icon: isActive ? (
				<FontAwesomeIcon icon={faCircleCheck} className='generalStyles__success generalStyles__mrFix' />
			) : (
				<FontAwesomeIcon icon={faBan} className='generalStyles__error generalStyles__mrFix' />
			),
			children: [],
		};
		return objectAction;
	};

	const buildSubPagesTree = (subPage: any) => {
		const objectSubPage: any = {
			title: t(`organizationManagement.roles.${subPage.tree_key}`),
			key: subPage.tree_key,
			children: [],
		};
		subPage.actions.map((page: any) => {
			const actionResponse = buildActionsTree(page, subPage.name);
			return objectSubPage.children.push(actionResponse);
		});
		return objectSubPage;
	};

	const buildPagesTree = (page: any) => {
		if (page.tree_key) {
			const objectPage: any = {
				title: t(`organizationManagement.roles.${page.tree_key}`),
				key: page.tree_key,
				children: [],
			};
			page.sub_pages.map((subPage: any) => {
				const subPageResponse = buildSubPagesTree(subPage);
				return objectPage.children.push(subPageResponse);
			});
			return objectPage;
		}
		return false;
	};

	const buildPermissionsTree = (menu: any) => {
		const tree: any = [];
		// BUILD TREE PERMISSIONS
		menu.map((page: any) => {
			const pageResponse = buildPagesTree(page);
			if (pageResponse) {
				return tree.push(pageResponse);
			}
			return false;
		});
		// OPEN ALL KEYS WHEN USER OPEN DRAWER
		const newOpenKeys: any = [];
		menu.map((page: any) => {
			page.sub_pages.map((subPage: any) => newOpenKeys.push(subPage.tree_key));
			return newOpenKeys.push(page.tree_key);
		});
		setOpenKeys(newOpenKeys);
		// SAVE TREE DATA
		setTreeData(tree);
	};

	const handleOpenModalEdit = () => {
		dispatch(updateOpenDrawerRole(false));
		dispatch(updateRole(roleData));
		dispatch(updateOpenModalEditRole(true));
	};

	const onExpand = (expandedKeysValue: any) => {
		setOpenKeys(expandedKeysValue);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		// CLEAN DATA
		setTreeData([]);
		setOpenKeys([]);
		//
		if ((openDrawerRole || openDrawerUser) && roleData && roleData.menu && roleData.menu.pages) {
			buildPermissionsTree(roleData.menu.pages);
		}
	}, [roleData]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{treeData && treeData.length > 0 ? (
				<>
					<Tree
						icon={
							<FontAwesomeIcon
								icon={connectionType === "offline" ? faBuildingShield : faWifi}
								className='generalStyles__info generalStyles__mrFix'
							/>
						}
						showLine={{ showLeafIcon }}
						showIcon
						onExpand={onExpand}
						treeData={treeData}
						expandedKeys={openKeys}
					/>
				</>
			) : (
				<>
					<div className='generalStyles__flexCenter'>
						<div>
							<p className='drawer__warningPermissionsIcon'>
								<FontAwesomeIcon icon={faCircleExclamation} />
							</p>
							<p>{t("organizationManagement.roles.blankPermissions")}</p>
							<Button
								className='generalStyles__width100'
								type='primary'
								icon={<FontAwesomeIcon icon={faAdd} className='generalStyles__mrFix' />}
								onClick={handleOpenModalEdit}
							>
								{t("organizationManagement.roles.addPermissions")}
							</Button>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default RolesStaticRolesTree;
