import React from "react";
// ANT DESIGN COMPONENTS
import { Dropdown, MenuProps, Space } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEllipsis } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateNotification, updateOpenDrawerManageSchedules } from "../../../redux/notifications/notificationSlice";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
// COMPONENTS
import TableActionsMenuItem from "../../components/table/TableActionsMenuItem";

function NotificationSchedulesTableActions({ selectedItem }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenDrawerConfigureNotifications = () => {
		dispatch(updateNotification(selectedItem));
		dispatch(updateOpenDrawerManageSchedules(true));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// ROW ACTIONS ************************************ */
	const manageActions = () => {
		const ASSIGN_USER = validatePermission("permission-not-defined", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faClock}
							text={t("general.manageSchedules")}
							onClick={handleOpenDrawerConfigureNotifications}
						/>
					),
					key: "1",
			  }
			: null;

		return [ASSIGN_USER];
	};
	const menu: MenuProps = {
		items: manageActions(),
	};
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Dropdown menu={menu} trigger={["click"]}>
				<Space className='generalStyles__hoverCursor'>
					<FontAwesomeIcon icon={faEllipsis} />
				</Space>
			</Dropdown>
		</div>
	);
}

export default NotificationSchedulesTableActions;
