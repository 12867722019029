import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import RootPermissionsJsonViewer from "./RootPermissionsJsonViewer";
import RootPermissionsEditModal from "./RootPermissionsEditModal";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

const RootPermissionsPage = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission(
				"view-tool-management-root-permissions",
				permissions
			) ? (
				<div className={`layout__contentPage__${theme}`}>
					<RootPermissionsJsonViewer />
					<RootPermissionsEditModal />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default RootPermissionsPage;
