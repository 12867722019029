import React from "react";
// ANT DESIGN COMPONENTS
import { Dropdown, Menu, Space } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateMessageType,
	updateOpenForm,
	updateFormAction,
	updateOpenModalDelete,
} from "../../../redux/messageType/messagesTypeSlice";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
// COMPONENTS
import TableActionsMenuItem from "../../components/table/TableActionsMenuItem";
// INTERFACES
import { MessageTypeTableActionsInterface } from "../../../interfaces/MessageType.interface";

function MessageTypeTableActions({ selectedItem }: MessageTypeTableActionsInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalDelete = () => {
		dispatch(updateMessageType(selectedItem));
		dispatch(updateOpenModalDelete(true));
		dispatch(updateFormAction("EDIT"));
	};
	const handleOpenModalEdit = () => {
		dispatch(updateMessageType(selectedItem));
		dispatch(updateOpenForm(true));
		dispatch(updateFormAction("EDIT"));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// ROW ACTIONS ************************************ */
	const manageActions = () => {
		const EDIT = validatePermission("message-types-update", permissions)
			? {
					label: <TableActionsMenuItem icon={faPenToSquare} text={t("general.edit")} onClick={handleOpenModalEdit} />,
					key: "1",
			  }
			: null;
		const DELETE = validatePermission("message-types-delete", permissions)
			? {
					label: <TableActionsMenuItem icon={faTrash} text={t("general.delete")} onClick={handleOpenModalDelete} />,
					key: "2",
			  }
			: null;

		return [EDIT, DELETE];
	};
	const menu = <Menu items={manageActions()} />;
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Dropdown overlay={menu} trigger={["click"]}>
				<Space className='generalStyles__hoverCursor'>
					<FontAwesomeIcon icon={faEllipsis} />
				</Space>
			</Dropdown>
		</div>
	);
}

export default MessageTypeTableActions;
