import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";

function TableColumnOrganizationType({ type }: { type: string }) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{type === "SUBORG" && (
				<Tag color='cyan' className='drawer__typeTag'>
					SUBORG
				</Tag>
			)}
			{type === "MASTER" && (
				<Tag color='blue' className='drawer__typeTag'>
					MASTER
				</Tag>
			)}
			{type === "ORG" && (
				<Tag color='green' className='drawer__typeTag'>
					ORGANIZATION
				</Tag>
			)}{" "}
		</div>
	);
}

export default TableColumnOrganizationType;
