import React from "react";
// ANT DESIGN COMPONENT
import { Tooltip, Empty } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
// COPY TO CLIBBOARD
import { CopyToClipboard } from "react-copy-to-clipboard";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector } from "react-redux";
// INTERFACES
import { RootState } from "../../../app/store";

function TerminalJsonViewer() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { terminalLastShortMessage } = useSelector((state: RootState) => state.monitoring);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`terminal__container__${theme}`}>
			<div className='terminal__jsonViewer__copyText'>
				{terminalLastShortMessage && terminalLastShortMessage.lift_id ? (
					<>
						<CopyToClipboard text={JSON.stringify(terminalLastShortMessage)}>
							<Tooltip placement='top' title={t("general.copyToClipboard")}>
								<span>
									{" "}
									<FontAwesomeIcon icon={faCopy} className='generalStyles__mrFix' />
								</span>
								Copy
							</Tooltip>
						</CopyToClipboard>
					</>
				) : (
					<div className='terminal__jsonViewer__copyEmptyText' />
				)}
			</div>
			<div className='terminal__jsonViewer__container'>
				{terminalLastShortMessage && terminalLastShortMessage.lift_id ? (
					<pre>{JSON.stringify(terminalLastShortMessage, null, 2)}</pre>
				) : (
					<div className='terminal__jsonViewer__emptyFixTop2'>
						<Empty />
					</div>
				)}
			</div>
		</div>
	);
}

export default TerminalJsonViewer;
