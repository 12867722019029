import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Select, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { useLazyGetOrganizationAndChildsQuery } from "../../../redux/organizations/organizationAPI";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { RootState } from "../../../app/store";
import { OrganizationInterface } from "../../../interfaces/Organizations.interface";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterGetOrganizations() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [organizations, setOrganizations] = useState<OrganizationInterface[]>(
		[]
	);
	const [launchFirstSearch, setLaunchFirstSearch] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);
	const { userLogged } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetOrganizations, { data, isLoading, isError, isFetching }] =
		useLazyGetOrganizationAndChildsQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getOrganization = async (orgId: string) => {
		const token = await GETJwtToken();
		triggerGetOrganizations({
			page: 1,
			limit: 9999,
			token,
			orgId,
		});
	};

	const initialData = () => {
		if (userLogged && userLogged.org && userLogged.org.id) {
			getOrganization(userLogged.org.id);
		}
	};

	const onChangeOrganization = (newValue: string) => {
		const index = organizations.findIndex((item: any) => item.id === newValue);
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		// Save selected organization
		copy.organizationName = organizations[index].name;
		copy.organizationId = organizations[index].id;
		// Validate first default data
		if (filters.getFirstDefaultValue) {
			copy.getFirstDefaultValue = false;
		}
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		initialData();
	}, []);

	useEffect(() => {
		const newData = structuredClone(data);
		if (newData && newData.data) {
			if (data.data.children) {
				setOrganizations([
					{
						id: data.data.id,
						address: data.data.address,
						city: data.data.city,
						country: data.data.country,
						name: data.data.name,
						phone_code: data.data.phone_code,
						phone: data.data.phone,
						state: data.data.state,
						type: data.data.type,
					},
					...data.data.children,
				]);
			} else {
				setOrganizations([
					{
						id: data.data.id,
						address: data.data.address,
						city: data.data.city,
						country: data.data.country,
						name: data.data.name,
						phone_code: data.data.phone_code,
						phone: data.data.phone,
						state: data.data.state,
						type: data.data.type,
					},
				]);
			}
		}
	}, [data]);

	useEffect(() => {
		setOrganizations([]);
	}, [isError]);

	useEffect(() => {
		// Get first organization by default
		if (
			organizations &&
			userLogged &&
			filters &&
			filters.getFirstDefaultValue
		) {
			organizations.map((organization: any) => {
				if (
					userLogged.org_id &&
					organization.id &&
					organization.id === userLogged.org_id
				) {
					onChangeOrganization(organization.id);
					setLaunchFirstSearch(true);
				}
				return true;
			});
		}
	}, [organizations]);

	useEffect(() => {
		if (launchFirstSearch) {
			setLaunchFirstSearch(false);
		}
	}, [launchFirstSearch]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__width100'>
			<Tooltip
				title={organizations.length === 0 ? t("report.noOrganizations") : ""}
			>
				<Select
					className='generalStyles__width100'
					disabled={organizations.length === 0}
					showSearch
					size='small'
					optionFilterProp='children'
					placeholder={t("report.selectOrganization")}
					loading={isLoading || isFetching}
					value={filters.organizationId === "" ? null : filters.organizationId}
					onChange={onChangeOrganization}
				>
					{organizations.map((organization: OrganizationInterface) => (
						<Option key={organization.id} value={organization.id}>
							{organization.name}
						</Option>
					))}
				</Select>
			</Tooltip>
		</div>
	);
}

export default ReportsFilterGetOrganizations;
