import React, { useEffect } from "react";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateServerBreadcrumb,
	updateIdServerSelected,
	updateNameServerSelected,
} from "../../../redux/servers/serverSlice";
import {
	updateIdBankSelected,
	updateNameBankSelected,
	updateBankBreadcrumb,
} from "../../../redux/banks/bankSlice";
// COMPONENTS
import BanksHeader from "./BanksHeader";
import BanksTable from "./BanksTable";
import BanksCreateForm from "./BanksCreateForm";
import BanksDrawer from "./BanksDrawer";
import BanksDeleteModal from "./BanksDeleteModal";
import LiftsTable from "../lifts/LiftsTable";
import LiftsHeader from "../lifts/LiftsHeader";
import LiftsDrawer from "../lifts/LiftsDrawer";
import LiftsCreateForm from "../lifts/LiftsCreateForm";
import BanksFloorNames from "./BanksFloorNames";
import BanksDrawerLiftsPosition from "./BanksDrawerLiftsPosition";
import BanksAssignActions from "./BanksAssignActions";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

const BanksPage = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);
	const { bankBreadcrumb } = useSelector((state: any) => state.bank);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		dispatch(updateServerBreadcrumb([]));
		dispatch(updateBankBreadcrumb([]));
		dispatch(updateIdServerSelected(""));
		dispatch(updateIdBankSelected(""));
		dispatch(updateNameBankSelected(""));
		dispatch(updateNameServerSelected(""));
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("view-edge-management-banks", permissions) ? (
				<div>
					<div className={`layout__contentPage__${theme}`}>
						{bankBreadcrumb.length === 0 && (
							<>
								<BanksHeader />
								<BanksTable renderComponent='bankPage' />
							</>
						)}
						{bankBreadcrumb.length === 1 && (
							<>
								<LiftsHeader />
								<LiftsTable renderComponent='bankPage' />
							</>
						)}
						<BanksCreateForm renderComponent='bankPage' />
						<BanksDrawer />
						<LiftsDrawer />
						<BanksDeleteModal />
						<LiftsCreateForm />
						<BanksFloorNames />
						<BanksDrawerLiftsPosition />
						<BanksAssignActions />
					</div>
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default BanksPage;
