import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Select, InputNumber, Button, Tooltip, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faGear, faPlus } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
// import { updateConfigurationDefaultOffsets } from "../../../redux/configurationFiles/configurationFilesSlice";
import { updateOpenFormOffsetType, updateOpenFormOffsetTypeAction } from "../../../redux/tools/toolsSlice";
import { useLazyGetAllOffsetsTypesQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import OffsetTypeForm from "../../tools/offsetType/OffsetTypeForm";

function ConfigurationFilesSelectOffsets({ dataBlock, onChangeOffsets }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [listSelectedOffsetsTypes, setListSelectedOffsetsTypes] = useState<any>([]);
	const [selectedItemsOffsetsTypes, setSelectedItemsOffsetsTypes] = useState<string[]>([]);
	const [offsetTypes, setOffsetTypes] = useState([]);
	const filteredOptionsOffsetTypes = offsetTypes.filter((o) => !selectedItemsOffsetsTypes.includes(o));
	const [isLoading, setIsLoading] = useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetOffsetsTypes, { data: dataOffsetTypes }] = useLazyGetAllOffsetsTypesQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const showIsLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
	};

	const onChangeTotalInput = (newValue: number, index: number, values: any) => {
		const newObject: any = [];
		listSelectedOffsetsTypes.map((item: any, index2: number) => {
			if (index === index2) {
				newObject.push({
					key: values.key,
					offsetTypePosition: values.offsetTypePosition,
					name: values.name,
					offsetTypeId: values.offsetTypeId,
					[`total${values.name}`]: newValue,
				});
			} else {
				newObject.push({ ...item });
			}
			return true;
		});
		setListSelectedOffsetsTypes(newObject);
	};

	const buildSelectedItemsObj = (items: any) => {
		// Build object
		if (items.length > 0) {
			const newArr: any = [];
			items.map((item: any, index: number) => {
				// Get index in offset list
				const offsetIndex = dataOffsetTypes.data.findIndex((object: any) => object.name === item);
				newArr.push({
					dataBlock,
					key: `${item}-${index}`,
					offsetTypePosition: index,
					name: item,
					offsetTypeId: dataOffsetTypes.data[offsetIndex].id,
					[`total${item}`]: 1,
				});
				return true;
			});
			setListSelectedOffsetsTypes(newArr);
		}
	};

	const onChangeOffsetTypeSelect = (newSelectedItemsObj: any) => {
		setSelectedItemsOffsetsTypes(newSelectedItemsObj);
		buildSelectedItemsObj(newSelectedItemsObj);
	};

	const getOffsetsList = async () => {
		const token = await GETJwtToken();
		triggerGetOffsetsTypes({ token });
	};

	const handleOpenModalCreateNewOffsetType = () => {
		dispatch(updateOpenFormOffsetType(true));
		dispatch(updateOpenFormOffsetTypeAction("create"));
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		showIsLoading();
		// Get offset type list
		getOffsetsList();
	}, []);

	useEffect(() => {
		const newObjt: any = [];
		if (dataOffsetTypes && dataOffsetTypes.data) {
			dataOffsetTypes.data.map((item: any) => {
				newObjt.push(item.name);
				return true;
			});
			setOffsetTypes(newObjt);
		}
	}, [dataOffsetTypes]);

	useEffect(() => {
		onChangeOffsets({
			dataBlock,
			data: listSelectedOffsetsTypes,
		});
	}, [listSelectedOffsetsTypes]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={isLoading}>
			<div>
				{/** ************************************************* */}
				{/** OFFSETS */}
				<br />
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon icon={faGear} className='generalStyles__info generalStyles__mrFix' />
							{dataBlock}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme} mt-3`}>
					{/* OPTIONS OFFSET TYPE */}
					<div className='generalStyles__flex'>
						<div>
							<p className='drawer__title mt-2'>
								{t("configuration.dataBlock.dataBlock")} {dataBlock}
							</p>
						</div>
						{/** SELECT OFF TYPE OFFSETS */}
						<div className='drawer__textContainer generalStyles__width100'>
							<Select
								mode='multiple'
								value={selectedItemsOffsetsTypes}
								onChange={(e: any) => {
									onChangeOffsetTypeSelect(e);
								}}
								className='generalStyles__width100'
								disabled={isLoading}
								options={filteredOptionsOffsetTypes.map((item) => ({
									value: item,
									label: item,
								}))}
							/>
						</div>
						<div>
							<Tooltip title={t("tooltip.configurationSelectOffsets")} placement='top'>
								<FontAwesomeIcon icon={faCircleInfo} className='generalStyles__infoTooltipIcon mt-1' />
							</Tooltip>
						</div>
					</div>
					{/** FILTERD OPTIONS */}
					{selectedItemsOffsetsTypes && (
						<div className='container'>
							<div className='row mt-2'>
								{listSelectedOffsetsTypes.map((item: any, index: number) => (
									<div className='generalStyles__flex' key={item.key}>
										<div>
											<p className={isMobile ? "drawer__titleMobile" : "drawer__title"} style={{ paddingTop: "10px" }}>
												<span className='generalStyles__info'>{index + 1})</span> Total - {item.name}
											</p>
										</div>
										<div className='drawer__textContainer generalStyles__width100'>
											<div className='drawer__textContainer'>
												<div className='generalStyles__flex'>
													<InputNumber
														min={1}
														max={100}
														value={item[`total${item.name}`]}
														onChange={(e) => {
															onChangeTotalInput(e, index, item);
														}}
														className='edgeManagement__bankInputTotalOption'
													/>
													{item[`total${item.name}`] === 1 && (
														<Tooltip title={t("tooltip.carServiceByte")} placement='top'>
															<FontAwesomeIcon icon={faCircleInfo} className='generalStyles__warningTooltipIcon' />
														</Tooltip>
													)}
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					)}
					{/** ---------------------------------------------------- */}
					{/** ADD NEW OFFSET TYPE */}
					<div className='generalStyles__flexEnd'>
						<Tooltip title={t("organizationManagement.roles.offset-type-managercreate")} placement='top'>
							<Button shape='circle' className='buttonStyle__2' onClick={handleOpenModalCreateNewOffsetType}>
								<FontAwesomeIcon icon={faPlus} />
							</Button>
						</Tooltip>
						{/** OFFSET TYPE FORM */}
						<OffsetTypeForm />
					</div>
				</div>
			</div>
		</Spin>
	);
}

export default ConfigurationFilesSelectOffsets;
