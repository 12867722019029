import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenFormPermission } from "../../../redux/tools/toolsSlice";
import { usePostPermissionMutation, usePutPermissionMutation } from "../../../redux/tools/toolsAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONETNS
import GlobalAlert2 from "../../home/GlobalAlert2";

function permissionsForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>({
		name: "",
		description: "",
	});
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permission, openFormPermission, openFormPermissionAction } = useSelector((state: any) => state.tool);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		postPermission,
		{ isSuccess: isSuccessPost, isLoading: isLoadingPost, isError: isErrorPost, error: errorPost, reset: resetPost },
	] = usePostPermissionMutation();
	const [
		putPermission,
		{ isSuccess: isSuccessPut, isLoading: isLoadingPut, isError: isErrorPut, error: errorPut, reset: resetPut },
	] = usePutPermissionMutation();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onFill = () => {
		setData({
			name: permission.name,
			description: permission.description,
		});
	};

	const onClickClose = () => {
		dispatch(updateOpenFormPermission(false));
	};

	const onChangeValue = (newValue: string, variableName: string) => {
		const copy = structuredClone(data);
		copy[variableName] = newValue;
		setData(copy);
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		if (openFormPermissionAction === "create") {
			const BODY = {
				token,
				body: {
					message: "",
					short_message: "",
				},
			};
			postPermission(BODY);
		} else if (openFormPermissionAction === "update") {
			const BODY = {
				token,
				id: permission.id,
				body: {
					message: "",
					short_message: "",
				},
			};
			putPermission(BODY);
		}
	};

	const disabledSaveButton = () => {
		let disableButton = false;
		if (data.name === "" || data.description === "") {
			disableButton = true;
		}
		return disableButton;
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		if (openFormPermission) {
			resetPost();
			resetPut();
			if (openFormPermissionAction === "update") {
				onFill();
			} else {
				setData({
					name: "",
					description: "",
				});
			}
		}
	}, [openFormPermission]);

	useEffect(() => {
		onClickClose();
	}, [isSuccessPut, isErrorPut, isSuccessPost, isErrorPost]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width={isMobile ? "95%" : "35%"}
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openFormPermission}
		>
			<Spin spinning={isLoadingPost || isLoadingPut} tip={t("general.loading")}>
				{/** ************************************************* */}
				{/** PERMISSION FORM */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon icon={faGear} className='generalStyles__info generalStyles__mrFix' />
							{openFormPermissionAction === "create"
								? t("configuration.permission.createNewPermission")
								: t("configuration.permission.updatePermission")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme}`}>
					{/** NAME */}
					<div className='col-md-12'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span className='drawer__text'>Name</span>
							</div>
							<div className='generalStyles__width100'>
								<Input
									size='small'
									value={data.name}
									onChange={(e) => {
										onChangeValue(e.currentTarget.value, "name");
									}}
								/>
							</div>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
					{/** DESCRIPTION */}
					<div className='col-md-12'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span className='drawer__text'>Description</span>
							</div>
							<div className='generalStyles__width100'>
								<Input
									size='small'
									value={data.name}
									onChange={(e) => {
										onChangeValue(e.currentTarget.value, "description");
									}}
								/>
							</div>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
				</div>
				<br />
				{/** ************************************************* */}
				{/** CLOSE AND SUBMIT BUTTONS */}
				<div className='mt-4 generalStyles__width100'>
					<div>
						<div>
							<Button onClick={onClickClose} className='buttonStyle__3'>
								{t("organizationManagement.close")}
							</Button>
							<Button onClick={onClickSave} className='buttonStyle__3' type='primary' disabled={disabledSaveButton()}>
								{t("general.save")}
							</Button>
						</div>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPost}
				isSuccess={isSuccessPost}
				requestType='POST'
				error={errorPost}
				name='Permission'
			/>
			<GlobalAlert2
				isError={isErrorPut}
				isSuccess={isSuccessPut}
				requestType='PUT'
				error={errorPut}
				name='Permission'
			/>
		</Drawer>
	);
}

export default permissionsForm;
