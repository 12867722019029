import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Button, Switch, TimePicker, Tooltip, DatePicker, Input } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// MOMENT DATE FORMAT
import moment from "moment";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCalendar, faTrash } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
import { useLazyGetHolidaysQuery } from "../../../redux/organizations/organizationAPI";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";
import { HolidayInterface } from "../../../interfaces/Organizations.interface";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function ReportsDrawerExclusionsFilters() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { RangePicker } = DatePicker;

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [exclusionRangeDate, setExclusionRangeDate] = useState<any>([
		moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
		moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
	]);
	const [exclusionRangeTime, setExclusionRangeTime] = useState<any>(["00:00", "23:59"]);
	const [holidays, setHolidays] = useState<HolidayInterface[]>([]);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetHolidays, { data: dataHolidays, isLoading: isLoadingHolidays }] = useLazyGetHolidaysQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeExclusionSaturdays = (newValue: boolean) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.exclusionSaturdays = newValue;
		dispatch(updateFilters(copy));
	};

	const onChangeExclusionSundays = (newValue: boolean) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.exclusionSundays = newValue;
		dispatch(updateFilters(copy));
	};

	const onChangeExclusionHolidays = (newValue: boolean) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.exclusionHolidays = newValue;
		dispatch(updateFilters(copy));
	};

	const onChangeAddExclusionTimeRanges = (newValue: boolean) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.exclusionTime = newValue;
		dispatch(updateFilters(copy));
	};

	const onChangeAddExclusionDateRanges = (newValue: boolean) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.exclusionDate = newValue;
		dispatch(updateFilters(copy));
	};

	const onChangeExclusionTimeRange = (value: any, dateString: [string, string] | string) => {
		setExclusionRangeTime(dateString);
	};

	const onChangeExclusionDateRange = (value: any, dateString: [string, string] | string) => {
		setExclusionRangeDate([
			moment(dateString[0]).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
			moment(dateString[1]).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
		]);
	};

	const onClickAddNewExclusionDateRange = () => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.exclusionDateList.push(exclusionRangeDate);
		dispatch(updateFilters(copy));
		setExclusionRangeDate([
			moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
			moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
		]);
	};

	const onClickRemoveExclusionDateRange = (index: number) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.exclusionDateList.splice(index, 1);
		dispatch(updateFilters(copy));
	};

	const onClickAddNewExclusionTimeRange = () => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.exclusionTimeList.push(exclusionRangeTime);
		dispatch(updateFilters(copy));
		setExclusionRangeTime(["00:00", "23:59"]);
	};

	const onClickRemoveExclusionTimeRange = (index: number) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.exclusionTimeList.splice(index, 1);
		dispatch(updateFilters(copy));
	};

	const getHolidaysByOrganization = async (orgId: string) => {
		const token = await GETJwtToken();
		triggerGetHolidays({
			token,
			orgId,
		});
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (filters && filters.organizationId) {
			getHolidaysByOrganization(filters.organizationId);
		} else {
			setHolidays([]);
		}
	}, [filters.organizationId]);

	useEffect(() => {
		if (dataHolidays && dataHolidays.data && dataHolidays.data.length > 0) {
			setHolidays(dataHolidays.data);
		} else {
			setHolidays([]);
		}
	}, [dataHolidays]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`drawer__box__${theme}`}>
			{/* EXCLUDE SATURDAYS */}
			<div className='generalStyles__flex'>
				<div>
					<p className='drawer__title'>{t("general.saturdays")}:</p>
				</div>
				<div className='drawer__textContainer'>
					<Switch onChange={onChangeExclusionSaturdays} checked={filters.exclusionSaturdays} />
				</div>
			</div>
			{/* EXCLUDE SUNDAYS */}
			<div className='generalStyles__flex'>
				<div>
					<p className='drawer__title'>{t("general.sundays")}:</p>
				</div>
				<div className='drawer__textContainer'>
					<Switch onChange={onChangeExclusionSundays} checked={filters.exclusionSundays} />
				</div>
			</div>
			{/* EXCLUDE HOLIDAYS */}
			<div className='generalStyles__flex'>
				<div>
					<p className='drawer__title'>{t("general.holidays")}:</p>
				</div>
				<div className='drawer__textContainer'>
					<Tooltip
						title={
							holidays.length === 0 ? (
								<>
									{filters && filters.organizationName ? (
										<>
											<span className='generalStyles__info'>{filters.organizationName}</span>{" "}
											<span>{t("general.noHolidaysAssigned")}</span>
										</>
									) : (
										<>
											<span>{t("general.selectAnOrganization")}</span>
										</>
									)}
								</>
							) : (
								""
							)
						}
					>
						<Switch
							onChange={onChangeExclusionHolidays}
							checked={filters.exclusionHolidays}
							disabled={holidays.length === 0}
							loading={isLoadingHolidays}
						/>
					</Tooltip>
				</div>
			</div>
			{/* LIST HOLIDAYS */}
			{filters && filters.exclusionHolidays && (
				<>
					{holidays.map((holiday: HolidayInterface) => (
						<div className='generalStyles__flex drawer__container'>
							<div>
								<p className='drawer__title' />
							</div>
							<div className='drawer__titleMobile generalStyles__width100 mt-1'>
								<Tooltip
									title={`${
										holiday && holiday.month
											? moment()
													.month(holiday.month - 1)
													.format("MMMM")
											: "--"
									} - ${holiday && holiday.day ? holiday.day : "--"} / ${
										holiday && holiday.name ? holiday.name : "--"
									}`}
								>
									<Input
										prefix={
											<FontAwesomeIcon
												icon={faCalendar}
												style={{
													marginTop: "-5px",
													marginLeft: "-5px",
													marginRight: "5px",
												}}
											/>
										}
										disabled
										value={`${
											holiday && holiday.month
												? moment()
														.month(holiday.month - 1)
														.format("MMMM")
												: "--"
										} - ${holiday && holiday.day ? holiday.day : "--"} / ${
											holiday && holiday.name ? holiday.name : "--"
										}`}
									/>
								</Tooltip>
							</div>
						</div>
					))}
					<br />
				</>
			)}
			{/* EXCLUSION TIME */}
			<div className='generalStyles__flex'>
				<div>
					<p className='drawer__title'>{t("general.exclusionTime")}:</p>
				</div>
				<div className='drawer__textContainer'>
					<Switch
						onChange={(e) => {
							onChangeAddExclusionTimeRanges(e);
						}}
					/>
				</div>
			</div>
			{/** EXCLUSION TIME LIST */}
			{filters && filters.exclusionTime && (
				<>
					<div className='generalStyles__flex '>
						<div>
							<p className='drawer__title' />
						</div>
						<div className='drawer__textContainer generalStyles__width100'>
							<div className='generalStyles__flex'>
								<TimePicker.RangePicker
									format='HH:mm'
									className='generalStyles__width100'
									onChange={onChangeExclusionTimeRange}
									value={[moment(exclusionRangeTime[0], "HH:mm"), moment(exclusionRangeTime[1], "HH:mm")]}
									allowClear={false}
								/>
								<Tooltip title='Add new exclusion time range'>
									<Button
										onClick={onClickAddNewExclusionTimeRange}
										type='primary'
										shape='circle'
										className='generalStyles__mlFix'
										icon={<FontAwesomeIcon icon={faAdd} />}
									/>
								</Tooltip>
							</div>
						</div>
					</div>
					<div className='generalStyles__flex'>
						<div>
							<p className='drawer__title' style={{ paddingTop: "5px" }} />
						</div>
						<div className='drawer__textContainer generalStyles__width100'>
							{filters.exclusionTimeList.map((item: any, index: number) => (
								<div className='generalStyles__flex mt-3' key={index}>
									<TimePicker.RangePicker
										format='HH:mm'
										className='generalStyles__width100'
										value={[moment(item[0], "HH:mm"), moment(item[1], "HH:mm")]}
										allowClear={false}
										disabled
									/>
									<Tooltip title={t("general.delete")}>
										<Button
											onClick={() => {
												onClickRemoveExclusionTimeRange(index);
											}}
											shape='circle'
											className='generalStyles__mlFix buttonStyle__4'
											icon={<FontAwesomeIcon icon={faTrash} />}
										/>
									</Tooltip>
								</div>
							))}
						</div>
					</div>
					<br />
				</>
			)}
			{/* EXCLUSION DATE RANGE */}
			<div className='generalStyles__flex'>
				<div>
					<p className='drawer__title'>{t("general.dateRanges")}</p>
				</div>
				<div className='drawer__textContainer generalStyles__width100'>
					<Switch
						onChange={(e) => {
							onChangeAddExclusionDateRanges(e);
						}}
					/>
				</div>
			</div>
			{/** EXCLUSION DATE LIST */}
			{filters && filters.exclusionDate && (
				<>
					<div className='generalStyles__flex'>
						<div>
							<p className='drawer__title' />
						</div>
						<div className='drawer__textContainer generalStyles__width100'>
							<div className='generalStyles__flex'>
								<RangePicker
									format='YYYY-MM-DD HH:mm'
									allowClear={false}
									className='generalStyles__width100'
									showTime
									value={[moment(exclusionRangeDate[0]), moment(exclusionRangeDate[1])]}
									onChange={onChangeExclusionDateRange}
								/>
								<Tooltip title='Add new exclusion date range'>
									<Button
										onClick={onClickAddNewExclusionDateRange}
										type='primary'
										shape='circle'
										className='generalStyles__mlFix'
										icon={<FontAwesomeIcon icon={faAdd} />}
									/>
								</Tooltip>
							</div>
						</div>
					</div>
					<div className='generalStyles__flex'>
						<div>
							<p className='drawer__title' />
						</div>
						<div className='drawer__textContainer generalStyles__width100'>
							{filters.exclusionDateList.map((item: any, index: number) => (
								<div className='generalStyles__flex mt-3' key={index}>
									<RangePicker
										format='YYYY-MM-DD HH:mm'
										allowClear={false}
										className='generalStyles__width100'
										showTime
										value={[moment(item[0], "YYYY-MM-DD HH:mm"), moment(item[1], "YYYY-MM-DD HH:mm")]}
										disabled
									/>
									<Tooltip title={t("general.delete")}>
										<Button
											onClick={() => {
												onClickRemoveExclusionDateRange(index);
											}}
											shape='circle'
											className='generalStyles__mlFix buttonStyle__4'
											icon={<FontAwesomeIcon icon={faTrash} />}
										/>
									</Tooltip>
								</div>
							))}
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default ReportsDrawerExclusionsFilters;
