import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { faBook, faNoteSticky, faPlugCircleCheck, faWrench } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import DrawerRowItem from "./DrawerRowItem";
import TableColumnLiftType from "../table/TableColumnLiftType";
import TableColumnAuthType from "../table/TableColumnAuthType";
import TableColumnProtocolType from "../table/TableColumnProtocolType";
import DrawerRowDivider from "./DrawerRowDivider";
// INTERFACES
import { DrawerAdapterInformationInterface } from "../../../interfaces/Global.interface";
import { RootState } from "../../../app/store";

function DrawerAdapterInformation({ data }: DrawerAdapterInformationInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div style={{ overflowX: "auto" }}>
			{/** ------------------------------------------------------------- */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faPlugCircleCheck} title={t("adapter.adapterInformation")} />
			{/** ------------------------------------------------------------- */}
			{/** ADAPTER  */}
			<div className={`drawer__box__${theme}`}>
				{/* ID */}
				<DrawerRowItem title='ID' value={data && data.id ? data.id : "--"} />
				{/* NAME */}
				<DrawerRowItem title={t("general.name")} value={data && data.name ? data.name : "--"} />
				{/* VERSION */}
				<DrawerRowItem title={t("adapter.version")} value={data && data.version ? data.version : "--"} />
				{/* SOCKET ADDRESS */}
				<DrawerRowItem
					title={t("edgeManagement.channels.socketAddress")}
					value={data && data.socket_address ? data.socket_address : "--"}
				/>
				{/* LIFT TYPE */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>Lift {t("edgeManagement.lifts.type")}</p>
					</div>
					<div className='drawer__textContainer'>
						<div className='drawer__text'>
							{data && data.lift_type ? <TableColumnLiftType liftType={data.lift_type || ""} /> : "--"}
						</div>
					</div>
				</div>
				{/* PROTOCOL TYPE */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("general.protocol")}</p>
					</div>
					<div className='drawer__textContainer'>
						<div className='drawer__text'>
							{data && data.protocol_type ? <TableColumnProtocolType protocolType={data.protocol_type || ""} /> : "--"}
						</div>
					</div>
				</div>
				{/* TYPE */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("adapter.type")}:</p>
					</div>
					<div className='drawer__textContainer'>
						<div className='drawer__text'>
							{data && data.type && (
								<>
									{data.type === "REST" && (
										<Tag color='blue' className='drawer__typeTag'>
											{data.type}
										</Tag>
									)}
									{data.type === "SOAP" && (
										<Tag color='orange' className='drawer__typeTag'>
											{data.type}
										</Tag>
									)}
									{data.type === "WEBSOCKET" && (
										<Tag color='green' className='drawer__typeTag'>
											{data.type}
										</Tag>
									)}
								</>
							)}
						</div>
					</div>
				</div>
				{/* AUTH TYPE */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("adapter.authType")}:</p>
					</div>
					<div className='drawer__textContainer'>
						<div className='drawer__text'>
							{data && data.auth_type && <TableColumnAuthType authType={data.auth_type || ""} />}
						</div>
					</div>
				</div>
			</div>
			{/** ------------------------------------------------------------- */}
			{/** COMPONENT DRAWER DIVIDER */}
			<br />
			<DrawerRowDivider icon={faNoteSticky} title={t("adapter.dictionary")} />
			<div className={`drawer__box__${theme}`}>
				{/* DICTIONARY */}
				<div className='generalStyles__flex drawer__container'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("adapter.dictionary")}</p>
					</div>
					<div className='drawer__textContainer' />
				</div>
				<div className='generalStyles__flex drawer__container'>
					<div>
						<pre className='drawer__text'>
							{data && data.dictionary ? JSON.stringify(data.dictionary, null, 2) : "--"}
						</pre>
					</div>
				</div>
			</div>
			{/** ------------------------------------------------------------- */}
			{/** COMPONENT DRAWER DIVIDER */}
			<br />
			<DrawerRowDivider icon={faBook} title={t("adapter.interface")} />
			<div className={`drawer__box__${theme}`}>
				{/* INTERFACE */}
				<div className='generalStyles__flex drawer__container'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("adapter.interface")}</p>
					</div>
					<div className='drawer__textContainer' />
				</div>
				<div className='generalStyles__flex drawer__container'>
					<div>
						<pre className='drawer__text'>
							{data && data.interface ? JSON.stringify(data.interface, null, 2) : "--"}
						</pre>
					</div>
				</div>
			</div>
			{/** ------------------------------------------------------------- */}
			{/** COMPONENT DRAWER DIVIDER */}
			<br />
			<DrawerRowDivider icon={faWrench} title={t("adapter.bacnetConfig")} />
			<div className={`drawer__box__${theme}`}>
				{/* BACNET  */}
				<div className='generalStyles__flex drawer__container'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("adapter.bacnetConfig")}</p>
					</div>
					<div className='drawer__textContainer' />
				</div>
				<div className='generalStyles__flex drawer__container'>
					<div>
						<pre className='drawer__text'>
							{data && data.bacnet_config ? JSON.stringify(data.bacnet_config, null, 2) : "--"}
						</pre>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DrawerAdapterInformation;
