import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import IconDefinitionIcons from "./IconDefinitionIcons";

function IconDefinitionElevator({ iconType, onChangeValue, icon }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const [t] = useTranslation("global");
	const ACTIONS = [
		{
			name: "Alert",
			icon: "actionAlert",
		},
		{
			name: "Close",
			icon: "actionClose",
		},
		{
			name: "Lock",
			icon: "actionLock",
		},
		{
			name: "Open",
			icon: "actionOpen",
		},
	];
	const DOUBLE = [
		{
			name: "Indicator",
			icon: "elDoubleIndicator",
		},
		{
			name: "Out Of Service",
			icon: "elDoubleOutService",
		},
		{
			name: "Front Close Rear Close",
			icon: "elFrontCloseRearClose",
		},
		{
			name: "Front Close Rear Open",
			icon: "elFrontCloseRearOpen",
		},
		{
			name: "Front Close Rear Opening",
			icon: "elFrontCloseRearOpening",
		},
		{
			name: "Front Open Rear Close",
			icon: "elFrontOpenRearClose",
		},
		{
			name: "Front Open Rear Open",
			icon: "elFrontOpenRearOpen",
		},
		{
			name: "Front Opening Rear Close",
			icon: "elFrontOpeningRearClose",
		},
		{
			name: "Front Opening Rear Opening",
			icon: "elFrontOpeningRearOpening",
		},
	];
	const SINGLE = [
		{
			name: "Indicator",
			icon: "elOutIndicator",
		},
		{
			name: "Out Of Service",
			icon: "elOutService",
		},
		{
			name: "Door Close",
			icon: "elClose",
		},
		{
			name: "Door Open",
			icon: "elOpen",
		},
		{
			name: "Door Opening",
			icon: "elOpening",
		},
	];
	const STATUS = [
		{
			name: "Down",
			icon: "elDown",
		},
		{
			name: "Up",
			icon: "elUp",
		},
		{
			name: "Warning",
			icon: "elWarning",
		},
		{
			name: "Hall Call",
			icon: "elGoalHallCall",
		},
		{
			name: "Car Call",
			icon: "elGoal",
		},
		{
			name: "Locked",
			icon: "elLocked",
		},
		{
			name: "Not Allowed",
			icon: "elNotAllowed",
		},
	];
	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>([]);
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openFormIconDefinitionAction } = useSelector(
		(state: any) => state.tool
	);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (iconType === "ACTION") {
			setData(ACTIONS);
		} else if (iconType === "SINGLEDOOR") {
			setData(SINGLE);
		} else if (iconType === "DOUBLEDOOR") {
			setData(DOUBLE);
		} else if (iconType === "STATUS") {
			setData(STATUS);
		}
	}, [iconType]);

	useEffect(() => {
		onChangeValue("", "icon");
	}, [data]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ICON TYPE */}
			<div className='col-md-12 mt-3'>
				<div className='generalStyles__flex'>
					<div className='generalStyles__inputFlexName'>
						<span className='generalStyles__inputFlexRequired'>*</span>
						<span className='drawer__text'>
							{t("general.icon")} {t("general.type")}
						</span>
					</div>
					<div className='generalStyles__width100'>
						<Select
							showSearch
							optionFilterProp='children'
							className='generalStyles__width100'
							allowClear={false}
							size='small'
							disabled={openFormIconDefinitionAction === "update"}
							value={iconType}
							onChange={(e) => {
								onChangeValue(e, "iconType");
							}}
						>
							{["STATUS", "ACTION", "SINGLEDOOR", "DOUBLEDOOR"].map(
								(item: string) => (
									<Option key={item} value={item}>
										{item}
									</Option>
								)
							)}
						</Select>
					</div>
					<div className='generalStyles__infoTooltipIconBlank' />
				</div>
			</div>
			{/** ICON */}
			<div className='col-md-12 mt-3'>
				<div className='generalStyles__flex'>
					<div className='generalStyles__inputFlexName'>
						<span className='generalStyles__inputFlexRequired'>*</span>
						<span className='drawer__text'>{t("general.icon")}</span>
					</div>
					<div className='generalStyles__width100'>
						<Select
							showSearch
							optionFilterProp='children'
							className='generalStyles__width100'
							allowClear={false}
							size='small'
							value={icon}
							disabled={openFormIconDefinitionAction === "update"}
							onChange={(e) => {
								onChangeValue(e, "icon");
							}}
						>
							{data.map((item: any) => (
								<Option key={item.icon} value={item.icon}>
									<IconDefinitionIcons
										isMap={false}
										expanded={false}
										liftType='ELEVATOR'
										icon={item.icon}
										iconSize={20}
									/>
									<span className='generalStyles__mlFix'>{item.name}</span>
								</Option>
							))}
						</Select>
					</div>
					<div className='generalStyles__infoTooltipIconBlank' />
				</div>
			</div>
		</div>
	);
}

export default IconDefinitionElevator;
