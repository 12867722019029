import React from "react";
// ANT DESIGN COMPONENTS
import { Breadcrumb } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateServerBreadcrumb } from "../../../redux/servers/serverSlice";

library.add(faUser);

function ServersBreadcrumb() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { serverBreadcrumb } = useSelector((state: any) => state.server);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const updateBreadCrumbBackToServers = () => {
		dispatch(updateServerBreadcrumb([]));
	};

	const updateBreadCrumbBackToBanks = (index: number) => {
		if (index === 0) {
			dispatch(updateServerBreadcrumb([serverBreadcrumb[0]]));
		}
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Breadcrumb className=''>
			<Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
			<Breadcrumb.Item
				className={
					serverBreadcrumb.length === 0 ? "generalStyles__breadcrumbItem" : "generalStyles__breadcrumbItemInactive"
				}
				onClick={updateBreadCrumbBackToServers}
			>
				{t("header.servers")}
			</Breadcrumb.Item>
			{serverBreadcrumb.map((item: string, index: number) => (
				<Breadcrumb.Item
					onClick={() => {
						updateBreadCrumbBackToBanks(index);
					}}
					className={
						serverBreadcrumb.length === index + 1
							? "generalStyles__breadcrumbItem"
							: "generalStyles__breadcrumbItemInactive"
					}
					key={index}
				>
					{item}
				</Breadcrumb.Item>
			))}
		</Breadcrumb>
	);
}

export default ServersBreadcrumb;
