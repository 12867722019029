import React, { useState } from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTES
import IconDefinitionIcons from "../../tools/iconDefinition/IconDefinitionIcons";
// INTERFACES
import { RootState } from "../../../app/store";

function MapViewPin({
  icon,
  direction,
  orientation,
  liftType,
  carName,
  isCursor,
}: {
  icon: string;
  direction: "top" | "left" | "bottom" | "right";
  orientation: "horizontal" | "vertical";
  liftType: "elevator" | "escalator" | "movingwalk" | "map";
  carName: string;
  isCursor: boolean;
}) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const [showContent, setShowContent] = useState<boolean>(false);
  const [boxSize, setBoxSize] = useState<"sm" | "md" | "lg">("lg");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { theme } = useSelector((state: RootState) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleMouseOver = () => {
    setShowContent(true);
  };
  const handleMouseLeave = () => {
    setShowContent(false);
  };
  const getPinClass = () => {
    let pinClass = "mapViewStyles__box";
    pinClass = pinClass.concat(" mapViewStyles__box__", theme);
    if (showContent) {
      pinClass = pinClass.concat(
        " mapViewStyles__box__",
        boxSize,
        "__",
        orientation,
        "__",
        "expanded"
      );
    } else {
      pinClass = pinClass.concat(" mapViewStyles__box__minimized");
    }
    pinClass = pinClass.concat(
      " mapViewStyles__arrow__",
      direction,
      "__",
      theme
    );
    pinClass = pinClass.concat(
      " mapViewStyles__box__",
      boxSize,
      "__",
      orientation
    );
    return pinClass;
  };

  const getAlignItemClass = () => {
    if (direction === "top") {
      return "flex-start";
    }
    if (direction === "bottom") {
      return "flex-end";
    }
    return "center";
  };

  const getJustifyContentClass = () => {
    if (direction === "top") {
      return "center";
    }
    if (direction === "bottom") {
      return "center";
    }
    if (direction === "right") {
      return "flex-end";
    }
    if (direction === "left") {
      return "flex-start";
    }
    return "center";
  };
  // ************************************************ */
  // USE EFFECT ************************************* */
  React.useEffect(() => {
    if (showContent) {
      setBoxSize("lg");
    }
  }, [showContent]);

  React.useEffect(() => {
    if (isCursor) {
      setBoxSize("lg");
      setShowContent(true);
    }
  }, [isCursor]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div
      style={{
        border: "solid 2px purple",
      }}
    >
      {/** ************************************************************** */}
      {/** VERTICAL */}
      {orientation === "vertical" && (
        <div
          className={`mapViewStyles__box__${boxSize}__vertical__expanded`}
          style={{
            justifyContent: getJustifyContentClass(),
            alignItems: getAlignItemClass(),
            display: "flex",
            borderRadius: "8px",
          }}
        >
          <div
            className={getPinClass()}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onFocus={() => {}}
          >
            {/** CAR NAME */}
            {showContent && (
              <div
                style={{
                  height: "16px",
                  fontSize: "10px",
                  width: "90%",
                  marginLeft: "5%",
                  marginRight: "5%",
                  paddingTop: "3px",
                }}
                className="generalStyles__textEllipsis"
              >
                <p>{carName}</p>
              </div>
            )}
            {/** ICON */}
            {icon && (
              <div
                style={{
                  width: "100%",
                }}
              >
                <IconDefinitionIcons
                  isMap
                  expanded={showContent}
                  liftType={liftType.toUpperCase()}
                  icon={icon} // last short message
                  iconSize={16}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {/** ************************************************************** */}
      {/** HORIZONTAL */}
      {orientation === "horizontal" && (
        <div
          className={`mapViewStyles__box__${boxSize}__horizontal__expanded`}
          style={{
            justifyContent: getJustifyContentClass(),
            alignItems: getAlignItemClass(),
            display: "flex",
            borderRadius: "8px",
          }}
        >
          {" "}
          <div
            className={getPinClass()}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onFocus={() => {}}
          >
            <div className="generalStyles__flex">
              {/** CAR NAME */}
              {showContent && (
                <div
                  style={{
                    height: "16px",
                    fontSize: "10px",
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    paddingTop: "3px",
                  }}
                  className="generalStyles__textEllipsis"
                >
                  <p>{carName || "--"}</p>
                </div>
              )}
              {/** ICON */}
              {icon && (
                <div
                  style={{
                    width: "40px",
                  }}
                >
                  <IconDefinitionIcons
                    isMap
                    expanded={showContent}
                    liftType={liftType.toUpperCase()}
                    icon={icon} // last short message
                    iconSize={16}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MapViewPin;
