import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// REDUX
import { useGetStatesMutation } from "../../../redux/countriesStatesCitiesAPI";
// INTERFACES
import { FormSelectStateInterface } from "../../../interfaces/Global.interface";

function FormInputSelectState({
	inputValue,
	inputOnChange,
	inputLabelName,
	inputVariableName,
	country,
}: FormSelectStateInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [listStates, setListStates] = useState<any[]>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetStates, { data, isLoading, reset: resetGetStates }] =
		useGetStatesMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */
	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			resetGetStates();
		};
	}, []);

	useEffect(() => {
		if (country && country !== "") {
			triggerGetStates(country);
		}
	}, [country]);

	useEffect(() => {
		if (data && data.data && data.data.states && data.data.states.length > 0) {
			setListStates(data.data.states);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='col-md-12 mt-3'>
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span>{inputLabelName}</span>
				</div>
				<div className='generalStyles__width100'>
					<Select
						className='generalStyles__width100'
						loading={isLoading}
						disabled={listStates.length === 0}
						showSearch
						optionFilterProp='children'
						onChange={(event: any) => {
							inputOnChange(event, inputVariableName);
						}}
						filterOption={(input: any, option: any) =>
							option.children.toLowerCase().includes(input.toLowerCase())
						}
						size='small'
						value={inputValue}
					>
						{listStates.map((st) => (
							<Option key={st.name} value={st.name}>
								{st.name}
							</Option>
						))}
					</Select>
				</div>
				<div className='generalStyles__infoTooltipIconBlank' />
			</div>
		</div>
	);
}

export default FormInputSelectState;
