import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faUserShield } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import DrawerRowDivider from "./DrawerRowDivider";

function DrawerRoleInformation({ data }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: any) => state.home);
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<br />
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faUserShield} title={t("organizationManagement.roleInformation")} />
			{/** ************************************************* */}
			<div className={`drawer__box__${theme}`}>
				{/* ID */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>ID:</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>{data.id ? data.id : "--"}</p>
					</div>
				</div>
				{/* NAME */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("organizationManagement.name")}</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>{data.name ? data.name : "--"}</p>
					</div>
				</div>
				{/* STATUS */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}> {t("userManagement.status")}</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data.active ? (
								<Tag
									color='success'
									icon={<FontAwesomeIcon icon={faCheckCircle} className='generalStyles__mrFix' />}
									className='drawer__tag'
								>
									{t("userManagement.active")}
								</Tag>
							) : (
								<Tag
									color='error'
									icon={<FontAwesomeIcon icon={faCheckCircle} className='generalStyles__mrFix' />}
									className='drawer__tag'
								>
									{t("userManagement.lock")}
								</Tag>
							)}{" "}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DrawerRoleInformation;
