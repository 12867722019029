import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Alert, Button, Table, Empty, Tag, Input, Tooltip } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faX } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateShowTable, updateFormAction, updateMessageType } from "../../../redux/messageType/messagesTypeSlice";
import {
	useLazyGetMessagesTypeQuery,
	usePostMessageTypeMutation,
	usePutMessageTypeMutation,
} from "../../../redux/messageType/messagesTypeAPI";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
import MessageTypeTableActions from "./MessageTypeTableActions";
import MessageTypeHeader from "./MessageTypeHeader";
import GlobalAlert2 from "../../home/GlobalAlert2";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// INTERFACES
import { RootState } from "../../../app/store";
import { MessageTypeInterface } from "../../../interfaces/MessageType.interface";

function MessageTypeTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Column, ColumnGroup } = Table;
	const BLANK_MESSAGE_TYPE = {
		id: "",
		name: "",
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<MessageTypeInterface[]>([]);
	const [messageType, setMessageType] = useState<MessageTypeInterface>(BLANK_MESSAGE_TYPE);
	const [errorMessage, setErrorMessage] = useState<any>({});
	const [totalRows, setTotalRows] = useState<number>(0);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetMessageTypes,
		{
			data: dataGetMessageTypes,
			isLoading: isLoadingGetMessageTypes,
			isFetching: isFetchingGetMessageTypes,
			isError: isErrorGetMessageTypes,
			error: errorGetMessageTypes,
		},
	] = useLazyGetMessagesTypeQuery();

	const [
		triggerPostMessageType,
		{
			isSuccess: isSuccessPostMessageType,
			isLoading: isLoadingPostMessageType,
			isError: isErrorPostMessageType,
			error: errorPostMessageType,
			reset: resetPostMessageType,
		},
	] = usePostMessageTypeMutation();

	const [
		triggerPutMessageType,
		{
			isSuccess: isSuccessPutMessageType,
			isLoading: isLoadingPutMessageType,
			isError: isErrorPutMessageType,
			error: errorPutMessageType,
			reset: resetPutMessageType,
		},
	] = usePutMessageTypeMutation();

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { permissions } = useSelector((state: any) => state.user);
	const {
		refreshTable,
		showTable,
		formAction,
		messageType: selectedMessageType,
	} = useSelector((state: RootState) => state.messageType);

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickShowForm = () => {
		setIsLoading(true);
		dispatch(updateShowTable(true));
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetMessageTypes({
			token,
		});
	};

	const onClickRefreshTable = async () => {
		getInitialData();
	};

	const onChangeFormValue = (newValue: string, variableName: string) => {
		const copy: MessageTypeInterface = structuredClone(messageType);
		copy[variableName] = newValue;
		setMessageType(copy);
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		if (formAction === "CREATE") {
			const BODY = {
				token,
				body: messageType,
			};
			triggerPostMessageType(BODY);
		} else {
			const copy = structuredClone(messageType);
			const BODY = {
				token,
				id: messageType.id,
				body: copy,
			};
			triggerPutMessageType(BODY);
		}
	};

	const resetForm = () => {
		resetPostMessageType();
		resetPutMessageType();
		setMessageType(BLANK_MESSAGE_TYPE);
		setErrorMessage({});
		dispatch(updateFormAction("CREATE"));
		dispatch(updateMessageType(BLANK_MESSAGE_TYPE));
	};

	const checkFormIsValid = () => {
		let formIsValid = true;
		if (messageType.name === "") {
			formIsValid = false;
		}
		return formIsValid;
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (showTable) {
			getInitialData();
		}
	}, [showTable]);

	useEffect(() => {
		if (refreshTable) {
			onClickRefreshTable();
		}
	}, [refreshTable]);

	useEffect(() => {
		if (dataGetMessageTypes && dataGetMessageTypes.data) {
			setData(dataGetMessageTypes.data);
			setTotalRows(dataGetMessageTypes.total);
		}
	}, [dataGetMessageTypes]);

	useEffect(() => {
		if (errorGetMessageTypes) {
			setErrorMessage(errorGetMessageTypes);
			setData([]);
		}
	}, [errorGetMessageTypes]);

	useEffect(() => {
		resetForm();
	}, [isSuccessPostMessageType, isSuccessPutMessageType, isErrorPostMessageType, isErrorPutMessageType]);

	useEffect(() => {
		if (formAction === "CREATE") {
			setMessageType(BLANK_MESSAGE_TYPE);
		}
	}, [formAction]);

	useEffect(() => {
		setMessageType(selectedMessageType);
	}, [selectedMessageType]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ----------------------------------------------------- */}
			{/** SHOW ALERT */}
			{!showTable && (
				<div className='mb-4'>
					<Alert
						message={t("general.warning")}
						description={t("configuration.action.warningDescription")}
						type='warning'
						showIcon
						closable
					/>
					<Button className='buttonStyle__2 configuration__editRootPermisionsButton' onClick={onClickShowForm}>
						{t("configuration.action.showRootConfiguration")}
					</Button>
				</div>
			)}
			{/** ----------------------------------------------------- */}
			{/** ACTION FORM */}
			{showTable && (
				<div>
					{validatePermission("permission-not-defined", permissions) ? (
						<>
							<MessageTypeHeader />
							<Table
								locale={{
									emptyText: (
										<TableNoDataBox
											isError={isErrorGetMessageTypes}
											errorMessage={
												errorMessage && errorMessage.data && errorMessage.data.message
													? errorMessage.data.message
													: "Error"
											}
											noItemsFound='No actions found'
										/>
									),
								}}
								rowClassName={(record, index) => getTableRowClass(index, theme)}
								dataSource={data}
								pagination={{
									defaultPageSize: 10,
									showSizeChanger: true,
									pageSizeOptions: ["10", "25", "50", "100"],
								}}
								size='small'
								className='mt-3'
								loading={
									isFetchingGetMessageTypes ||
									isLoading ||
									isLoadingGetMessageTypes ||
									isLoadingPostMessageType ||
									isLoadingPutMessageType
								}
								footer={() => (
									<div className='generalStyles__flexEnd'>
										<Tag>{totalRows} Rows</Tag>
									</div>
								)}
							>
								{/** NAME */}
								<ColumnGroup title='Name'>
									<Column
										width={200}
										title={
											<Input
												size='small'
												placeholder='Name'
												value={formAction === "CREATE" ? messageType.name : ""}
												disabled={formAction === "EDIT"}
												onChange={(event: React.FormEvent<HTMLInputElement>) => {
													onChangeFormValue(event.currentTarget.value, "name");
												}}
											/>
										}
										dataIndex='name'
										key='name'
										render={(text, record: MessageTypeInterface) => (
											<div>
												{formAction === "EDIT" && record.id === messageType.id ? (
													<Input
														size='small'
														placeholder='Name'
														value={messageType.name}
														onChange={(event: React.FormEvent<HTMLInputElement>) => {
															onChangeFormValue(event.currentTarget.value, "name");
														}}
													/>
												) : (
													<span>{text}</span>
												)}
											</div>
										)}
									/>
								</ColumnGroup>
								{/** ACTIONS */}
								<ColumnGroup align='center' title={t("edgeManagement.banks.actions")} width={200}>
									<Column
										align='center'
										width={200}
										title={
											<div className='generalStyles__flexCenter'>
												<div>
													<Tooltip title={t("general.save")}>
														<Button
															className={formAction === "CREATE" && checkFormIsValid() ? "buttonStyle__10" : ""}
															disabled={!checkFormIsValid() || formAction === "EDIT"}
															onClick={onClickSave}
															icon={<FontAwesomeIcon icon={faSave} />}
														/>
													</Tooltip>
												</div>
												<div className='generalStyles__mlFix'>
													<Tooltip title={t("general.clear")}>
														<Button
															className={formAction === "CREATE" ? "buttonStyle__14" : ""}
															onClick={resetForm}
															disabled={formAction === "EDIT"}
															icon={<FontAwesomeIcon icon={faX} />}
														/>
													</Tooltip>
												</div>
											</div>
										}
										key='action'
										render={(_: any, record: MessageTypeInterface) => (
											<div>
												{formAction === "EDIT" && record.id === messageType.id ? (
													<div className='generalStyles__flexCenter'>
														<div>
															<Tooltip title={t("general.save")}>
																<Button
																	className='buttonStyle__10'
																	disabled={!checkFormIsValid()}
																	onClick={onClickSave}
																	icon={<FontAwesomeIcon icon={faSave} />}
																/>
															</Tooltip>
														</div>
														<div className='generalStyles__mlFix'>
															<Tooltip title={t("general.cancel")}>
																<Button
																	className='buttonStyle__14'
																	onClick={resetForm}
																	icon={<FontAwesomeIcon icon={faX} />}
																/>
															</Tooltip>
														</div>
													</div>
												) : (
													<MessageTypeTableActions selectedItem={record} />
												)}
											</div>
										)}
									/>
								</ColumnGroup>
							</Table>
						</>
					) : (
						<>
							<div className='generalStyles__noAccessToListTable'>
								<Empty
									description={t("organizationManagement.listNotAvailableOrganizations")}
									image={Empty.PRESENTED_IMAGE_SIMPLE}
								/>
							</div>
						</>
					)}
				</div>
			)}
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostMessageType}
				isSuccess={isSuccessPostMessageType}
				requestType='POST'
				error={errorPostMessageType}
				name='Message Type'
			/>
			<GlobalAlert2
				isError={isErrorPutMessageType}
				isSuccess={isSuccessPutMessageType}
				requestType='PUT'
				error={errorPutMessageType}
				name='Message Type'
			/>
		</div>
	);
}

export default MessageTypeTable;
