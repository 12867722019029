import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Tabs } from "antd";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateOpenModalControlPanel,
	updateLiftSelected,
	updateLayoutSelected,
} from "../../../redux/monitoring/monitoringSlice";
// COMPONENTS
import ControlPanelHeader from "./ControlPanelHeader";
import ControlPanelElevator from "./ControlPanelElevator";
import ControlPanelHallCall from "./ControlPanelHallCall";
import ControlPanelFaults from "./ControlPanelFaults";
import ControlPanelActions from "./ControlPanelActions";
import ControlPanelStatus from "./ControlPanelStatus";
import ButtonClose from "../../components/buttons/ButtonClose";
// INTERFACES
import {
	MonitoringModalSettingsInterface,
	SocketMessageInterface,
	ControlPanelBankFloors,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";

function ControlPanel({
	socketMessage,
	iconDefinitionElevator,
	iconDefinitionEscalator,
	iconDefinitionMovingwalk,
}: MonitoringModalSettingsInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const VALID_MESSAGES_NO_ICON = ["PF", "FAULT"];

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const {
		liftSelected,
		layoutSelected,
		openModalControlPanel,
		controlPanelTab,
		panelDefaultState,
		controlPanelDrawerPlacement,
	} = useSelector((state: RootState) => state.monitoring);
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [tabActivekey, setTabActiveKey] = useState<string>("FAULTS");
	const [elevatorSocketMessage, setElevatorSocketMessage] = useState<SocketMessageInterface>(panelDefaultState);
	const [escalatorSocketMessage, setEscalatorSocketMessage] = useState<SocketMessageInterface>(panelDefaultState);
	const [movingWalkSocketMessage, setMovingWalkSocketMessage] = useState<SocketMessageInterface>(panelDefaultState);
	const [displayFrontDoors, setDisplayFrontDoors] = useState<boolean>(true);
	const [displayRearDoors, setDisplayRearDoors] = useState<boolean>(true);
	const [bankFloors, setBankFloors] = useState<ControlPanelBankFloors[]>([]);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenModalControlPanel(false));
		dispatch(
			updateLiftSelected({
				id: "",
				bank_id: "",
				control_panel: {},
				direction: "",
				name: "",
				position: 0,
				type: "",
				lift_floors: [],
			})
		);
		dispatch(
			updateLayoutSelected({
				value: "",
				label: "",
				children: [],
				type: "",
				idOrganization: "",
				idServer: "",
				idChannel: "",
				idBank: "",
				nameOrganization: "",
				nameServer: "",
				nameChannel: "",
				typeChannel: "",
				id: "",
				active: false,
				board: 0,
				columns: 0,
				floor_below_lobby: 0,
				floor_count: 0,
				logical_port: 0,
				name: "",
				number_of_lifts: 0,
				channel_id: "",
				rear_doors: false,
				stops: 0,
				control_panel: {},
				bank_floors: [],
				lifts: [],
				floorsInUse: [],
				i: "",
				x: 0,
				y: 0,
				w: 0,
				h: 0,
				l: 0,
				f: 0,
				floor: 0,
			})
		);
	};

	const generateFrontRearConfiguration = () => {
		let bankHasFrontDoors = false;
		let bankHasRearDoors = false;
		const newBankFloors: ControlPanelBankFloors[] = [];
		const liftFloors = liftSelected.lift_floors;
		layoutSelected.bank_floors.map((floor: any) => {
			// Check if has front or rear doors
			if (floor.front_door) {
				bankHasFrontDoors = true;
			}
			if (floor.rear_door) {
				bankHasRearDoors = true;
			}
			// Generate new bank floors configuration
			const findSpecificLiftFloor = liftFloors.find((item) => item.bank_floor_id === floor.id);
			if (findSpecificLiftFloor) {
				newBankFloors.push({
					id: floor.id,
					name: floor.name,
					floor_no: floor.floor_no,
					front_door: findSpecificLiftFloor.front_door,
					rear_door: findSpecificLiftFloor.rear_door,
				});
			}
			return true;
		});
		setBankFloors(newBankFloors);
		setDisplayFrontDoors(bankHasFrontDoors);
		setDisplayRearDoors(bankHasRearDoors);
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		if (
			socketMessage &&
			socketMessage.lift_id &&
			liftSelected &&
			liftSelected.id &&
			liftSelected.id === socketMessage.lift_id
		) {
			if (
				socketMessage.lift_type === "ELEVATOR" &&
				(iconDefinitionElevator[socketMessage.icon.name.toLowerCase()] ||
					VALID_MESSAGES_NO_ICON.includes(socketMessage.icon.name.replace(/[0-9]/g, "").toUpperCase()) ||
					socketMessage.icon.type.toUpperCase() === "FAULT" ||
					socketMessage.icon.type.toUpperCase() === "CARSERVICE" ||
					socketMessage.icon.type.toUpperCase() === "TRAFFIC")
			) {
				setElevatorSocketMessage(socketMessage);
			} else if (
				socketMessage.lift_type === "ESCALATOR" &&
				(iconDefinitionEscalator[socketMessage.icon.name.toLowerCase()] ||
					VALID_MESSAGES_NO_ICON.includes(socketMessage.icon.name.replace(/[0-9]/g, "").toUpperCase()) ||
					socketMessage.icon.type.toUpperCase() === "FAULT" ||
					socketMessage.icon.type.toUpperCase() === "CARSERVICE" ||
					socketMessage.icon.type.toUpperCase() === "TRAFFIC")
			) {
				setEscalatorSocketMessage(socketMessage);
			} else if (
				socketMessage.lift_type === "MOVINGWALK" &&
				(iconDefinitionMovingwalk[socketMessage.icon.name.toLowerCase()] ||
					VALID_MESSAGES_NO_ICON.includes(socketMessage.icon.name.replace(/[0-9]/g, "").toUpperCase()) ||
					socketMessage.icon.type.toUpperCase() === "FAULT" ||
					socketMessage.icon.type.toUpperCase() === "CARSERVICE" ||
					socketMessage.icon.type.toUpperCase() === "TRAFFIC")
			) {
				setMovingWalkSocketMessage(socketMessage);
			}
		}
	}, [socketMessage]);

	useEffect(() => {
		setTabActiveKey(controlPanelTab.tab);
	}, [controlPanelTab]);

	useEffect(() => {
		if (
			layoutSelected &&
			layoutSelected.bank_floors &&
			layoutSelected.lifts &&
			liftSelected &&
			liftSelected.lift_floors
		) {
			generateFrontRearConfiguration();
		}
	}, [layoutSelected]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='650'
			placement={controlPanelDrawerPlacement}
			closable={false}
			onClose={onClickClose}
			open={openModalControlPanel}
			footer={
				<div style={{ textAlign: "right" }}>
					<ButtonClose onClick={onClickClose} />
				</div>
			}
		>
			<div>
				{layoutSelected && liftSelected && layoutSelected.bank_floors && openModalControlPanel && (
					<>
						{/** ************************************************** */}
						{/* HEADER */}
						<div>
							<ControlPanelHeader
								lift={liftSelected}
								layout={layoutSelected}
								iconDefinitionElevator={iconDefinitionElevator}
								iconDefinitionEscalator={iconDefinitionEscalator}
								iconDefinitionMovingwalk={iconDefinitionMovingwalk}
								socketMessageElevator={elevatorSocketMessage}
								socketMessageEscalator={escalatorSocketMessage}
								socketMessageMovingwalk={movingWalkSocketMessage}
							/>
						</div>
						<br />
						<div>
							{/** ************************************************** */}
							{/* BODY */}
							<Tabs
								onTabClick={(key: string) => {
									setTabActiveKey(key);
								}}
								activeKey={tabActivekey}
							>
								{controlPanelTab.liftType === "ELEVATOR" && (
									<>
										{validatePermission("monitor-control-panel-car-calls", permissions) && (
											<Tabs.TabPane key='CARCALLS' tab='Car Calls' disabled={liftSelected.id === ""}>
												<ControlPanelElevator
													currentFloor={elevatorSocketMessage.position}
													frontLock={elevatorSocketMessage.car_calls.front_lock || []}
													rearLock={elevatorSocketMessage.car_calls.rear_lock || []}
													hasFrontDoors={displayFrontDoors}
													hasRearDoors={displayRearDoors}
													bankFloors={bankFloors}
												/>
											</Tabs.TabPane>
										)}
										{validatePermission("monitor-control-panel-hall-calls", permissions) && (
											<Tabs.TabPane key='HALLCALLS' tab='Hall Calls'>
												<ControlPanelHallCall
													currentFloor={elevatorSocketMessage.position}
													frontLock={elevatorSocketMessage.car_calls.front_lock || []}
													rearLock={elevatorSocketMessage.car_calls.rear_lock || []}
													hasFrontDoors={displayFrontDoors}
													hasRearDoors={displayRearDoors}
													bankFloors={bankFloors}
												/>
											</Tabs.TabPane>
										)}
										{validatePermission("monitor-control-panel-actions", permissions) && (
											<Tabs.TabPane key='ACTIONS' tab='Actions'>
												<ControlPanelActions
													carService={elevatorSocketMessage.service_mode.name}
													liftId={liftSelected && liftSelected.id ? liftSelected.id : ""}
													actions={layoutSelected && layoutSelected.actions ? layoutSelected.actions : null}
													serverId={layoutSelected && layoutSelected.idServer ? layoutSelected.idServer : ""}
													isControlPanel
												/>
											</Tabs.TabPane>
										)}
									</>
								)}
								{validatePermission("monitor-control-panel-faults", permissions) && (
									<Tabs.TabPane key='FAULTS' tab='Faults'>
										<ControlPanelFaults />
									</Tabs.TabPane>
								)}
								{controlPanelTab.liftType === "ELEVATOR" && (
									<>
										{validatePermission("monitor-control-panel-status", permissions) && (
											<Tabs.TabPane key='STATUS' tab='Status'>
												<ControlPanelStatus layout={layoutSelected} socketMessage={elevatorSocketMessage} />
											</Tabs.TabPane>
										)}
									</>
								)}
							</Tabs>
						</div>
					</>
				)}
			</div>
		</Drawer>
	);
}

export default ControlPanel;
