import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Tooltip, Tag } from "antd";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateLiftSelected,
	updateLayoutSelected,
	updateModalControlPanelTab,
	updateExistsFaultsInMonitoringView,
	updateExistsFaultsInLiftSelected,
	updatePanelDefaultState,
	updateOpenModalControlPanel,
	updateControlPanelDrawerPlacement,
} from "../../../redux/monitoring/monitoringSlice";
// INTERFACES
import {
	MonitoringElevatorFullViewNameInteface,
	SocketMessageInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";

function MonitoringElevatorFullViewName({ layout, lift, socketMessage }: MonitoringElevatorFullViewNameInteface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		car_calls: { front: [], rear: [], front_lock: [], rear_lock: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
			},
			down: {
				front: [],
				rear: [],
			},
			up_assigned: { front: [], rear: [] },
			down_assigned: { front: [], rear: [] },
		},
		faults: false,
		position: 0,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [hasFaults, setHasFaults] = useState<boolean>(false);
	const [faultMessage, setFaultMessage] = useState<boolean>(false);
	const [lastSocketMessage, setLastSocketMessage] = useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { faults } = useSelector((state: RootState) => state.monitoring);
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getTabKey = () => {
		const key = "CARCALLS";
		if (hasFaults || faultMessage) {
			return "FAULTS";
		}
		if (validatePermission("monitor-control-panel-car-calls", permissions)) {
			return "CARCALLS";
		}
		if (validatePermission("monitor-control-panel-hall-calls", permissions)) {
			return "HALLCALLS";
		}
		if (validatePermission("monitor-control-panel-actions", permissions)) {
			return "ACTIONS";
		}
		if (validatePermission("monitor-control-panel-faults", permissions)) {
			return "FAULTS";
		}
		if (validatePermission("monitor-control-panel-status", permissions)) {
			return "STATUS";
		}
		return key;
	};

	const getDrawerPlacement = (e: any) => {
		const clickX = e.clientX;
		const screenWidth = window.innerWidth;
		if (clickX > screenWidth / 2) {
			dispatch(updateControlPanelDrawerPlacement("left"));
		} else {
			dispatch(updateControlPanelDrawerPlacement("right"));
		}
	};

	const onClickOpenModalElevator = (e: any) => {
		getDrawerPlacement(e);
		dispatch(updateLiftSelected(lift));
		dispatch(updateLayoutSelected(layout));
		dispatch(
			updateModalControlPanelTab({
				liftType: "ELEVATOR",
				tab: getTabKey(),
			})
		);
		dispatch(updateOpenModalControlPanel(true));
		dispatch(updateExistsFaultsInLiftSelected(hasFaults));
		dispatch(updatePanelDefaultState(lastSocketMessage));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		// FAULT MESSAGES
		if (socketMessage && socketMessage.lift_id && lift && lift.id === socketMessage.lift_id) {
			setLastSocketMessage(socketMessage);
		}
	}, [socketMessage]);

	useEffect(() => {
		// FAULT MESSAGE
		if (socketMessage && socketMessage.icon && socketMessage.icon.type.toUpperCase() === "FAULT") {
			setFaultMessage(true);
			setHasFaults(true);
			dispatch(updateExistsFaultsInMonitoringView(true));
			setTimeout(() => {
				setFaultMessage(false);
			}, 10000);
		}
	}, [lastSocketMessage]);

	useEffect(() => {
		if (
			faults &&
			faults.liftId &&
			faults.liftId !== "" &&
			lift &&
			faults.type === "LIFT" &&
			faults.liftId === lift.id
		) {
			setHasFaults(false);
			setFaultMessage(false);
		}
		if (faults && faults.type === "ALL") {
			setHasFaults(false);
			setFaultMessage(false);
		}
	}, [faults]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div
			className='mt-2'
			role='button'
			tabIndex={0}
			onKeyDown={() => {}}
			onClick={onClickOpenModalElevator}
			style={{ fontSize: "9px", fontWeight: "bolder" }}
		>
			{faultMessage || hasFaults ? (
				<div>
					<div>
						<Tooltip placement='top' title={lift.name}>
							<span className='generalStyles__error'>
								{lift && lift.name ? lift.name.toUpperCase().substring(0, 6) : ""}
							</span>
						</Tooltip>
					</div>
					<div>
						<div>
							<Tag
								style={{
									marginLeft: "-3px",
									marginTop: "5px",
									fontSize: "9px",
									width: "43px",
								}}
								color='red'
							>
								FAULT
							</Tag>
						</div>
						{lastSocketMessage.service_mode.name !== "" && (
							<div>
								<Tooltip placement='top' title={lastSocketMessage.service_mode.description}>
									<Tag
										style={{
											marginLeft: "-3px",
											marginTop: "5px",
											width: "43px",
										}}
										color='cyan'
									>
										{lastSocketMessage.service_mode.name.toUpperCase().substring(0, 3)}
									</Tag>
								</Tooltip>
							</div>
						)}
					</div>
				</div>
			) : (
				<div>
					<div>
						<Tooltip placement='top' title={lift.name}>
							<span>{lift && lift.name ? lift.name.toUpperCase().substring(0, 6) : ""}</span>
						</Tooltip>
					</div>
					{lastSocketMessage.service_mode.name !== "" && (
						<div>
							<Tooltip placement='top' title={lastSocketMessage.service_mode.description}>
								<Tag
									style={{
										marginLeft: "-3px",
										marginTop: "5px",
										width: "43px",
									}}
									color='cyan'
								>
									{lastSocketMessage.service_mode.name.toUpperCase().substring(0, 3)}
								</Tag>
							</Tooltip>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default MonitoringElevatorFullViewName;
