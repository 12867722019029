import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { DatePicker } from "antd";
// MOMENT DATE FORMAT
import moment from "moment";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterDateWeek() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters, blockFilters } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChange = (date: any, dateString: any) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		const [startYear, startWeek] = dateString.split("-");
		const startWeekNumber = startWeek.replace(/(st|th|nd)$/, "");
		const [endYear, endWeek] = dateString.split("-");
		const endWeekNumber = endWeek.replace(/(st|th|nd)$/, "");
		const date1 = moment()
			.year(parseInt(startYear, 10))
			.week(parseInt(startWeekNumber, 10))
			.startOf("week");
		const date2 = moment()
			.year(parseInt(endYear, 10))
			.week(parseInt(endWeekNumber, 10))
			.endOf("week");
		copy.startDate = moment(date1).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		copy.endDate = moment(date2).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		dispatch(updateFilters(copy));
	};

	const generateDefaultWeekDate = () => {
		const startWeekNumber = moment().week();
		const endWeekNumber = moment().week();
		const year = moment().year();
		const date1 = moment().year(year).week(startWeekNumber).startOf("week");
		const date2 = moment().year(year).week(endWeekNumber).endOf("week");
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.startDate = moment(date1).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		copy.endDate = moment(date2).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		generateDefaultWeekDate();
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************* */}
			{/** BODY */}
			<DatePicker
				picker='week'
				className='generalStyles__width100'
				style={{ height: "24px" }}
				onChange={onChange}
				value={moment(filters.startDate)}
				allowClear={false}
				disabled={blockFilters}
			/>
		</div>
	);
}

export default ReportsFilterDateWeek;
