import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Select, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { useLazyGetLiftsQuery } from "../../../redux/lifts/liftAPI";
import {
	updateFilters,
	updateLifts,
	updateIdLiftPositionCero,
	updateBanksGroups,
} from "../../../redux/reports/reportsSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterGetLifts() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option, OptGroup } = Select;
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters, blockFilters, lifts, banksGroups } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetLifts, { data, isLoading, isError, isFetching }] =
		useLazyGetLiftsQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initialData = async () => {
		const token = await GETJwtToken();
		triggerGetLifts({
			page: 1,
			limit: 9999,
			token,
		});
	};

	const onChangeLift = (newValue: string) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		const index = lifts.findIndex((item: any) => item.id === newValue);
		if (lifts && lifts[index]) {
			copy.liftId = lifts[index].id;
			copy.liftName = lifts[index].name;
			if (lifts[index].bank) {
				copy.bankId = lifts[index].bank.id;
				copy.bankName = lifts[index].bank.name;
			}
		}
		copy.liftId = newValue;
		dispatch(updateFilters(copy));
	};

	const callFirstLift = () => {
		if (lifts && lifts[0] && lifts[0].id && filters.liftId === "") {
			onChangeLift(lifts[0].id);
		}
	};

	const generateGroupsBanks = (response: any) => {
		const newGroups: any = [];
		response.map((item: any) => {
			if (item && item.bank && item.bank.name && item.bank.id) {
				newGroups.push({
					name: item.bank.name,
					id: item.bank.id,
				});
			}
			return true;
		});
		// Sort by name
		newGroups.sort((a: any, b: any) => {
			if (a.name.toLowerCase() < b.name.toLowerCase()) {
				return -1;
			}
			if (a.name.toLowerCase() > b.name.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		// Remove repeat items
		const uniqueArray = newGroups.filter(
			(item: any, index: any, self: any) =>
				index === self.findIndex((obj: any) => obj.id === item.id)
		);
		//
		dispatch(updateBanksGroups(uniqueArray));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		initialData();
	}, []);

	useEffect(() => {
		if (data && data.data && data.data.length > 0) {
			dispatch(updateLifts(data.data));
			dispatch(updateIdLiftPositionCero(data.data[0].id));
			generateGroupsBanks(data.data);
		}
	}, [data]);

	useEffect(() => {
		if (isError) {
			dispatch(updateLifts([]));
		}
	}, [isError]);

	useEffect(() => {
		callFirstLift();
	}, [lifts]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__width100'>
			<Tooltip title={lifts.length === 0 ? t("report.noLifts") : ""}>
				<Select
					className='generalStyles__width100'
					disabled={lifts.length === 0 || blockFilters}
					showSearch
					size='small'
					optionFilterProp='children'
					placeholder={t("report.selectLift")}
					loading={isLoading || isFetching}
					value={filters.liftId === "" ? null : filters.liftId}
					onChange={onChangeLift}
				>
					{banksGroups.map((group: any) => (
						<OptGroup key={group.id} label={`BANK / ${group.name}`}>
							{lifts
								.filter((bank: any) => bank && bank.bank_id === group.id)
								.map((server: any) => (
									<Option key={server.id} value={server.id}>
										{server.name}
									</Option>
								))}
						</OptGroup>
					))}
				</Select>
			</Tooltip>
		</div>
	);
}

export default ReportsFilterGetLifts;
