/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACE
import { HomeStateInterface } from "../../interfaces/Home.interface";
import { UserLoggedInterface } from "../../interfaces/User.interface";

const initialState: HomeStateInterface = {
  userInformation: {},
  userLogged: {
    id: "",
    accepted_terms: "",
    cognito_id: "",
    created_offline_psw: false,
    email: "",
    first_name: "",
    last_name: "",
    org_id: "",
    pref_grid_id: "",
    pref_theme: "",
    role_id: "",
    status: "",
    org: {
      id: "",
      address: "",
      city: "",
      country: "",
      name: "",
      phone_code: "",
      phone: "",
      state: "",
      type: "",
    },
    role: {
      name: "",
      active: false,
      actions: [],
      offline: false,
      tree_key: false,
      sub_pages: {
        name: "",
        active: false,
        offline: false,
        tree_key: "",
        sub_pages: [],
        actions: [],
      },
    },
  },
  breadcrumb: {},
  theme: "light",
  language: "en",
  breadcrumbTitle: "",
  globalAlert: {},
  openDrawerUser: false,
  sideBarCollapsed: false,
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    updateUserInformation: (state, action: PayloadAction<Object>) => {
      state.userInformation = action.payload;
    },
    updateUserLogged: (state, action: PayloadAction<UserLoggedInterface>) => {
      state.userLogged = action.payload;
    },
    updateBreadcrumb: (state, action: PayloadAction<Object>) => {
      state.breadcrumb = action.payload;
    },
    updateTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
    },
    updateLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    updateBreadcrumbTitle: (state, action: PayloadAction<string>) => {
      state.breadcrumbTitle = action.payload;
    },
    updateGlobalAlert: (state, action: PayloadAction<Object>) => {
      state.globalAlert = action.payload;
    },
    updateOpenDrawerUser: (state, action: PayloadAction<boolean>) => {
      state.openDrawerUser = action.payload;
    },
    updateSideBarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sideBarCollapsed = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUserInformation,
  updateUserLogged,
  updateBreadcrumb,
  updateTheme,
  updateLanguage,
  updateBreadcrumbTitle,
  updateGlobalAlert,
  updateOpenDrawerUser,
  updateSideBarCollapsed,
} = homeSlice.actions;

export default homeSlice.reducer;
