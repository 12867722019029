import React from "react";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateOpenModalCreateNewOrganization,
	updateOrganizationRefreshTable,
	updateFormCreateEdit,
} from "../../../redux/organizations/organizationSlice";
// COMPONENTS
import ButtonRefreshTable from "../../components/buttons/ButtonRefreshTable";
import ButtonCreateItem from "../../components/buttons/ButtonCreateItem";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";

function OrganizationsHeader() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalCreateNewOrganization = () => {
		dispatch(updateFormCreateEdit("CREATE"));
		dispatch(updateOpenModalCreateNewOrganization(true));
	};

	const onClickReloadPage = () => {
		dispatch(updateOrganizationRefreshTable(true));
		setTimeout(() => {
			dispatch(updateOrganizationRefreshTable(false));
		}, 1000);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__pageHeader'>
			{validatePermission("orgs-create", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<ButtonCreateItem
						onClick={handleOpenModalCreateNewOrganization}
						label={t("organizationManagement.createNewOrganization")}
					/>
				</div>
			)}
			{validatePermission("orgs-view", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<ButtonRefreshTable
						onClick={onClickReloadPage}
						label={t("organizationManagement.refreshTable")}
					/>
				</div>
			)}
		</div>
	);
}

export default OrganizationsHeader;
