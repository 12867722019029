import React, { useEffect, useState, useRef } from "react";
// ANT DESIGN COMPONENTS
import { Spin } from "antd";
// HTML CANVAS
import html2canvas from "html2canvas";
// MOMENT DATE FORMAT
import moment from "moment";
// MUI CHARTS
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { HighlightItemData } from "@mui/x-charts/context";
import Box from "@mui/material/Box";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// CSS CUSTOM STYLES
import {
	getChartBackgroundColor,
	getChartTextColor,
	getChartTicklineColor,
	generateBarsDataFault,
	getChartAxisHighlight,
	getAxisLabels,
} from "../utils";
// COMPONENTS
import ReportsAlertDownloadModal from "../ReportsAlertDownloadModal";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ChartBarTimeLineByDay({
	data,
	isLoading,
	bottomLabel,
	leftLabel,
}: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const chartRef = useRef(null);
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [highlightedItem, setHighLightedItem] =
		useState<HighlightItemData | null>(null);
	const [labels, setLabels] = useState<string[]>([]);
	const [seriesDataStack, setSeriesDataStack] = useState<any>([]);
	const [localSelectedCarList, setLocalCarList] = useState<any>([]);
	const [chartLabel, setChartLabel] = useState("");
	const [chartHeight, setChartHeight] = useState(100);
	// eslint-disable-next-line no-unused-vars
	const [downloadReport, setDownloadReport] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters, openPdfViewer, chartReportTheme } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	// eslint-disable-next-line no-undef
	const getCharNoDataMessage = () => {
		if (filters.carsList.length === 0) {
			return "general.noData";
		}
		if (localSelectedCarList.length === 0) {
			return "general.selectSomeCar";
		}
		if (data && data.length === 0) {
			return "general.monthHasNoData";
		}
		return "general.noData";
	};

	const arraysAreEqual = (arr1: any, arr2: any) => {
		if (arr1.length !== arr2.length) {
			return false;
		}

		return arr1.every((value: any, index: any) => value === arr2[index]);
	};

	const captureChartAsImage = () => {
		if (chartRef.current) {
			html2canvas(chartRef.current).then((canvas) => {
				const imgData: any = canvas.toDataURL("image/png");
				const copy: ReportSliceFiltersInterface = structuredClone(filters);
				copy.urlChartImage = imgData;
				dispatch(updateFilters(copy));
			});
		}
	};

	const buildCustomLabels = (stackData: any) => {
		const newData: any = [];
		stackData.map((item: any) => {
			newData.push({
				color: item.color,
				data: item.data,
				highlightScope: item.highlightScope,
				stack: item.stack,
				label: item.label.replace(/\d/g, ""),
			});
			return true;
		});
		return newData;
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (localSelectedCarList && localSelectedCarList.length > 0 && data) {
			const responseStackData = generateBarsDataFault(
				data,
				true,
				localSelectedCarList,
				filters.chartMirror
			);
			const responseCustomLabels = buildCustomLabels(responseStackData);
			setSeriesDataStack(responseCustomLabels);
			const responseLabels = getAxisLabels(data);
			setChartHeight(
				responseLabels.length > 0 ? responseLabels.length * 100 : 200
			);
			setLabels(responseLabels);
			setChartLabel(
				`${moment(filters.startDate).format("MMMM Do YYYY")} / ${moment(
					filters.endDate
				).format("MMMM Do YYYY")}`
			);
		} else {
			setSeriesDataStack([]);
			setChartLabel("");
			setLabels(getAxisLabels(data));
		}
	}, [localSelectedCarList, data, filters.chartMirror]);

	useEffect(() => {
		if (!arraysAreEqual(filters.selectedCarsList, localSelectedCarList)) {
			setLocalCarList(filters.selectedCarsList);
		}
	}, [filters.selectedCarsList]);

	useEffect(() => {
		if (openPdfViewer) {
			captureChartAsImage();
		}
	}, [openPdfViewer]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={openPdfViewer || isLoading}>
			<div style={{ opacity: openPdfViewer ? 0 : 1 }}>
				<Box sx={{ width: "100%", padding: "10px" }} ref={chartRef}>
					<div
						className='generalStyles__width100 generalStyles__flexCenter'
						style={{
							fontSize: "20px",
							fontWeight: "bolder",
							backgroundColor: `${getChartBackgroundColor(chartReportTheme)}`,
							color: `${getChartTextColor(chartReportTheme)}`,
							paddingTop: "20px",
							paddingBottom: "5px",
						}}
					>
						<div className='generalStyles__flex generalStyles__mrFix25'>
							<div
								style={{
									backgroundColor: "#1bed18",
									height: "20px",
									width: "20px",
									marginTop: "3px",
									marginRight: "10px",
								}}
							/>
							<div>
								<span>Normal</span>
							</div>
						</div>
						<div className='generalStyles__flex generalStyles__mrFix25'>
							<div
								style={{
									backgroundColor: "#0c04e2",
									height: "20px",
									width: "20px",
									marginTop: "3px",
									marginRight: "10px",
								}}
							/>
							<div>
								<span>Inspection</span>
							</div>
						</div>
						<div className='generalStyles__flex generalStyles__mrFix25'>
							<div
								style={{
									backgroundColor: "#8cf8f6",
									height: "20px",
									width: "20px",
									marginTop: "3px",
									marginRight: "10px",
								}}
							/>
							<div>
								<span>Mechanic on Site</span>
							</div>
						</div>
						<div className='generalStyles__flex generalStyles__mrFix25'>
							<div
								style={{
									backgroundColor: "#e50509",
									height: "20px",
									width: "20px",
									marginTop: "3px",
									marginRight: "10px",
								}}
							/>
							<div>
								<span>Out of Service</span>
							</div>
						</div>
						<div className='generalStyles__flex generalStyles__mrFix25'>
							<div
								style={{
									backgroundColor: "#1e1e1e",
									height: "20px",
									width: "20px",
									marginTop: "3px",
									marginRight: "10px",
								}}
							/>
							<div>
								<span>Test Mode</span>
							</div>
						</div>
						<div className='generalStyles__flex generalStyles__mrFix25'>
							<div
								style={{
									backgroundColor: "#eff635",
									height: "20px",
									width: "20px",
									marginTop: "3px",
									marginRight: "10px",
								}}
							/>
							<div>
								<span>Fire Phase 1</span>
							</div>
						</div>
						<div className='generalStyles__flex generalStyles__mrFix25'>
							<div
								style={{
									backgroundColor: "orange",
									height: "20px",
									width: "20px",
									marginTop: "3px",
									marginRight: "10px",
								}}
							/>
							<div>
								<span>Fire Phase 2</span>
							</div>
						</div>
						<div className='generalStyles__flex'>
							<div
								style={{
									backgroundColor: "#ef0cef",
									height: "20px",
									width: "20px",
									marginTop: "3px",
									marginRight: "10px",
								}}
							/>
							<div>
								<span>Independent Service</span>
							</div>
						</div>
					</div>
					<div className='generalStyles__flex'>
						<div
							style={{
								fontSize: "20px",
								fontWeight: "bolder",
								backgroundColor: `${getChartBackgroundColor(chartReportTheme)}`,
								textAlign: "center",
								color: `${getChartTextColor(chartReportTheme)}`,
							}}
							className='generalStyles__flexCenter'
						>
							<span style={{ transform: "rotate(270deg)" }}>
								{filters.chartType === "bar_horizontal" ||
								filters.chartType === "bar_stack_horizontal"
									? bottomLabel
									: leftLabel}
							</span>
						</div>
						<BarChart
							axisHighlight={{ x: "line", y: "line" }}
							slotProps={{
								legend: { hidden: true },
								// Custom message for empty chart
								noDataOverlay: { message: t(`${getCharNoDataMessage()}`) },
							}}
							sx={{
								[`.${axisClasses.root}`]: {
									[`.${axisClasses.tick}, .${axisClasses.line}`]: {
										stroke: `${getChartTicklineColor(chartReportTheme)}`,
										strokeWidth: 3,
									},
									[`.${axisClasses.tickLabel}`]: {
										fill: `${getChartTextColor(chartReportTheme)}`,
									},
								},
								[`.MuiBarLabel-root	`]: {
									stroke: "white",
								},
								[`.MuiChartsAxisHighlight-root `]: {
									stroke: `${getChartAxisHighlight(chartReportTheme)}`,
								},
								[`.css-1f57y8b `]: {
									fill: `${getChartTextColor(chartReportTheme)}`,
								},
								backgroundColor: `${getChartBackgroundColor(chartReportTheme)}`,
							}}
							// eslint-disable-next-line react/jsx-props-no-spreading
							yAxis={[
								{
									data: labels,
									scaleType: "band",
									tickLabelStyle: {
										fontSize: 12,
									},
									tickLabelPlacement: "middle",
									tickPlacement: "middle",
								},
							]}
							series={seriesDataStack}
							barLabel='value'
							height={chartHeight}
							tooltip={{ trigger: "item" }}
							highlightedItem={highlightedItem}
							onHighlightChange={setHighLightedItem}
							layout='horizontal'
						/>
					</div>
					<div
						className='generalStyles__width100 generalStyles__textCenter'
						style={{
							fontSize: "20px",
							fontWeight: "bolder",
							backgroundColor: `${getChartBackgroundColor(chartReportTheme)}`,
							color: `${getChartTextColor(chartReportTheme)}`,
							paddingBottom: "10px",
							marginTop: "-5px",
						}}
					>
						<span>
							{filters.chartType === "bar_horizontal" ||
							filters.chartType === "bar_stack_horizontal"
								? leftLabel
								: bottomLabel}
						</span>
						<br />
						<span className='generalStyles__info'>{chartLabel}</span>
					</div>
				</Box>
			</div>
			{/** ******************************************* */}
			{/** DOWNLOAD REPORT */}
			<ReportsAlertDownloadModal setDownloadReport={setDownloadReport} />
		</Spin>
	);
}

export default ChartBarTimeLineByDay;
