import React from "react";
// ANT DESIGN COMPONENTS
import { Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// INTEFACES
import { ButtonCloseInterface } from "../../../interfaces/Components.interface";

function ButtonClose({ onClick }: ButtonCloseInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Button
			className='buttonStyle__11 generalStyles__width100'
			onClick={() => {
				onClick();
			}}
		>
			{t("general.close")}
		</Button>
	);
}

export default ButtonClose;
