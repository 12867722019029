import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useDeleteBankMutation } from "../../../redux/banks/bankAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import { updateOpenModalDeleteBank } from "../../../redux/banks/bankSlice";
// UTILS
import { styleDeleteButton } from "../../../utils/utils";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

library.add(faTrash, faTriangleExclamation);

function BanksDeleteModal() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [disableButtonConfirmDelete, setDisableButtonConfirmDelete] = useState(true);
	const [confirmBankName, setConfirmBankName] = useState("");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { bank, openModalDeleteBank } = useSelector((state: any) => state.bank);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerDeleteBank, { isLoading, isSuccess, error, isError, reset: resetDeleteBank }] = useDeleteBankMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOk = () => {
		dispatch(updateOpenModalDeleteBank(false));
	};

	const handleCancel = () => {
		dispatch(updateOpenModalDeleteBank(false));
	};

	const handleCloseModal = () => {
		dispatch(updateOpenModalDeleteBank(false));
	};

	const handleDeleteItem = async () => {
		const token = await GETJwtToken();
		triggerDeleteBank({ id: bank.id, token });
	};

	const handleSubmitDeleteItem = async (event: any) => {
		if (event.key === "Enter" && !disableButtonConfirmDelete) {
			const token = await GETJwtToken();
			triggerDeleteBank({ id: bank.id, token });
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openModalDeleteBank) {
			setDisableButtonConfirmDelete(true);
			setConfirmBankName("");
			resetDeleteBank();
		}
	}, [openModalDeleteBank]);

	useEffect(() => {
		setDisableButtonConfirmDelete(true);
		if (confirmBankName && bank && bank.name && confirmBankName.toLowerCase() === bank.name.toLowerCase()) {
			setDisableButtonConfirmDelete(false);
		} else {
			setDisableButtonConfirmDelete(true);
		}
	}, [confirmBankName]);

	useEffect(() => {
		dispatch(updateOpenModalDeleteBank(false));
	}, [isSuccess, isError]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal onOk={handleOk} closable={false} onCancel={handleCancel} open={openModalDeleteBank} footer={null}>
			<Spin spinning={isLoading}>
				<div>
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faTriangleExclamation}
							className='generalStyles__warning generalStyles__deleteItemIcon'
						/>
						<p className='mt-3 drawer__text'>
							{t("organizationManagement.deleteDescription.text1")}
							<b className='generalStyles__warning'>{t("organizationManagement.deleteDescription.text2")} </b>
							{t("edgeManagement.banks.theBank")}{" "}
							<b className='generalStyles__warning'>{bank && bank.name ? bank.name : ""} </b>
							{t("edgeManagement.banks.confirmBankName")}
						</p>
						<Input
							placeholder={t("edgeManagement.banks.bankName")}
							onChange={(e) => {
								setConfirmBankName(e.target.value);
							}}
							value={confirmBankName}
							onKeyDown={handleSubmitDeleteItem}
							status='warning'
						/>
					</div>
					<div className='generalStyles__flexEnd mt-4'>
						<div>
							<Button
								htmlType='submit'
								className={styleDeleteButton(disableButtonConfirmDelete, theme)}
								onClick={handleDeleteItem}
								disabled={disableButtonConfirmDelete}
							>
								{t("organizationManagement.delete")}
							</Button>
							<Button onClick={handleCloseModal} className='buttonStyle__4'>
								{t("organizationManagement.close")}
							</Button>
						</div>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2 isError={isError} isSuccess={isSuccess} requestType='DELETE' error={error} name='Bank' />
		</Modal>
	);
}

export default BanksDeleteModal;
