import React from "react";
// COMPONENTS
import MonitoringLiftStatusView from "./MonitoringLiftStatusView";
// INTERFACES
import {
  LiftInterface,
  MonitoringStatusViewInterface,
} from "../../../interfaces/EdgeManagement.interface";

function MonitoringStatusView({
  socketMessage,
  layout,
}: MonitoringStatusViewInterface) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div style={{ padding: "10px" }}>
      {layout && layout.lifts && (
        <div>
          <div className={` generalStyles__flexCenter`}>
            {layout.lifts.map((lift: LiftInterface) => (
              <div>
                <MonitoringLiftStatusView
                  key={lift.id}
                  lift={lift}
                  layout={layout}
                  socketMessage={socketMessage}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default MonitoringStatusView;
