const config = {
  // Backend config
  s3: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    BUCKET: process.env.REACT_APP_COGNITO_BUCKET,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    URL: process.env.REACT_APP_COGNITO_API_URL,
  },
  cognito: {
    REGION: process.env.REACT_APP_COGNITO_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    OAUTH_DOMAIN: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
    REDIRECT_SIGN_IN: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
    REDIRECT_SIGN_OUT: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,
  },
}

export default config
