import React from "react";
import { DownOutlined } from "@ant-design/icons";
// eslint-disable-next-line no-unused-vars
import type { MenuProps } from "antd";
import { Badge, Dropdown, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetMapViewQuery } from "../../../redux/mapView/mapViewAPI";
import GETJwtToken from "../../../redux/authentication/authentication";
import { updateMapViewItem } from "../../../redux/mapView/mapViewSlice";
import { RootState } from "../../../app/store";

function MonitoringMapViewList({ faults }: { faults: any[] }) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [
    triggerGetMapView,
    { data: dataGetMapView, isLoading: isLoadingGetMapView },
  ] = useLazyGetMapViewQuery();

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { selectedOrganization, mapViewItem } = useSelector(
    (state: any) => state.mapView
  );
  const { theme } = useSelector((state: RootState) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const getInitialData = async () => {
    if (!selectedOrganization) {
      return;
    }
    const token = await GETJwtToken();
    const BODY: any = {
      org_id: selectedOrganization,
    };
    triggerGetMapView({
      token,
      body: BODY,
    });
  };

  const itemCounter = (id: string) => {
    let count = 0;
    dataGetMapView?.data.forEach((item: any) => {
      if (item.id === id) {
        item?.setup?.pins?.forEach((pin: any) => {
          faults?.forEach((fault: any) => {
            if (fault.lift_id === pin.id) {
              count += 1;
            }
          });
        });
      }
    });
    return count;
  };

  // ************************************************ */
  // USE EFFECT ************************************* */
  React.useEffect(() => {
    getInitialData();
  }, [selectedOrganization]);

  // ************************************************ */
  // COMPONENT ************************************** */
  const items: MenuProps["items"] = dataGetMapView?.data.map((item: any) => {
    const count = itemCounter(item.id);
    return {
      key: item.id,
      label: (
        <>
          {item.name} {count > 0 && <Badge count={count} />}
        </>
      ),
      onClick: () => {
        dispatch(updateMapViewItem(item));
      },
    };
  });

  if (isLoadingGetMapView) return <div>Loading...</div>;

  return (
    <Dropdown menu={{ items }}>
      <Space
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        <span
          className={
            faults?.length > 0 ? `mapViewStyles__title__error__${theme}` : ""
          }
          style={{ display: "flex", gap: 8 }}
        >
          {mapViewItem?.name || "Select a map"}
          <DownOutlined />
        </span>
      </Space>
    </Dropdown>
  );
}

export default MonitoringMapViewList;
