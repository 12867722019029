import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenFormDataBlock } from "../../../redux/tools/toolsSlice";
import {
	usePostDataBlockTypesMutation,
	usePutDataBlockTypesMutation,
} from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

function DataBlocksForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [name, setName] = useState<any>("");
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { dataBlock, openFormDataBlock, openFormDataBlockAction } = useSelector((state: any) => state.tool);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		postDataBlock,
		{ isSuccess: isSuccessPost, isLoading: isLoadingPost, isError: isErrorPost, error: errorPost, reset: resetPost },
	] = usePostDataBlockTypesMutation();
	const [
		putDataBlock,
		{ isSuccess: isSuccessPut, isLoading: isLoadingPut, isError: isErrorPut, error: errorPut, reset: resetPut },
	] = usePutDataBlockTypesMutation();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onFill = () => {
		setName(dataBlock.name);
	};

	const onClickClose = () => {
		dispatch(updateOpenFormDataBlock(false));
	};

	const onChangeName = (newValue: string) => {
		setName(newValue);
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		if (openFormDataBlockAction === "create") {
			const BODY = {
				token,
				body: {
					name,
				},
			};
			postDataBlock(BODY);
		} else if (openFormDataBlockAction === "update") {
			const BODY = {
				token,
				id: dataBlock.id,
				body: {
					name,
				},
			};
			putDataBlock(BODY);
		}
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		if (openFormDataBlock) {
			resetPost();
			resetPut();
			if (openFormDataBlockAction === "update") {
				onFill();
			} else {
				setName("");
			}
		}
	}, [openFormDataBlock]);

	useEffect(() => {
		onClickClose();
	}, [isSuccessPost, isErrorPost, isSuccessPut, isErrorPut]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width={isMobile ? "95%" : "35%"}
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openFormDataBlock}
		>
			<Spin spinning={isLoadingPost || isLoadingPut} tip={t("general.loading")}>
				{/** ************************************************* */}
				{/** DATA BLOCK FORM */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon icon={faGear} className='generalStyles__info generalStyles__mrFix' />
							{openFormDataBlockAction === "create"
								? t("configuration.dataBlock.createNewDataBlock")
								: t("configuration.dataBlock.updateDataBlock")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme}`}>
					{/** NAME */}
					<div className='col-md-12'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span className='drawer__text'>{t("general.name")}</span>
							</div>
							<div className='generalStyles__width100'>
								<Input
									size='small'
									value={name}
									onChange={(e) => {
										onChangeName(e.currentTarget.value);
									}}
								/>
							</div>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
				</div>
				<br />
				{/** ************************************************* */}
				{/** CLOSE AND SUBMIT BUTTONS */}
				<div className='mt-4 generalStyles__width100'>
					<div>
						<div>
							<Button onClick={onClickClose} className='buttonStyle__3'>
								{t("organizationManagement.close")}
							</Button>
							<Button
								onClick={onClickSave}
								className='buttonStyle__3'
								type='primary'
								disabled={name === "" || name === undefined}
							>
								{t("general.save")}
							</Button>
						</div>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPost}
				isSuccess={isSuccessPost}
				requestType='POST'
				error={errorPost}
				name='Data Block'
			/>
			<GlobalAlert2
				isError={isErrorPut}
				isSuccess={isSuccessPut}
				requestType='PUT'
				error={errorPut}
				name='Data Block'
			/>
		</Drawer>
	);
}

export default DataBlocksForm;
