import React from "react";
// ANT DESIGN COMPONENTS
import { Button } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
// COMPONENTS
import CustomAmplifyCarousel from "../login/CustomAmplifyCarousel";
import EulaText from "./EulaText";

function EulaPage() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='loginStyles__body'>
			{/** ************************************************** */}
			<div className='loginStyles__loginSubBox1 d-none d-sm-block'>
				<CustomAmplifyCarousel />
			</div>
			<div className='loginStyles__loginSubBox2'>
				<div
					style={{
						textAlign: "justify",
						overflowY: "scroll",
						height: "100%",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "flex-end",
							justifyContent: "flex-end",
							padding: "30px",
						}}
					>
						<div>
							<Button
								style={{
									backgroundColor: "#969696",
									color: "white",
								}}
								shape='circle'
								icon={<FontAwesomeIcon icon={faX} />}
								onClick={() => {
									window.location.href = "/login";
								}}
							/>
						</div>
					</div>
					<div style={{ padding: "0px 120px 50px 120px" }}>
						<EulaText />
						<br />
						<div>
							<Button
								onClick={() => {
									window.location.href = "/login";
								}}
								className='buttonStyle__6'
							>
								Back To Login
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EulaPage;
