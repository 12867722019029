/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Table, Alert, Menu, Dropdown, Space, Empty, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faTrash, faPenToSquare, faBan, faToggleOn, faSearch } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetLiftsQuery, useLazyGetLiftsByBankQuery } from "../../../redux/lifts/liftAPI";
import {
	updateOpenModalDeleteLift,
	updateOpenModalEditLift,
	updateOpenDrawerLift,
	updateLift,
	updateLiftTableParams,
	updateOpenModalActivateDeactivate,
} from "../../../redux/lifts/liftSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// FILTERS
import FilterSearchText from "../../components/filters/FilterSearchText";
import FilterSelectLiftType from "../../components/filters/FilterSelectLiftType";
// UTILS
import { getTableRowClass, buildFilterString } from "../../../utils/utils";

const LiftsTable = ({ renderComponent }: any) => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [lifts, setLifts] = useState<any[]>([]);
	const [selectedItem, setSelectedItem] = useState<any>({});
	const [idLiftSelected, setIdLiftSelected] = useState("");
	const [filterName, setFilterName] = useState("");
	const [filterLiftType, setFilterLiftType] = useState("");
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { userLogged } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetLiftsTable, { data, isLoading, isError, isFetching }] = useLazyGetLiftsQuery();
	const [
		triggerGetLiftsByBankTable,
		{
			data: dataGetLiftsByBankTable,
			isLoading: isLoadingGetLiftsByBankTable,
			isError: isErrorGetLiftsByBankTable,
			isFetching: isFetchingGetLiftsByBankTable,
		},
	] = useLazyGetLiftsByBankQuery();

	const { liftTableParams, liftRefreshTable } = useSelector((state: any) => state.lift);
	const { idBankSelected, clearFilters } = useSelector((state: any) => state.bank);
	// ************************************************ */
	// TABLE ACTIONS ************************************ */

	const { Column } = Table;

	const manageActions = () => {
		const BLANK = {
			label: (
				<div className='generalStyles__tableActionBox'>
					<span>
						<FontAwesomeIcon className='generalStyles__error generalStyles__mrFix' icon={faBan} />
						{t("general.noActions")}
					</span>
				</div>
			),
			key: "0",
		};

		const EDIT = validatePermission("lifts-update", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalEdit();
							}}
							onKeyDown={() => {
								handleOpenModalEdit();
							}}
						>
							<span>
								<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faPenToSquare} />
								{t("organizationManagement.edit")}
							</span>
						</div>
					),
					key: "2",
			  }
			: null;

		const DELETE = validatePermission("lifts-delete", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalDelete();
							}}
							onKeyDown={() => {
								handleOpenModalDelete();
							}}
						>
							<span>
								<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faTrash} />
								{t("organizationManagement.delete")}
							</span>
						</div>
					),
					key: "3",
			  }
			: null;

		const ACTIVATE_DEACTIVATE = validatePermission("lifts-update", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalActivateDeactivate();
							}}
							onKeyDown={() => {
								handleOpenModalActivateDeactivate();
							}}
						>
							<span>
								<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faToggleOn} />
								{t("general.activate")}
							</span>
						</div>
					),
					key: "4",
			  }
			: null;

		// RETURN OBJECT WITH VALID ACTIONS
		if (EDIT || DELETE || ACTIVATE_DEACTIVATE) {
			return [EDIT, DELETE, ACTIVATE_DEACTIVATE];
		}
		return [BLANK];
	};

	const menu = <Menu items={manageActions()} />;

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const handleOpenModalEdit = () => {
		dispatch(updateLift(selectedItem));
		dispatch(updateOpenModalEditLift(true));
	};

	const handleOpenModalDelete = () => {
		dispatch(updateLift(selectedItem));
		dispatch(updateOpenModalDeleteLift(true));
	};

	const handleOpenModalActivateDeactivate = () => {
		dispatch(updateLift(selectedItem));
		dispatch(updateOpenModalActivateDeactivate(true));
	};

	const onCellClick = (record: any) => {
		if (record) {
			dispatch(updateLift(record));
			dispatch(updateOpenDrawerLift(true));
		}
	};

	const handleTableChange = async (pagination: any, filters: any, sorter: any) => {
		dispatch(
			updateLiftTableParams({
				pagination,
				filters,
				...sorter,
			})
		);
		//
		const token = await GETJwtToken();
		triggerGetLiftsTable({
			page: pagination.current,
			limit: pagination.pageSize,
			token,
			orgId: idLiftSelected,
		});
	};

	const refreshTable = (orgId: string) => {
		const searchAndFilterString = buildFilterString({
			filterName,
			filterLiftType,
		});
		getData(orgId, searchAndFilterString);
	};

	const getData = async (orgId: string, searchAndFilterString: string) => {
		dispatch(
			updateLiftTableParams({
				pagination: {
					current: 1,
					pageSize: 10,
					total: liftTableParams.pagination.total,
				},
				filters: {},
				sorter: {},
			})
		);
		//
		const token = await GETJwtToken();

		if (renderComponent === "bankPage") {
			await triggerGetLiftsByBankTable({
				page: 1,
				limit: 10,
				token,
				bankId: idBankSelected,
				searchAndFilterString,
			}).unwrap();
		} else {
			await triggerGetLiftsTable({
				page: 1,
				limit: 10,
				token,
				orgId,
				searchAndFilterString,
			}).unwrap();
		}
	};

	const getInitialData = async () => {
		const token = await GETJwtToken();
		if (renderComponent === "bankPage") {
			if (idBankSelected && idBankSelected !== "") {
				triggerGetLiftsByBankTable({
					page: liftTableParams.pagination.current,
					limit: liftTableParams.pagination.pageSize,
					token,
					bankId: idBankSelected,
				});
			}
		} else {
			triggerGetLiftsTable({
				page: liftTableParams.pagination.current,
				limit: liftTableParams.pagination.pageSize,
				token,
			});
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (userLogged && userLogged.org) {
			setIdLiftSelected(userLogged.org.id);
			getInitialData();
		}
	}, []);

	useEffect(() => {
		const newData = structuredClone(data || dataGetLiftsByBankTable);
		if (newData && newData.data) {
			dispatch(
				updateLiftTableParams({
					...liftTableParams,
					pagination: {
						...liftTableParams.pagination,
						total: newData.total,
					},
				})
			);
			setLifts(newData.data);
		}
	}, [data, dataGetLiftsByBankTable]);

	useEffect(() => {
		if (isError || isErrorGetLiftsByBankTable) {
			setLifts([]);
		}
	}, [isError, isErrorGetLiftsByBankTable]);

	useEffect(() => {
		if (liftRefreshTable) {
			refreshTable(idLiftSelected);
		}
	}, [liftRefreshTable]);

	useEffect(() => {
		if (clearFilters) {
			setFilterName("");
			setFilterLiftType("");
			getData(idLiftSelected, "");
		}
	}, [clearFilters]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='mt-3'>
			{validatePermission("lifts-view", permissions) ? (
				<>
					{!isError || !isErrorGetLiftsByBankTable ? (
						<Table
							rowClassName={(record, index) => getTableRowClass(index, theme)}
							className='customTable'
							dataSource={lifts}
							loading={isLoading || isFetching || isLoadingGetLiftsByBankTable || isFetchingGetLiftsByBankTable}
							rowKey={(record) => record.id}
							size='small'
							pagination={liftTableParams.pagination}
							onChange={handleTableChange}
							scroll={{ x: 900 }}
						>
							{/** NAME -- */}
							<Column
								onCell={(record) => ({
									onClick: () => {
										onCellClick(record);
									},
								})}
								title={t("general.name")}
								dataIndex='name'
								key='name'
								filterIcon={<FontAwesomeIcon icon={faSearch} />}
								filterDropdown={() => (
									<FilterSearchText
										placeHolder={t("general.name")}
										filterValue={filterName}
										setFilterValue={setFilterName}
										refreshTable={() => {
											refreshTable(idLiftSelected);
										}}
										refreshRequireParamenters
									/>
								)}
								render={(text) => (
									<>
										<div>{text}</div>
									</>
								)}
							/>
							{/** TYPE -- */}
							<Column
								onCell={(record) => ({
									onClick: () => {
										onCellClick(record);
									},
								})}
								title={t("edgeManagement.lifts.type")}
								dataIndex='type'
								key='type'
								filterIcon={<FontAwesomeIcon icon={faSearch} />}
								filterDropdown={() => (
									<FilterSelectLiftType
										placeHolder={t("general.liftType")}
										setFilterValue={setFilterLiftType}
										filterValue={filterLiftType}
										refreshTable={() => {
											refreshTable(idLiftSelected);
										}}
									/>
								)}
								render={(text) => (
									<>
										<div>
											{text === "ELEVATOR" && (
												<Tag color='blue' className='drawer__typeTag'>
													{text}
												</Tag>
											)}
											{text === "ESCALATOR" && (
												<Tag color='orange' className='drawer__typeTag'>
													{text}
												</Tag>
											)}
											{text === "MOVINGWALK" && (
												<Tag color='green' className='drawer__typeTag'>
													{text}
												</Tag>
											)}
											{text === "BOT" && (
												<Tag color='magenta' className='drawer__typeTag'>
													{text}
												</Tag>
											)}
										</div>
									</>
								)}
							/>
							{/** DIRECTION -- */}
							<Column
								onCell={(record) => ({
									onClick: () => {
										onCellClick(record);
									},
								})}
								title={t("edgeManagement.lifts.direction")}
								dataIndex='direction'
								key='direction'
								render={(text) => (
									<>
										<div>
											{(text === "UP" || text === "FORWARD") && (
												<Tag color='green' className='drawer__typeTag'>
													{text}
												</Tag>
											)}
											{(text === "DOWN" || text === "BACKWARD") && (
												<Tag color='red' className='drawer__typeTag'>
													{text}
												</Tag>
											)}
											{text === "N/D" && (
												<Tag color='blue' className='drawer__typeTag'>
													N/D
												</Tag>
											)}{" "}
										</div>
									</>
								)}
							/>
							{/** BANK NAME -- */}
							<Column
								onCell={(record) => ({
									onClick: () => {
										onCellClick(record);
									},
								})}
								title={t("edgeManagement.banks.bankName")}
								dataIndex='bankName'
								key='bankName'
								render={(text, record: any) => (
									<>
										<div>{record && record.bank && record.bank.name ? record.bank.name : "--"}</div>
									</>
								)}
							/>
							{/** ACTIONS -- */}
							<Column
								title={t("edgeManagement.lifts.actions")}
								key='action'
								render={(_: any, record: any) => (
									<Dropdown overlay={menu} trigger={["click"]}>
										<Space
											className='generalStyles__hoverCursor'
											onClick={() => {
												setSelectedItem(record);
											}}
										>
											<FontAwesomeIcon icon={faEllipsis} />
										</Space>
									</Dropdown>
								)}
							/>
						</Table>
					) : (
						<Alert
							message={t("organizationManagement.errorDescription.error")}
							description={t("organizationManagement.errorDescription.text1")}
							type='error'
							showIcon
							className='mt-3'
						/>
					)}
				</>
			) : (
				<>
					<div className='generalStyles__noAccessToListTable'>
						<Empty description={t("edgeManagement.lifts.listNotAvailable")} image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</div>
				</>
			)}
		</div>
	);
};

export default LiftsTable;
