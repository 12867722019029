import React from "react";
// ANT DESIGN COMPONENTS
import { Divider, Alert, Tag } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faElevator } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";

function DrawerLiftsAssigned({ data }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: any) => state.home);
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<br />
			{/** ************************************************* */}
			{/** LIFTS ASSIGNED  */}
			<div>
				<Divider orientation='left' className='generalStyles__drawerDivider'>
					<h5>
						<FontAwesomeIcon icon={faElevator} className='generalStyles__info generalStyles__mrFix' />
						{t("edgeManagement.lifts.liftsAssigned")}
					</h5>
				</Divider>
			</div>
			<div>
				{data && data.length > 0 ? (
					<>
						{data.map((lift: any, index: number) => (
							<div key={index}>
								<div className={`drawer__box__${theme} mt-3`}>
									{/* ID */}
									<div className='generalStyles__flex'>
										<div>
											<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>ID:</p>
										</div>
										<div className='drawer__textContainer'>
											<p className='drawer__text'>{lift && lift.id ? lift.id : "--"}</p>
										</div>
									</div>
									{/* NAME */}
									<div className='generalStyles__flex'>
										<div>
											<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("general.name")}</p>
										</div>
										<div className='drawer__textContainer'>
											<p className='drawer__text'>{lift && lift.name ? lift.name : "--"}</p>
										</div>
									</div>
									{/* POSITION */}
									<div className='generalStyles__flex'>
										<div>
											<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
												{t("edgeManagement.lifts.position")}
											</p>
										</div>
										<div className='drawer__textContainer'>
											<p className='drawer__text'>{lift && lift.position ? lift.position : "--"}</p>
										</div>
									</div>
									{/* FLOORS */}
									<div className='generalStyles__flex'>
										<div>
											<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
												{t("configuration.configurationFiles.floors")}
											</p>
										</div>
										<div className='drawer__textContainer'>
											<p className='drawer__text'>{lift && lift.lift_floors ? lift.lift_floors.length : "--"}</p>
										</div>
									</div>
									{/* TYPE */}
									<div className='generalStyles__flex'>
										<div>
											<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
												{t("edgeManagement.lifts.type")}
											</p>
										</div>
										<div className='drawer__textContainer'>
											{lift && lift.type ? (
												<>
													<div className=''>
														{lift.type === "ELEVATOR" && (
															<Tag color='blue' className='drawer__typeTag'>
																{lift.type}
															</Tag>
														)}
														{lift.type === "ESCALATOR" && (
															<Tag color='volcano' className='drawer__typeTag'>
																{lift.type}
															</Tag>
														)}
														{lift.type === "MOVINGWALK" && (
															<Tag color='green' className='drawer__typeTag'>
																{lift.type}
															</Tag>
														)}{" "}
													</div>
												</>
											) : (
												"--"
											)}
										</div>
									</div>
								</div>
							</div>
						))}
					</>
				) : (
					<div className={`drawer__box__${theme}`}>
						<Alert message={t("edgeManagement.lifts.noLiftsFound")} type='warning' showIcon />
					</div>
				)}
			</div>
		</div>
	);
}

export default DrawerLiftsAssigned;
