import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import RolesTable from "./RolesTable";
import RolesHeader from "./RolesHeader";
import RolesDrawer from "./RolesDrawer";
import RolesCreateRoleForm from "./RolesCreateRoleForm";
import RolesDeleteRoleModal from "./RolesDeleteRoleModal";
import RolesEditRoleModal from "./RolesEditRoleForm";
import RolesBlockUnlockRolesModal from "./RolesBlockUnlockRoleModal";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

const RolesPage = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("view-organization-management-roles", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<RolesHeader />
					<RolesTable />
					<RolesDrawer />
					<RolesCreateRoleForm />
					<RolesDeleteRoleModal />
					<RolesEditRoleModal />
					<RolesBlockUnlockRolesModal />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default RolesPage;
