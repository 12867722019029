/* eslint-disable no-use-before-define */
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Table, Alert, Menu, Dropdown, Space, Empty, Tag, Collapse } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faTrash, faPenToSquare, faBan, faCube, faDownload } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateBreadcrumbTitle } from "../../../redux/home/homeSlice";
import {
	useLazyGetConfigurationFilesQuery,
	useLazyGetAllOffsetsTypesQuery,
	useLazyGetOffsetsByDataBlockQuery,
} from "../../../redux/configurationFiles/configurationFilesAPI";
import {
	updateOpenModalDeleteConfigurationFile,
	updateOpenModalEditConfigurationFile,
	updateOpenDrawerConfigurationFile,
	updateConfigurationFile,
	updateConfigurationFileBreadcrumb,
	updateOffsetTypeName,
	updateOffsetTypeTagColor,
	updateOpenDrawerCreateDataBlock,
	updateOpenModalDownloadOffsetsConfigurationFile,
} from "../../../redux/configurationFiles/configurationFilesSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// UTILS
import { getOffsetsCountByConfiguration, getTableRowClass } from "../../../utils/utils";

const ConfigurationFilesExpandableRow = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const { Panel } = Collapse;
	const [configurationFiles, setConfigurationFiles] = useState<any[]>([]);
	const [selectedItem, setSelectedItem] = useState<any>({});
	const [idOrganizationSelected, setIdOrganizationSelected] = useState("");
	const [t] = useTranslation("global");
	const { permissions } = useSelector((state: any) => state.user);
	const { userLogged } = useSelector((state: any) => state.home);
	const { configurationFileRefreshTable } = useSelector((state: any) => state.configurationFile);
	const [offsetTypeList, setOffsetsTypeList] = useState<any>([]);
	const { theme } = useSelector((state: any) => state.home);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetConfigurationFilesTable, { data, isLoading, isError, isFetching }] =
		useLazyGetConfigurationFilesQuery();
	const [triggerGetOffsetsTypes, { data: liftOffsetsType, isLoading: isLoadingGetOffsetTypes }] =
		useLazyGetAllOffsetsTypesQuery();

	const [triggerGetDataBlock, { isLoading: isLoadingDataBlock, isFetching: isFetchingDataBlock }] =
		useLazyGetOffsetsByDataBlockQuery();
	// ************************************************ */
	// TABLE ACTIONS ************************************ */

	const { Column } = Table;

	const manageActions = () => {
		const BLANK = {
			label: (
				<div className='generalStyles__tableActionBox'>
					<span>
						<FontAwesomeIcon className='generalStyles__error generalStyles__mrFix' icon={faBan} />
						{t("general.noActions")}
					</span>
				</div>
			),
			key: "0",
		};

		const EDIT = validatePermission("configurations-update", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalEdit();
							}}
							onKeyDown={() => {
								handleOpenModalEdit();
							}}
						>
							<span>
								<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faPenToSquare} />
								{t("organizationManagement.edit")}
							</span>
						</div>
					),
					key: "1",
			  }
			: null;

		const CREATE_DATA_BLOCK = validatePermission("datablocks-create", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenDrawerCreateDataBlock();
							}}
							onKeyDown={() => {
								handleOpenDrawerCreateDataBlock();
							}}
						>
							<span>
								<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faCube} />
								{t("configuration.dataBlock.newDataBlock")}
							</span>
						</div>
					),
					key: "2",
			  }
			: null;

		const DOWNLOAD_CONFIGURATION_FILE = validatePermission("configurations-view", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalDownloadOffsetsConfigurationfile();
							}}
							onKeyDown={() => {
								handleOpenModalDownloadOffsetsConfigurationfile();
							}}
						>
							<span>
								<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faDownload} />
								{t("configuration.dataBlock.downloadConfiguration")}
							</span>
						</div>
					),
					key: "3",
			  }
			: null;

		const DELETE = validatePermission("configurations-delete", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalDelete();
							}}
							onKeyDown={() => {
								handleOpenModalDelete();
							}}
						>
							<span>
								<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faTrash} />
								{t("organizationManagement.delete")}
							</span>
						</div>
					),
					key: "5",
			  }
			: null;

		// RETURN OBJECT WITH VALID ACTIONS
		if (EDIT || CREATE_DATA_BLOCK || DOWNLOAD_CONFIGURATION_FILE || DELETE) {
			return [EDIT, CREATE_DATA_BLOCK, DOWNLOAD_CONFIGURATION_FILE, DELETE];
		}
		return [BLANK];
	};

	const menu = <Menu items={manageActions()} />;

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const handleOpenModalEdit = () => {
		dispatch(updateConfigurationFile(selectedItem));
		dispatch(updateOpenModalEditConfigurationFile(true));
	};

	const handleOpenDrawerCreateDataBlock = () => {
		dispatch(updateConfigurationFile(selectedItem));
		dispatch(updateOpenDrawerCreateDataBlock(true));
	};

	const handleOpenModalDownloadOffsetsConfigurationfile = () => {
		dispatch(updateConfigurationFile(selectedItem));
		dispatch(updateOpenModalDownloadOffsetsConfigurationFile(true));
	};

	const handleOpenModalDelete = () => {
		dispatch(updateConfigurationFile(selectedItem));
		dispatch(updateOpenModalDeleteConfigurationFile(true));
	};

	const handleGoToOffsetsTable = (selectedItem_: any) => {
		dispatch(updateConfigurationFile(selectedItem_));
		dispatch(updateConfigurationFileBreadcrumb(["Offsets"]));
		localStorage.setItem("headerTitle", "offsets");
		dispatch(updateBreadcrumbTitle("offsets"));
	};

	const handleGoToDefinitions = (record: any, offsetType: string, offsetTypeTagColor: string, title: string) => {
		dispatch(updateConfigurationFile(record));
		dispatch(updateOffsetTypeName(offsetType));
		dispatch(updateOffsetTypeTagColor(offsetTypeTagColor));
		dispatch(updateConfigurationFileBreadcrumb([selectedItem.name, `${offsetType} - Definitions`]));
		localStorage.setItem("headerTitle", title);
		dispatch(updateBreadcrumbTitle(title));
	};

	const onCellClick = (record: any) => {
		if (record) {
			dispatch(updateConfigurationFile(record));
			dispatch(updateOpenDrawerConfigurationFile(true));
		}
	};

	const refreshTable = async (orgId: string) => {
		//
		const token = await GETJwtToken();
		await triggerGetConfigurationFilesTable({
			page: 1,
			limit: 10,
			token,
			orgId,
		}).unwrap();
	};

	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetConfigurationFilesTable({
			page: 1,
			limit: 9999,
			token,
		});
	};

	const getOffsetsList = async () => {
		const token = await GETJwtToken();
		triggerGetOffsetsTypes({ token });
	};

	const buildConfigurationFilesData = (data_: any) => {
		const newArr: any = [];
		data_.map((item: any) => {
			const copy = structuredClone(item);
			if (copy && copy.data_blocks && copy.data_blocks.length > 0) {
				copy.data_blocks.map((item2: any, index: number) => {
					const copy2 = structuredClone(copy);
					copy2.rowSpan = index === 0 ? copy.data_blocks.length : 0;
					copy2.data_block = item2;
					newArr.push(copy2);
					return true;
				});
			} else {
				copy.data_block = [];
				copy.rowSpan = 1;
				newArr.push(copy);
			}
			return true;
		});
		setConfigurationFiles(newArr);
	};

	const onChangeCollapseDefinitions = async (key: string | string[], record: any, index: number) => {
		if (key && key.length > 0) {
			const token = await GETJwtToken();
			const response = await triggerGetDataBlock({
				token,
				dataBlockID: record.data_block.id,
			}).unwrap();
			if (response && response.data && response.data.offsets) {
				const copy = structuredClone(configurationFiles);
				copy[index].data_block.offsets = response.data.offsets;
				setConfigurationFiles(copy);
			}
		}
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (userLogged && userLogged.org) {
			setIdOrganizationSelected(userLogged.org.id);
			getInitialData();
			getOffsetsList();
		}
	}, []);

	useEffect(() => {
		if (data && data.data && data.data.length > 0) {
			buildConfigurationFilesData(data.data);
		}
	}, [data]);

	useEffect(() => {
		if (isError) {
			setConfigurationFiles([]);
		}
	}, [isError]);

	useEffect(() => {
		if (configurationFileRefreshTable) {
			refreshTable(idOrganizationSelected);
		}
	}, [configurationFileRefreshTable]);

	useEffect(() => {
		if (liftOffsetsType && liftOffsetsType.data) {
			setOffsetsTypeList(liftOffsetsType.data);
		}
	}, [liftOffsetsType]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='mt-3'>
			{validatePermission("configurations-view", permissions) ? (
				<>
					{!isError ? (
						<Table
							rowClassName={(record, index) => getTableRowClass(index, theme)}
							className='customTable'
							dataSource={configurationFiles}
							loading={isLoading || isFetching || isLoadingGetOffsetTypes || isLoadingDataBlock || isFetchingDataBlock}
							size='small'
							scroll={{ x: 900 }}
							pagination={{
								defaultPageSize: 9999,
							}}
						>
							{/** NAME */}
							<Column
								onCell={(record: any) => ({
									onClick: () => {
										onCellClick(record);
									},
									rowSpan: record.rowSpan,
								})}
								title={t("configuration.configurationFiles.name")}
								dataIndex='name'
								key='name'
								className='generalStyles__tableHeaderLink generalStyles__ellipsis__250px'
								render={(text) => (
									<>
										<div>{text || "--"}</div>
									</>
								)}
							/>
							{/** TYPE */}
							<Column
								onCell={(record: any) => ({
									onClick: () => {
										onCellClick(record);
									},
									rowSpan: record.rowSpan,
								})}
								title={t("edgeManagement.lifts.type")}
								dataIndex='lift_type'
								key='lift_type'
								width='150px'
								filterSearch
								render={(text) => (
									<>
										<div>
											{text === "ELEVATOR" && (
												<Tag color='blue' className='drawer__typeTag'>
													{text}
												</Tag>
											)}
											{text === "ESCALATOR" && (
												<Tag color='volcano' className='drawer__typeTag'>
													{text}
												</Tag>
											)}
											{text === "MOVINGWALK" && (
												<Tag color='green' className='drawer__typeTag'>
													{text}
												</Tag>
											)}
										</div>
									</>
								)}
							/>
							{/** LIFTS */}
							<Column
								onCell={(record: any) => ({
									onClick: () => {
										onCellClick(record);
									},
									rowSpan: record.rowSpan,
								})}
								title={t("configuration.configurationFiles.lifts")}
								dataIndex='lifts'
								key='lifts'
								width='100px'
								filterSearch
								render={(text) => (
									<>
										<div>{text || 0}</div>
									</>
								)}
							/>
							{/** FLOORS */}
							<Column
								onCell={(record: any) => ({
									onClick: () => {
										onCellClick(record);
									},
									rowSpan: record.rowSpan,
								})}
								title={t("configuration.configurationFiles.floors")}
								dataIndex='stops'
								key='stops'
								width='100px'
								filterSearch
								render={(text, record: any) => (
									<>
										<div>{record.stops || 0}</div>
									</>
								)}
							/>
							{/** VENDOR */}
							<Column
								onCell={(record: any) => ({
									onClick: () => {
										onCellClick(record);
									},
									rowSpan: record.rowSpan,
								})}
								title={t("configuration.vendor.vendor")}
								dataIndex='vendor'
								key='vendor'
								width='100px'
								filterSearch
								render={(text, record: any) => (
									<>
										<div>
											{record && record.data_block && record.data_block.vendor && record.data_block.vendor.name
												? record.data_block.vendor.name
												: "--"}
										</div>
									</>
								)}
							/>
							{/** DATA BLOCKS */}
							<Column
								title={t("configuration.dataBlock.dataBlock")}
								filterSearch
								width='150px'
								render={(text, record: any) => (
									<>
										<Link to='offsets'>
											{record &&
											record.data_block &&
											record.data_block.data_block_type &&
											record.data_block.data_block_type.name ? (
												<Tag
													className='generalStyles__textEllipsis'
													color='green'
													style={{
														cursor: "pointer",
														width: "100px",
														textAlign: "center",
													}}
													onClick={() => {
														handleGoToOffsetsTable(record);
													}}
												>
													<span>{record.data_block.data_block_type.name}</span>
												</Tag>
											) : (
												<Tag
													color='red'
													style={{
														cursor: "pointer",
														width: "100px",
														textAlign: "center",
													}}
													onClick={() => {
														handleGoToOffsetsTable(record);
													}}
												>
													N/A
												</Tag>
											)}
										</Link>
									</>
								)}
							/>
							{/** DEFINITIONS */}
							<Column
								title='Definitions'
								dataIndex='definitions'
								key='definitions'
								filterSearch
								render={(text, record: any, index1: number) => (
									<Collapse
										bordered={false}
										onChange={(key) => {
											onChangeCollapseDefinitions(key, record, index1);
										}}
									>
										<Panel
											header='Definitions'
											key='1'
											style={{
												display: "flex",
												backgroundColor: getTableRowClass(index1, theme),
											}}
										>
											<div>
												{offsetTypeList.map((item: any, index: number) => (
													<div key={index} className='mt-1'>
														{getOffsetsCountByConfiguration(item.name, record.data_block.offsets) > 0 && (
															<div>
																<Link to='definitions'>
																	<Tag
																		color={item.color}
																		style={{ cursor: "pointer" }}
																		onClick={() => {
																			handleGoToDefinitions(record, item.name, item.color, `definitions`);
																		}}
																		key={index}
																	>
																		<span>{item.name}</span>
																	</Tag>
																</Link>
															</div>
														)}
													</div>
												))}
											</div>{" "}
										</Panel>
									</Collapse>
								)}
							/>
							{/** ACTIONS */}
							<Column
								title={t("edgeManagement.banks.actions")}
								key='action'
								width='100px'
								onCell={(record: any) => ({
									rowSpan: record.rowSpan,
								})}
								render={(_: any, record: any) => (
									<Dropdown overlay={menu} trigger={["click"]}>
										<Space
											className='generalStyles__hoverCursor'
											onClick={() => {
												setSelectedItem(record);
											}}
										>
											<FontAwesomeIcon icon={faEllipsis} />
										</Space>
									</Dropdown>
								)}
							/>
						</Table>
					) : (
						<Alert
							message={t("organizationManagement.errorDescription.error")}
							description={t("organizationManagement.errorDescription.text1")}
							type='error'
							showIcon
							className='mt-3'
						/>
					)}
				</>
			) : (
				<>
					<div className='generalStyles__noAccessToListTable'>
						<Empty description={t("edgeManagement.banks.listNotAvailable")} image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</div>
				</>
			)}
		</div>
	);
};

export default ConfigurationFilesExpandableRow;
