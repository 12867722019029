import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Switch, Tooltip, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { useLazyGetAllOffsetsTypesQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { RootState } from "../../../app/store";
import { OffsetTypeInterface } from "../../../interfaces/Offsets.interface";
import {
	ReportFilterListOffsetsInterface,
	ReportSliceFiltersInterface,
} from "../../../interfaces/Reports.interface";

function ReportsDrawerOffsetTypeFilters() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [listOffsets, setListOffsets] = useState<
		ReportFilterListOffsetsInterface[]
	>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetOffsetsType, { data, isLoading }] =
		useLazyGetAllOffsetsTypesQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initData = async () => {
		const token = await GETJwtToken();
		triggerGetOffsetsType({ token });
	};

	const onChangeOffset = (newValue: boolean, offsetName: string) => {
		const copy = structuredClone(listOffsets);
		const index = copy.findIndex(
			(object: ReportFilterListOffsetsInterface) => object.value === offsetName
		);
		if (index >= 0) {
			copy[index].isActive = newValue;
		}
		setListOffsets(copy);
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		if (data && data.data) {
			// Generate list
			const newArr: ReportFilterListOffsetsInterface[] = [];
			data.data.map((offset: OffsetTypeInterface) => {
				const newObj = {
					id: offset.id,
					label: offset.name,
					value: offset.name,
					isActive: false,
				};
				if (filters.defaultOffsets.includes(offset.name.toUpperCase())) {
					newObj.isActive = true;
				}
				newArr.push(newObj);
				return true;
			});
			setListOffsets(newArr);
			// Set default offset type
		}
	}, [data]);

	useEffect(() => {
		const newList: string[] = [];
		listOffsets.map((offset: ReportFilterListOffsetsInterface) => {
			if (offset.isActive) {
				newList.push(offset.value);
			}
			return true;
		});
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.offsetsList = newList;
		dispatch(updateFilters(copy));
	}, [listOffsets]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={isLoading}>
			<div className={`drawer__box__${theme} generalStyles__flexWrap`}>
				{/* OFFSETS */}
				{listOffsets.map((offset: ReportFilterListOffsetsInterface) => (
					<div
						className='generalStyles__flex mt-2 mb-2 '
						style={{ width: "50%", paddingRight: "15px" }}
						key={offset.id}
					>
						<div className='generalStyles__mrFix generalStyles__mlFix'>
							<Tooltip
								title={
									filters.disabledOffsets
										? t("general.disabledOffsetsReport")
										: ""
								}
							>
								<Switch
									checked={offset.isActive}
									onChange={(checked: boolean) => {
										onChangeOffset(checked, offset.value);
									}}
									disabled={filters.disabledOffsets}
								/>
							</Tooltip>
						</div>
						<div className='generalStyles__textEllipsis'>
							<Tooltip title={offset.label}>
								<span> {offset.label}</span>
							</Tooltip>
						</div>
					</div>
				))}
			</div>
		</Spin>
	);
}

export default ReportsDrawerOffsetTypeFilters;
