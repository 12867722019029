import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Select } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import { useLazyGetServersQuery } from "../../../redux/servers/serverAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function ServersAssignServer({ serverId, setServerId, disableComponent }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [servers, setServers] = useState<any>([]);
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetServers, { data }] = useLazyGetServersQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const initData = async () => {
		const token = await GETJwtToken();
		triggerGetServers({
			token,
			page: 1,
			limit: 9999,
		});
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		if (data && data.data) {
			setServers(data.data);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Divider orientation='left' className='generalStyles__drawerDivider'>
				<h5>
					<FontAwesomeIcon icon={faServer} className='generalStyles__info generalStyles__mrFix' />
					{t("edgeManagement.servers.assignToServer")}
				</h5>
			</Divider>
			<div className={`drawer__box__${theme}`}>
				{/** ---------------------------------------------------- */}
				{/** SERVERS */}
				<div className='col-md-12'>
					<div className='generalStyles__flex'>
						<div className='generalStyles__inputFlexNameLarge'>
							<span className='generalStyles__inputFlexRequired'>*</span>
							<span>{t("header.servers")}</span>
						</div>
						<div className='generalStyles__width100'>
							<Select
								className='generalStyles__width100'
								size='small'
								onChange={(e: any) => {
									setServerId(e);
								}}
								value={serverId}
								disabled={disableComponent}
							>
								{servers.map((item: any) => (
									<Option key={item.id} value={item.id}>
										{item.name}
									</Option>
								))}
							</Select>
						</div>
						<div className='generalStyles__infoTooltipIconBlank' />
					</div>
				</div>
			</div>
		</div>
	);
}

export default ServersAssignServer;
