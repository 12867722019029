import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faBan } from "@fortawesome/free-solid-svg-icons";

function TableColumnStatus({ active }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='generalStyles__ellipsis__200px'>
				{active ? (
					<Tag
						color='success'
						icon={<FontAwesomeIcon icon={faCheckCircle} className='generalStyles__mrFix' />}
						className='drawer__tag'
					>
						{t("general.active")}
					</Tag>
				) : (
					<Tag
						color='error'
						icon={<FontAwesomeIcon icon={faBan} className='generalStyles__mrFix' />}
						className='drawer__tag'
					>
						{t("general.block")}
					</Tag>
				)}
			</div>
		</div>
	);
}

export default TableColumnStatus;
