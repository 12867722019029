import React from "react";
import { Auth } from "aws-amplify";
// ANT DESIGN COMPONENTS
import { Modal, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector } from "react-redux";



function UserNotFound() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");


	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { userLogged } = useSelector((state: any) => state.home);


	// ************************************************ */
	// FUNCTIONS ************************************** */


	const signOut = async () => {
		await Auth.signOut();
		window.location.href = `${process.env.REACT_APP_LOGIN_ROUTE}`;
	};
	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal
		
			closable={false}
			open={!userLogged.accepted_terms}
			width='50%'
			footer={
				<div className='generalStyles__flexEnd mt-1'>
					<div>
						<Button type='default' className='buttonStyle__2' onClick={signOut}>
							{t("header.signOut")}
						</Button>
					</div>
				</div>
			}
		>
			
				{/** ************************* */}
				{/** BODY */}
				<div style={{ height: "20vh",  padding: "15px" }}>
                <div className='generalStyles__textJustify'>
                    <h2>Your email is not registered as LiftNet user</h2>
                    <p>
                        Please contact your Lifnet technical support to get access to the platform.
                    </p>
                </div>
				</div>
			
		</Modal>
	);
}

export default UserNotFound;
