import React from "react";
// ANT DESIGN COMPONENTS
import { Button } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
// INTEFACES
import { ButtonCreateItemInterface } from "../../../interfaces/Components.interface";

function ButtonCreateItem({ onClick, label }: ButtonCreateItemInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Button
			type='primary'
			className='generalStyles__width200px'
			icon={<FontAwesomeIcon icon={faPlus} className='generalStyles__mrFix' />}
			onClick={() => {
				onClick();
			}}
		>
			{label}
		</Button>
	);
}

export default ButtonCreateItem;
