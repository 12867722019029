import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
import { faPlugCircleCheck } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerAdapter } from "../../../redux/adapters/adapterSlice";
// COMPONENTS
import DrawerAdapterInformation from "../../components/drawer/DrawerAdapterInformation";
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
// INTERFACES
import { AdapterInterface } from "../../../interfaces/Adapter.interface";
import { ActionInterface } from "../../../interfaces/Actions.interface";
import { RootState } from "../../../app/store";

function AdaptersDrawer() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const BLANK_ADAPTER = {
		auth_type: "PASSWORD",
		dictionary: {},
		interface: {},
		lift_type: "ELEVATOR",
		name: "",
		socket_address: "",
		protocol_type: "",
		type: "WEBSOCKET",
		version: "",
		auth_credentials: { username: "", password: "" },
	};
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<AdapterInterface>(BLANK_ADAPTER);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// LOCAL STORAGE AND VARIABLES ******************** */
	const { adapter, openDrawerAdapter } = useSelector(
		(state: RootState) => state.adapter
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerAdapter(false));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (adapter) {
			setData(adapter);
		}
	}, [adapter]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='550'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerAdapter}
		>
			{data && (
				<div>
					{/** ------------------------------------------------------------- */}
					{/** ADAPTER INFORMATION */}
					<DrawerAdapterInformation data={data} />
					{/** ------------------------------------------------------------- */}
					{/** ACTIONS ASSIGNED TO ADAPTER */}
					{data && data.action_catalogs && (
						<div>
							{/** ------------------------------------------------------------- */}
							{/** COMPONENT DRAWER DIVIDER */}
							<br />
							<DrawerRowDivider
								icon={faPlugCircleCheck}
								title={t("adapter.actions")}
							/>
							{data.action_catalogs.map((action: ActionInterface) => (
								<div className={`drawer__box__${theme} mt-3`}>
									{/** ID */}
									<div className='generalStyles__flex'>
										<div>
											<p className='drawer__title'>ID</p>
										</div>
										<div className='drawer__textContainer'>
											<p className='drawer__text'>
												{action.id ? action.id : "--"}
											</p>
										</div>
									</div>
									{/** COMMAND */}
									<div className='generalStyles__flex'>
										<div>
											<p className='drawer__title'>{t("general.command")}:</p>
										</div>
										<div className='drawer__textContainer'>
											<p className='drawer__text'>
												{action.cmd ? action.cmd : "--"}
											</p>
										</div>
									</div>
									{/** LABEL */}
									<div className='generalStyles__flex'>
										<div>
											<p className='drawer__title'>{t("general.label")}:</p>
										</div>
										<div className='drawer__textContainer'>
											<p className='drawer__text'>
												{action.label ? action.label : "--"}
											</p>
										</div>
									</div>
									{/** TOOLTIP */}
									<div className='generalStyles__flex'>
										<div>
											<p className='drawer__title'>{t("general.tooltip")}:</p>
										</div>
										<div className='drawer__textContainer'>
											<p className='drawer__text'>
												{action.tooltip ? action.tooltip : "--"}
											</p>
										</div>
									</div>
									{/** VALID STATES */}
									<div className='generalStyles__flex'>
										<div>
											<p className='drawer__title'>{t("general.states")}:</p>
										</div>
										<div className='drawer__textContainer'>
											{action && action.valid_states && (
												<>
													{action.valid_states.map((state: string) => (
														<Tag color='magenta'>{state}</Tag>
													))}
												</>
											)}
										</div>
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			)}
		</Drawer>
	);
}

export default AdaptersDrawer;
