import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import { useDeleteOrganizationMutation } from "../../../redux/organizations/organizationAPI";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function ModalDeleteItemOrganization({ nameComponent, nameItem, onClickClose, openModalDelete, id }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [disableButtonConfirmDelete, setDisableButtonConfirmDelete] = useState(true);
	const [confirmOrganizationName, setConfirmOrganizationName] = useState("");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [deleteOrganization, { isLoading, isSuccess, isError, error, reset: resetDeleteOrganization }] =
		useDeleteOrganizationMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickDelete = async () => {
		const token = await GETJwtToken();
		deleteOrganization({ id, token });
	};

	const onKeyDownInputName = (event: any) => {
		if (event.key === "Enter" && !disableButtonConfirmDelete) {
			onClickDelete();
		}
	};

	const deleteButtonStyle = () => {
		if (disableButtonConfirmDelete) {
			if (theme === "dark") {
				return "buttonStyle__inactive__1__dark generalStyles__mrFix";
			}
			return "buttonStyle__inactive__1 generalStyles__mrFix";
		}
		return "buttonStyle__2 generalStyles__mrFix";
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		setDisableButtonConfirmDelete(true);
		setConfirmOrganizationName("");
		resetDeleteOrganization();
	}, [openModalDelete]);

	useEffect(() => {
		setDisableButtonConfirmDelete(true);
		if (confirmOrganizationName && confirmOrganizationName.toLowerCase() === nameItem.toLowerCase()) {
			setDisableButtonConfirmDelete(false);
		} else {
			setDisableButtonConfirmDelete(true);
		}
	}, [confirmOrganizationName]);

	useEffect(() => {
		onClickClose();
	}, [isSuccess, isError]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal closable={false} onCancel={onClickClose} open={openModalDelete} footer={null}>
			<Spin spinning={isLoading}>
				<div>
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faTriangleExclamation}
							className='generalStyles__warning generalStyles__deleteItemIcon'
						/>
						<p className='mt-3 drawer__text'>
							{t("modal.modalDeleteText1")} <b className='generalStyles__warning'>{t("modal.modalDeleteText2")} </b>
							{t("modal.modalDeleteText3")} {nameComponent || "--"}{" "}
							<b className='generalStyles__warning'>{nameItem || "--"}</b> {t("modal.modalDeleteText4")}
						</p>
						<Input
							placeholder={t("general.name")}
							onChange={(e) => {
								setConfirmOrganizationName(e.target.value);
							}}
							value={confirmOrganizationName}
							onKeyDown={onKeyDownInputName}
							status='warning'
						/>
					</div>
					<div className='generalStyles__flexEnd mt-4'>
						<div>
							<Button
								htmlType='submit'
								className={deleteButtonStyle()}
								onClick={onClickDelete}
								disabled={disableButtonConfirmDelete}
							>
								{t("general.delete")}
							</Button>
							<Button onClick={onClickClose} className='buttonStyle__4'>
								{t("general.close")}
							</Button>
						</div>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2 isError={isError} isSuccess={isSuccess} requestType='DELETE' error={error} name='Organization' />
		</Modal>
	);
}

export default ModalDeleteItemOrganization;
