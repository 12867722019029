import React from "react";
// FONT AWESOME LIBRARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
// STYLES
import notFoundImage from "../../assets/error/404.png";

library.add(faUser);

function NotFoundPage() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='generalStyles__flexCenter mb-5'>
				<img src={notFoundImage} alt='' width='300' />
			</div>
		</div>
	);
}

export default NotFoundPage;
