/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Tabs, type TabsProps } from "antd";
import GETJwtToken from "../../../redux/authentication/authentication";
import {
  updateMapViewPinArray,
  updateOpenSelectMode,
  updateSelectedPin,
} from "../../../redux/mapView/mapViewSlice";
import { useLazyGetBanksByOrganizationQuery } from "../../../redux/banks/bankAPI";
import {
  MapViewDirections,
  MapViewTypes,
} from "../../../interfaces/MapView.interface";
import {
  useLazyGetMapViewQuery,
  usePostMapViewPinMutation,
} from "../../../redux/mapView/mapViewAPI";

// ANT DESIGN COMPONENTS
function MapViewMenuItem({
  name,
  id,
  active,
  type,
}: {
  name: string;
  id: string;
  active: boolean;
  type: MapViewTypes;
}) {
  const [direction, setDirection] = React.useState<MapViewDirections>(
    MapViewDirections.UP
  );

  const { mapViewPinArray, mapViewItem } = useSelector(
    (state: any) => state.mapView
  );
  const dispatch = useDispatch();

  const [triggerPostMapViewPin] = usePostMapViewPinMutation();

  const directions = {
    UP: faArrowUp,
    DOWN: faArrowDown,
    LEFT: faArrowLeft,
    RIGHT: faArrowRight,
  };

  const handleClickActionButton = async () => {
    if (active) {
      dispatch(
        updateMapViewPinArray(
          mapViewPinArray.filter((lift: any) => lift.id !== id)
        )
      );
      if (!mapViewItem?.setup) return;
      const pins = mapViewItem?.setup?.pins ?? [];
      const body = {
        id: mapViewItem.id,
        name: mapViewItem.name,
        org_id: mapViewItem.org_id,
        start: mapViewItem.start,
        setup: {
          pins: mapViewPinArray.filter((lift: any) => lift.id !== id),
        },
      };
      const token = await GETJwtToken();
      triggerPostMapViewPin({
        token,
        body,
      });
    } else {
      dispatch(updateOpenSelectMode(true));
      dispatch(
        updateSelectedPin({
          text: name,
          id,
          direction,
          type,
        })
      );
    }
  };

  const handleChangeDirectionClockwise = () => {
    if (direction === MapViewDirections.UP) {
      setDirection(MapViewDirections.RIGHT);
    } else if (direction === MapViewDirections.RIGHT) {
      setDirection(MapViewDirections.DOWN);
    } else if (direction === MapViewDirections.DOWN) {
      setDirection(MapViewDirections.LEFT);
    } else if (direction === MapViewDirections.LEFT) {
      setDirection(MapViewDirections.UP);
    }
  };
  return (
    <div
      style={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: 8,
        padding: "8px",
        display: "flex",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 8,
          display: "inline-flex",
        }}
      >
        <Button ghost onClick={handleChangeDirectionClockwise}>
          <FontAwesomeIcon icon={directions[direction]} color="#2c2c2c" />
        </Button>
        <p
          style={{
            textAlign: "center",
          }}
        >
          {name}
        </p>
      </div>
      <Button ghost onClick={handleClickActionButton}>
        <FontAwesomeIcon
          icon={active ? faX : faAdd}
          color={`${active ? "#E32A2A" : "#1D7F38"}`}
        />
      </Button>
    </div>
  );
}

interface MapViewBanksInterface {
  name: string;
  lifts: any[];
}

function OrganizationGroup({ name, lifts }: MapViewBanksInterface) {
  if (!lifts) return null;
  const { mapViewPinArray } = useSelector((state: any) => state.mapView);
  const getType = (type: string) => {
    if (type === "ELEVATOR") return MapViewTypes.LIFT;
    if (type === "ESCALATOR") return MapViewTypes.ESCALATOR;
    if (type === "MAP") return MapViewTypes.MAP;
    if (type === "MOVINGWALK") return MapViewTypes.MOVINGWALK;
    return MapViewTypes.LIFT;
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p
        style={{
          fontSize: 12,
          fontWeight: "700",
        }}
      >
        {name}
      </p>
      {lifts?.map((lift: any, index) => (
        <MapViewMenuItem
          key={index}
          name={lift.name}
          id={lift.id}
          type={getType(lift.type)}
          active={mapViewPinArray?.find((l: any) => l.id === lift.id)}
        />
      ))}
    </div>
  );
}

function MapViewMenu() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { mapViewItem, mapViewPinArray } = useSelector(
    (state: any) => state.mapView
  );
  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  const [triggerGetBanks, { data: dataGetBanks }] =
    useLazyGetBanksByOrganizationQuery();

  const [triggerGetMapView, { data: dataGetMapView }] =
    useLazyGetMapViewQuery();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const getOrganizationsFNC = async () => {
    const token = await GETJwtToken();
    triggerGetBanks({
      token,
      limit: 9999,
      page: 1,
      searchAndFilterString: `&org_id=${mapViewItem?.org_id}`,
    });
  };

  const getMapViewsFNC = async () => {
    const token = await GETJwtToken();
    const body = {
      org_id: mapViewItem?.org_id,
    };
    triggerGetMapView({
      token,
      body,
    });
  };
  // ************************************************ */
  // USE EFFECT ************************************* */

  useEffect(() => {
    if (mapViewItem?.org_id) {
      getMapViewsFNC();
      getOrganizationsFNC();
    }
  }, [mapViewItem?.org_id]);

  // ************************************************ */
  // COMPONENT ************************************** */

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Lifts",
      children: (
        <>
          {dataGetBanks?.data?.map(
            (bank: MapViewBanksInterface, index: number) => (
              <OrganizationGroup
                key={index}
                name={bank.name}
                lifts={bank.lifts}
              />
            )
          )}
        </>
      ),
    },
    {
      key: "2",
      label: "Connections",
      children: (
        <>
          {dataGetMapView?.data?.map((mw: any, index: number) => {
            if (mw.id === mapViewItem.id) return null;
            return (
              <MapViewMenuItem
                key={index}
                name={mw.name}
                active={mapViewPinArray?.find((l: any) => l.id === mw.id)}
                type={MapViewTypes.MAP}
                id={mw.id}
              />
            );
          })}
        </>
      ),
    },
  ];

  return (
    <div
      style={{
        padding: 16,
        overflowY: "auto",
        height: "80vh",
      }}
    >
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
}

export default MapViewMenu;
