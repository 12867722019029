import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Select, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { useLazyGetServersQuery } from "../../../redux/servers/serverAPI";
import {
	updateFilters,
	updateServers,
	updateIdServerPositionCero,
	updateOrganizationsGroups,
} from "../../../redux/reports/reportsSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { ServerInterface } from "../../../interfaces/EdgeManagement.interface";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";
import { RootState } from "../../../app/store";

function ReportsFilterGetServers() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option, OptGroup } = Select;
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters, blockFilters, servers, organizationsGroups } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetServers, { data, isError }] = useLazyGetServersQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initialData = async () => {
		const token = await GETJwtToken();
		triggerGetServers({
			page: 1,
			limit: 9999,
			token,
		});
	};

	const onChangeServer = (newValue: string) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		const index = servers.findIndex((object: any) => object.id === newValue);
		if (servers[index]) {
			copy.serverId = servers[index].id;
			copy.serverName = servers[index].name;
			if (servers[index].org) {
				copy.organizationId = servers[index].org.id;
				copy.organizationName = servers[index].org.name;
			}
		}
		dispatch(updateFilters(copy));
	};

	const callFirstServer = () => {
		if (servers && servers[0] && servers[0].id && filters.serverId === "") {
			onChangeServer(servers[0].id);
		}
	};

	const generateGroupsOrganizations = (response: any) => {
		const newGroups: any = [];
		response.map((item: any) => {
			if (item && item.org && item.org.name && item.org.id) {
				newGroups.push({ name: item.org.name, id: item.org.id });
			}
			return true;
		});
		// Sort by name
		newGroups.sort((a: any, b: any) => {
			if (a.name.toLowerCase() < b.name.toLowerCase()) {
				return -1;
			}
			if (a.name.toLowerCase() > b.name.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		// Remove repeat items
		const uniqueArray = newGroups.filter(
			(item: any, index: any, self: any) =>
				index === self.findIndex((obj: any) => obj.id === item.id)
		);
		//
		dispatch(updateOrganizationsGroups(uniqueArray));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (servers.length === 0) {
			initialData();
		}
	}, []);

	useEffect(() => {
		if (data && data.data && data.data.length > 0) {
			dispatch(updateServers(data.data));
			dispatch(updateIdServerPositionCero(data.data[0].id));
			generateGroupsOrganizations(data.data);
		}
	}, [data]);

	useEffect(() => {
		if (isError) {
			dispatch(updateServers([]));
		}
	}, [isError]);

	useEffect(() => {
		callFirstServer();
	}, [servers]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__width100'>
			<Tooltip title={servers.length === 0 ? t("report.noServers") : ""}>
				<Select
					className='generalStyles__width100'
					disabled={servers.length === 0 || blockFilters}
					showSearch
					optionFilterProp='children'
					size='small'
					placeholder={t("report.selectServer")}
					value={filters.serverId === "" ? null : filters.serverId}
					onChange={onChangeServer}
				>
					{organizationsGroups.map((group: any) => (
						<OptGroup key={group.id} label={`ORG / ${group.name}`}>
							{servers
								.filter((server: ServerInterface) => server.org_id === group.id)
								.map((server: ServerInterface) => (
									<Option key={server.id} value={server.id}>
										{server.name}
									</Option>
								))}
						</OptGroup>
					))}
				</Select>
			</Tooltip>
		</div>
	);
}

export default ReportsFilterGetServers;
