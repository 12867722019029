import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { DatePicker } from "antd";
// MOMENT DATE FORMAT
import moment from "moment";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterDateMonth() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters, blockFilters } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChange = (date: any, dateString: any) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		const startMonth = moment(dateString, "YYYY-MM");
		const endMonth = moment(dateString, "YYYY-MM");
		const startMonthNumber = startMonth.month() + 1;
		const endMonthNumber = endMonth.month() + 1;
		const date1 = moment()
			.month(startMonthNumber - 1)
			.startOf("month")
			.startOf("day")
			.format("YYYY-MM-DD");
		const date2 = moment()
			.month(endMonthNumber - 1)
			.endOf("month")
			.endOf("day")
			.format("YYYY-MM-DD");
		copy.startDate = moment(date1).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		copy.endDate = moment(date2).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		dispatch(updateFilters(copy));
	};

	const generateDefaultMonthDate = () => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		const startMonthNumber = moment().month() + 1;
		const endMonthNumber = moment().month() + 1;
		const date1 = moment()
			.month(startMonthNumber - 1)
			.startOf("month")
			.startOf("day");
		const date2 = moment()
			.month(endMonthNumber - 1)
			.endOf("month")
			.endOf("day");
		copy.startDate = moment(date1).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		copy.endDate = moment(date2).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		generateDefaultMonthDate();
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************* */}
			{/** BODY */}
			<DatePicker
				picker='month'
				className='generalStyles__width100'
				style={{ height: "24px" }}
				onChange={onChange}
				value={moment(filters.startDate)}
				allowClear={false}
				disabled={blockFilters}
			/>
		</div>
	);
}

export default ReportsFilterDateMonth;
