import React from "react";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateOpenModalCreateEditUser,
	updateUserRefreshTable,
	updateFormCreateEdit,
} from "../../../redux/users/userSlice";
// COMPONENTS
import ButtonRefreshTable from "../../components/buttons/ButtonRefreshTable";
import ButtonCreateItem from "../../components/buttons/ButtonCreateItem";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";

function UsersHeader() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalCreateNewUser = () => {
		dispatch(updateOpenModalCreateEditUser(true));
		dispatch(updateFormCreateEdit("create"));
	};

	const onClickReloadPage = () => {
		dispatch(updateUserRefreshTable(true));
		setTimeout(() => {
			dispatch(updateUserRefreshTable(false));
		}, 1000);
	};

	return (
		<div className='generalStyles__pageHeader'>
			{validatePermission("users-create", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<ButtonCreateItem
						onClick={handleOpenModalCreateNewUser}
						label={t("userManagement.createNewUser")}
					/>
				</div>
			)}
			{validatePermission("users-view", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<ButtonRefreshTable
						onClick={onClickReloadPage}
						label={t("organizationManagement.refreshTable")}
					/>
				</div>
			)}
		</div>
	);
}

export default UsersHeader;
