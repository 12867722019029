import React from "react";
// ANT DESIGN COMPONENTS
import { Input } from "antd";
// INTERFACES
import { FormInputTextDisabledInterface } from "../../../interfaces/Global.interface";

function FormInputTextDisabled({
	inputValue,
	inputLabelName,
}: FormInputTextDisabledInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='col-md-12 mt-3'>
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='drawer__text'>{inputLabelName}</span>
				</div>
				<div className='generalStyles__width100'>
					<Input
						className='generalStyles__input'
						size='small'
						value={inputValue}
						disabled
					/>
				</div>
				<div className='generalStyles__infoTooltipIconBlank' />
			</div>
		</div>
	);
}

export default FormInputTextDisabled;
