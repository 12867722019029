import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Tag, Tooltip } from "antd";
// REDUX
import { useSelector } from "react-redux";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
// COMPONENTS
import IconDefinitionIcons from "../../tools/iconDefinition/IconDefinitionIcons";
import MonitoringIconsEscalators from "../monitoringIcons/MonitoringIconsEscalators";
import MonitoringIconsMovingWalks from "../monitoringIcons/MonitoringIconsMovingWalks";
// INTERFACES
import {
	BankFloorInterface,
	MonitoringModalSettingsHeaderInterface,
	SocketMessageInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function ControlPanelHeader({
	lift,
	layout,
	socketMessageElevator,
	socketMessageEscalator,
	socketMessageMovingwalk,
	iconDefinitionEscalator,
	iconDefinitionMovingwalk,
	iconDefinitionElevator,
}: MonitoringModalSettingsHeaderInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [currentPositionNameFloor, setCurrentPositionNameFloor] = useState("");
	const [nextStopNameFloor, setNextStopNameFloor] = useState("");
	const [lastShortMesageElevator, setLastShortMessageElevator] = useState("elOutIndicator");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { elevatorIconDefinitions } = useSelector((state: RootState) => state.monitoring);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getFloorName = (socketMessage_: SocketMessageInterface) => {
		layout.bank_floors.map((floor: BankFloorInterface) => {
			if (socketMessage_.position === floor.floor_no) {
				setCurrentPositionNameFloor(floor.name);
			}
			if (
				socketMessage_.car_calls &&
				(socketMessage_.car_calls.front[0] || socketMessage_.car_calls.rear[0]) &&
				(socketMessage_.car_calls.front[0] === floor.floor_no || socketMessage_.car_calls.rear[0] === floor.floor_no)
			) {
				setNextStopNameFloor(floor.name);
			}
			return true;
		});
	};

	const getCarCallName = (carCall: number) => {
		let floorName = "";
		if (layout) {
			layout.bank_floors.map((floor: BankFloorInterface) => {
				if (carCall === floor.floor_no) {
					floorName = floor.name;
				}
				return true;
			});
		}
		return floorName.substring(0, 2).toUpperCase();
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		getFloorName(socketMessageElevator);
		//
		if (
			iconDefinitionElevator &&
			elevatorIconDefinitions.includes(iconDefinitionElevator[socketMessageElevator.icon.name.toLowerCase()])
		) {
			setLastShortMessageElevator(iconDefinitionElevator[socketMessageElevator.icon.name.toLowerCase()]);
		}
	}, [socketMessageElevator]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`montoringView__controlPanel__${theme}`}>
			<div className={`monitoringView__controlPanelDetails monitoringView__controlPanelDetails__${theme}`}>
				<div className='generalStyles__flexCenter monitoring__100pxBox2'>
					<div>
						<div style={{ textAlign: "center" }}>
							{lift && lift.type === "ELEVATOR" && (
								<IconDefinitionIcons
									isMap={false}
									expanded={false}
									liftType='ELEVATOR'
									icon={lastShortMesageElevator}
									iconSize={50}
								/>
							)}
							{lift && lift.type === "ESCALATOR" && (
								<MonitoringIconsEscalators
									lift={lift}
									shortMessage={socketMessageEscalator.icon.name}
									iconDefinitionEscalator={iconDefinitionEscalator}
									iconSize={60}
								/>
							)}
							{lift && lift.type === "MOVINGWALK" && (
								<MonitoringIconsMovingWalks
									lift={lift}
									shortMessage={socketMessageMovingwalk.icon.name}
									iconDefinitionMovingwalk={iconDefinitionMovingwalk}
									iconSize={60}
								/>
							)}
						</div>
						<div className='generalStyles__flexCenter'>
							<div style={{ textAlign: "center" }}>
								{socketMessageElevator.service_mode.name !== "" && (
									<Tooltip
										placement='top'
										title={`${socketMessageElevator.service_mode.name} - ${socketMessageElevator.service_mode.description}`}
									>
										<Tag
											color='cyan'
											style={{
												width: "85px",
												textAlign: "center",
												marginTop: "10px",
												marginLeft: "10px",
											}}
										>
											{socketMessageElevator.service_mode.name.toUpperCase().substring(0, 9) || "--"}
										</Tag>
									</Tooltip>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='generalStyles__mlFix25 generalStyles__width100'>
					<div>
						<span className='montoring__modalSettingsHeader__liftName'>{lift.name || "--"}</span>
						<span className='generalStyles__mrFix generalStyles__mlFix'>/</span>
						<span>{layout.name || "--"}</span>
					</div>
					<div>
						<p className='generalStyles__info'>{socketMessageElevator.icon.description.replace("_", " ")}</p>
					</div>
					{/** CAR CALLS */}
					<div className='generalStyles__flex montoring__modalSettingsHeader__carCallsContainer'>
						<div>{socketMessageElevator.car_calls && lift && lift.type === "ELEVATOR" ? "Calls" : ""}</div>
						<div className='generalStyles__flex'>
							{socketMessageElevator.car_calls && socketMessageElevator.car_calls.front.length > 0 && (
								<>
									{socketMessageElevator.car_calls.front.map((carCall: number, index: number) => (
										<div
											key={index}
											className={`monitoringView__headerCarCall monitoringView__headerCarCall__${theme}`}
										>
											{getCarCallName(carCall)}
										</div>
									))}
								</>
							)}
							{socketMessageElevator.car_calls && socketMessageElevator.car_calls.rear.length > 0 && (
								<>
									{socketMessageElevator.car_calls.rear.map((carCall: number, index: number) => (
										<div
											key={index}
											className={`monitoringView__headerCarCall monitoringView__headerCarCall__${theme}`}
										>
											{getCarCallName(carCall)}
										</div>
									))}
								</>
							)}
						</div>
					</div>
				</div>
				<div className='generalStyles__flex mt-1'>
					<div className='montoring__modalSettingsHeader__currentFloorContainer'>{currentPositionNameFloor}</div>
					{socketMessageElevator.car_calls &&
						(socketMessageElevator.car_calls.front.length > 0 || socketMessageElevator.car_calls.rear.length > 0) && (
							<>
								<div>
									<FontAwesomeIcon icon={faCaretRight} className='montoring__modalSettingsHeader__icon' />
								</div>
								<div className='montoring__modalSettingsHeader__currentFloorContainer'>{nextStopNameFloor}</div>
							</>
						)}
				</div>
			</div>{" "}
		</div>
	);
}

export default ControlPanelHeader;
