import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Tag, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faUser,
	faBuildingLock,
	faCheckCircle,
	faCircleExclamation,
	faBan,
	faBuildingCircleCheck,
	faUserShield,
	faUserLock,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerUser } from "../../../redux/users/userSlice";
// COMPONENTS
import DrawerOrganizationInformation from "../../components/drawer/DrawerOrganizationInformation";
import DrawerRoleInformation from "../../components/drawer/DrawerRoleInformation";
import RolesStaticRolesTree from "../../organizationManagement/roles/RolesStaticRolesTree";

library.add(
	faUser,
	faBuildingCircleCheck,
	faBuildingLock,
	faCheckCircle,
	faCircleExclamation,
	faBan,
	faUserShield,
	faUserLock
);

function UserDrawer() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const { user, openDrawerUser } = useSelector((state: any) => state.user);
	const [data, setData] = useState<any>({});
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerUser(false));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (user) {
			setData(user);
		}
	}, [user]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer width='550' placement='right' onClose={onClickClose} closable={false} open={openDrawerUser}>
			{data && (
				<div>
					{/** ************************************************* */}
					<div>
						<Divider orientation='left' className='generalStyles__drawerDivider'>
							<h5>
								<FontAwesomeIcon icon={faUser} className='generalStyles__info generalStyles__mrFix' />
								{t("userManagement.userInformation")}
							</h5>
						</Divider>
					</div>
					<div className={`drawer__box__${theme}`}>
						{/* ID */}
						<div className='generalStyles__flex'>
							<div>
								<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.userId")}:</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>{data.id ? data.id : "--"}</p>
							</div>
						</div>
						{/* FIRST NAME */}
						<div className='generalStyles__flex'>
							<div>
								<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.firstName")}</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>{data.first_name ? data.first_name : "--"}</p>
							</div>
						</div>
						{/* MIDDLE NAME */}
						<div className='generalStyles__flex'>
							<div>
								<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.middleName")}</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>{data.middle_name ? data.middle_name : "--"}</p>
							</div>
						</div>
						{/* LAST NAME */}
						<div className='generalStyles__flex'>
							<div>
								<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.lastName")}</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>{data.last_name ? data.last_name : "--"}</p>
							</div>
						</div>
						{/* EMAIL */}
						<div className='generalStyles__flex'>
							<div>
								<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.email")}</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>{data.email ? data.email : "--"}</p>
							</div>
						</div>
						{/* ADDRESS */}
						<div className='generalStyles__flex'>
							<div>
								<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.address")}</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>
									{data.contact_info && data.contact_info[0] && data.contact_info[0].address
										? data.contact_info[0].address
										: "--"}
								</p>
							</div>
						</div>
						{/* PHONE */}
						<div className='generalStyles__flex'>
							<div>
								<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.phone")}</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>
									{data.contact_info && data.contact_info[0] && data.contact_info[0].phone
										? data.contact_info[0].phone
										: "--"}
								</p>
							</div>
						</div>
						{/* DIAL CODE */}
						<div className='generalStyles__flex'>
							<div>
								<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.dialCode")}</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>
									{data.contact_info && data.contact_info[0] && data.contact_info[0].phone_code
										? data.contact_info[0].phone_code
										: "--"}
								</p>
							</div>
						</div>
						{/* STATUS */}
						<div className='generalStyles__flex'>
							<div>
								<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.status")}</p>
							</div>
							<div className='drawer__textContainer'>
								<p className='drawer__text'>
									{data.status ? (
										<>
											{data.status === "ACTIVE" && (
												<Tag
													color='success'
													icon={<FontAwesomeIcon icon={faCheckCircle} className='generalStyles__mrFix' />}
													className='drawer__tag'
												>
													{t("userManagement.active")}
												</Tag>
											)}
											{data.status === "NEW" && (
												<Tag
													color='warning'
													icon={<FontAwesomeIcon icon={faCircleExclamation} className='generalStyles__mrFix' />}
													className='drawer__tag'
												>
													{t("userManagement.newUser")}
												</Tag>
											)}
											{data.status === "BLOCKED" && (
												<Tag
													color='error'
													icon={<FontAwesomeIcon icon={faBan} className='generalStyles__mrFix' />}
													className='drawer__tag'
												>
													{t("userManagement.lock")}
												</Tag>
											)}
										</>
									) : (
										"--"
									)}
								</p>
							</div>
						</div>
					</div>
					{/** ************************************************* */}
					{/** ORGANIZATION INFORMATION  */}
					<DrawerOrganizationInformation data={data.org || {}} />
					{/** ************************************************* */}
					{/** ROLE INFORMATION  */}
					<DrawerRoleInformation data={data.role || {}} />
					{/** ************************************************* */}
					{/** ROLES STATIC TREE ONLINE */}
					<br />
					<div>
						<Divider orientation='left' className='generalStyles__drawerDivider'>
							<h5>
								<FontAwesomeIcon icon={faUserLock} className='generalStyles__info generalStyles__mrFix' />
								{t("organizationManagement.permissions")} Online
							</h5>
						</Divider>
					</div>
					<div className={`drawer__box__${theme}`}>
						<div>
							<RolesStaticRolesTree roleData={data.role} connectionType='online' />
						</div>
					</div>
					{/** ************************************************* */}
					{/** ROLES STATIC TREE OFFLINE */}
					<br />
					<div>
						<Divider orientation='left' className='generalStyles__drawerDivider'>
							<h5>
								<FontAwesomeIcon icon={faUserLock} className='generalStyles__info generalStyles__mrFix' />
								{t("organizationManagement.permissions")} Offline
							</h5>
						</Divider>
					</div>
					<div className={`drawer__box__${theme}`}>
						<div>
							<RolesStaticRolesTree roleData={data.role} connectionType='offline' />
						</div>
					</div>
				</div>
			)}
			{/** CLOSE AND SUBMIT BUTTONS */}
			<div className='mt-4 generalStyles__width100'>
				<div>
					<Button type='default' onClick={onClickClose} className='buttonStyle__5'>
						{t("organizationManagement.close")}
					</Button>
				</div>
			</div>
		</Drawer>
	);
}

export default UserDrawer;
