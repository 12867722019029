import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Empty, Table, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
// REDUX
import { useSelector } from "react-redux";
import { useLazyGetKeysQuery } from "../../../../redux/keys/keysAPI";
// AUTHORIZATION
import GETJwtToken from "../../../../redux/authentication/authentication";
import validatePermission from "../../../../utils/validatePermissions";
// FILTERS
import { getTableRowClass } from "../../../../utils/utils";
// COMPONENTS
import TableNoDataBox from "../../../components/table/TableNoDataBox";
import KeysTableActions from "./KeysTableActions";
// INTERFACES
import { KeyInterface } from "../../../../interfaces/Key.interface";
import { TablePaginationInterface } from "../../../../interfaces/Global.interface";
import { RootState } from "../../../../app/store";

function KeysTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: RootState) => state.home);
	const { Column } = Table;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [keys, setKeys] = useState<KeyInterface[]>([]);
	const [errorMessage, setErrorMessage] = useState<any>("");
	const [pagination, setPagination] = useState<TablePaginationInterface>({
		current: 1,
		pageSize: 10,
		showSizeChanger: true,
		pageSizeOptions: ["10", "20", "50", "100"],
		total: 0,
	});
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { keysAdapter } = useSelector((state: RootState) => state.adapter);
	const { permissions } = useSelector((state: any) => state.user);
	const { keyRefreshTable } = useSelector((state: RootState) => state.key);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetKeysTable,
		{
			data: dataGetKeys,
			isLoading: isLoadingGetKeys,
			isError: isErrorGetKeys,
			isFetching: isFetchingGetKeys,
			error: errorGetKeys,
		},
	] = useLazyGetKeysQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const getData = async (page: number, limit: number) => {
		const searchAndFilterString = "&filter_target=adapter_id&filter=".concat(keysAdapter.id || "");
		const token = await GETJwtToken();
		triggerGetKeysTable({
			page,
			limit,
			token,
			searchAndFilterString,
		});
	};

	const handleTableChange = async (pagination_: any) => {
		setPagination({
			...pagination,
			current: pagination_.current,
			pageSize: pagination_.pageSize,
			total: pagination_.total,
		});
		getData(pagination_.current, pagination_.pageSize);
	};

	const refreshTable = async () => {
		setPagination({
			...pagination,
			current: 1,
			pageSize: 10,
		});
		getData(1, 10);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getData(1, 10);
	}, []);

	useEffect(() => {
		if (dataGetKeys && dataGetKeys.data) {
			setPagination({
				...pagination,
				current: pagination.current,
				pageSize: pagination.pageSize,
				total: dataGetKeys.total,
			});

			const transformedKeys = dataGetKeys.data.map((keyItem: KeyInterface) => {
				let jInter: any;
				const copy = structuredClone(keyItem);
				try {
					if (typeof keyItem.value === "object") {
						jInter = keyItem.value;
					} else {
						jInter = JSON.parse(keyItem.value);
						if (typeof jInter === "string") {
							jInter = JSON.parse(jInter);
						}
					}

					copy.identifier = jInter;
					return copy;
				} catch (_) {
					return copy;
				}
			});

			setKeys(transformedKeys);
		}
	}, [dataGetKeys]);

	useEffect(() => {
		if (keyRefreshTable) {
			refreshTable();
		}
	}, [keyRefreshTable]);

	useEffect(() => {
		if (errorGetKeys) {
			setErrorMessage(errorGetKeys);
			setKeys([]);
			setPagination({
				current: 1,
				pageSize: 10,
				showSizeChanger: true,
				pageSizeOptions: ["10", "20", "50", "100"],
				total: 0,
			});
		}
	}, [errorGetKeys]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='mt-3'>
			{validatePermission("keys-view", permissions) ? (
				<Table
					locale={{
						emptyText: (
							<TableNoDataBox
								isError={isErrorGetKeys}
								errorMessage={
									errorMessage && errorMessage.data && errorMessage.data.message ? errorMessage.data.message : "Error"
								}
								noItemsFound='No keys found'
							/>
						),
					}}
					rowClassName={(record, index) => getTableRowClass(index, theme)}
					className='customTable'
					dataSource={keys}
					loading={isLoadingGetKeys || isFetchingGetKeys}
					rowKey={(record: any) => record.id}
					size='small'
					pagination={pagination}
					onChange={handleTableChange}
					scroll={{ x: 900 }}
					footer={() => (
						<div className='generalStyles__flexEnd'>
							<Tag>
								{pagination.total} {t("key.listKeys")}
							</Tag>
						</div>
					)}
				>
					{Object.entries(keysAdapter.interface || {}).map(([item]) => (
						<Column
							key={item}
							width='150px'
							title={item.charAt(0).toUpperCase() + item.slice(1)}
							render={(text, record: any) => (
								<>
									<div>{record && record.identifier ? record.identifier[item] : "--"}</div>
								</>
							)}
						/>
					))}
					{/** MESSAGE NAME */}
					<Column
						width='150px'
						title={t("key.shortMessage")}
						render={(text, record: any) => (
							<>
								<div>
									{record && record.message && record.message.short_message ? record.message.short_message : "--"}
								</div>
							</>
						)}
					/>
					{/** ACTIONS */}
					<Column
						title={t("general.actions")}
						key='action'
						width='100px'
						render={(_: any, record: KeyInterface) => <KeysTableActions selectedItem={record} />}
					/>
				</Table>
			) : (
				<>
					<div className='generalStyles__noAccessToListTable'>
						<Empty description={t("key.listNotAvailable")} image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</div>
				</>
			)}
		</div>
	);
}

export default KeysTable;
