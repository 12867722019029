/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LoginState {
	loginAmplify: boolean;
	jwtToken: string;
	amplifyActiveComponent: string;
	user: Object;
	isLoading: boolean;
	offlineToken: string;
}

const initialState: LoginState = {
	loginAmplify: false,
	jwtToken: "",
	amplifyActiveComponent: "",
	user: {},
	isLoading: false,
	offlineToken: "",
};

export const loginSlice = createSlice({
	name: "login",
	initialState,
	reducers: {
		updateLoginAmplify: (state, action: PayloadAction<boolean>) => {
			state.loginAmplify = action.payload;
		},
		updateJwtToken: (state, action: PayloadAction<string>) => {
			state.jwtToken = action.payload;
		},
		updateAmplifyActiveComponent: (state, action: PayloadAction<string>) => {
			state.amplifyActiveComponent = action.payload;
		},
		updateUser: (state, action: PayloadAction<Object>) => {
			state.user = action.payload;
		},
		updateIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		updateOfflineToken: (state, action: PayloadAction<string>) => {
			state.offlineToken = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateLoginAmplify,
	updateJwtToken,
	updateAmplifyActiveComponent,
	updateUser,
	updateIsLoading,
	updateOfflineToken,
} = loginSlice.actions;

export default loginSlice.reducer;
