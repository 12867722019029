import React, { useEffect, useState, useRef } from "react";
// ANT DESIGN COMPONENTS
import { Spin } from "antd";
// HTML CANVAS
import html2canvas from "html2canvas";
// MUI CHARTS
import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import Box from "@mui/material/Box";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownLong, faUpLong } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters, updateBlockFilters } from "../../../redux/reports/reportsSlice";
// UTILS
import {
	getChartBackgroundColor,
	getChartTextColor,
	getChartTicklineColor,
	generateLinesData,
	getChartAxisHighlight,
	getAxisLabels,
} from "../utils";
// COMPONENTS
import ReportsAlertDownloadModal from "../ReportsAlertDownloadModal";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ChartLine({ data, isLoading, bottomLabel, leftLabel, topLabel, topLabelIcon }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const chartRef = useRef(null);
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [labels, setLabels] = useState<string[]>([]);
	const [seriesData, setSeriesData] = useState<any>([]);
	const [localSelectedCarList, setLocalCarList] = useState<any>([]);
	// eslint-disable-next-line no-unused-vars
	const [downloadReport, setDownloadReport] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters, chartReportTheme, openPdfViewer, blockFilters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const arraysAreEqual = (arr1: any, arr2: any) => {
		if (arr1.length !== arr2.length) {
			return false;
		}

		return arr1.every((value: any, index: any) => value === arr2[index]);
	};

	const getCharNoDataMessage = () => {
		if (filters.carsList.length === 0) {
			return "general.noData";
		}
		if (localSelectedCarList.length === 0) {
			return "general.selectSomeCar";
		}
		if (data && data.length === 0) {
			return "general.monthHasNoData";
		}
		return "general.noData";
	};

	const captureChartAsImage = () => {
		if (chartRef.current) {
			html2canvas(chartRef.current).then((canvas) => {
				const imgData: any = canvas.toDataURL("image/png");
				const copy: ReportSliceFiltersInterface = structuredClone(filters);
				copy.urlChartImage = imgData;
				dispatch(updateFilters(copy));
			});
		}
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		dispatch(updateBlockFilters(true));
		if (localSelectedCarList && localSelectedCarList.length > 0 && data) {
			let curveType = "linear";
			if (filters.chartType === "line") {
				curveType = "linear";
			} else if (filters.chartType === "line_natural") {
				curveType = "natural";
			} else if (filters.chartType === "step_before") {
				curveType = "stepBefore";
			} else if (filters.chartType === "step_after") {
				curveType = "stepAfter";
			}
			const responseSeriesData = generateLinesData(data, curveType, localSelectedCarList, filters.chartMirror);
			setSeriesData(responseSeriesData);
			const responseLabels = getAxisLabels(data);
			setLabels(responseLabels);
		} else {
			setSeriesData([]);
		}
		setTimeout(() => {
			dispatch(updateBlockFilters(false));
		}, 1000);
	}, [localSelectedCarList, filters.chartType, filters.chartMirror]);

	useEffect(() => {
		if (!arraysAreEqual(filters.selectedCarsList, localSelectedCarList)) {
			setLocalCarList(filters.selectedCarsList);
		}
	}, [filters.selectedCarsList]);

	useEffect(() => {
		if (openPdfViewer) {
			captureChartAsImage();
		}
	}, [openPdfViewer]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={openPdfViewer || blockFilters}>
			<div style={{ opacity: openPdfViewer ? 0 : 1 }}>
				<Box sx={{ width: "100%", padding: "10px" }} ref={chartRef}>
					<div
						className='generalStyles__width100 generalStyles__textCenter'
						style={{
							fontSize: "20px",
							fontWeight: "bolder",
							backgroundColor: `${getChartBackgroundColor(chartReportTheme)}`,
							color: `${getChartTextColor(chartReportTheme)}`,
							paddingTop: "20px",
							paddingBottom: "5px",
						}}
					>
						<span>{topLabel}</span>
						{topLabelIcon === "UP" && (
							<FontAwesomeIcon icon={faUpLong} fontSize={23} className='generalStyles__mlFix generalStyles__success' />
						)}
						{topLabelIcon === "DOWN" && (
							<FontAwesomeIcon icon={faDownLong} fontSize={23} className='generalStyles__mlFix generalStyles__error' />
						)}
					</div>
					<Spin spinning={isLoading}>
						<div className='generalStyles__flex'>
							<div
								style={{
									fontSize: "20px",
									fontWeight: "bolder",
									backgroundColor: `${getChartBackgroundColor(chartReportTheme)}`,
									textAlign: "center",
									color: `${getChartTextColor(chartReportTheme)}`,
								}}
								className='generalStyles__flexCenter'
							>
								<span style={{ transform: "rotate(270deg)" }}>
									{filters.chartType === "bar_horizontal" || filters.chartType === "bar_stack_horizontal"
										? bottomLabel
										: leftLabel}
								</span>
							</div>
							<LineChart
								axisHighlight={{ x: "line", y: "line" }}
								slotProps={{
									legend: {
										direction: "row",
										position: { vertical: "top", horizontal: "middle" },
										labelStyle: {
											fontSize: 14,
											fill: `${getChartTextColor(chartReportTheme)}`,
										},
									},
									// Custom loading message
									loadingOverlay: { message: "Loading..." },
									// Custom message for empty chart
									noDataOverlay: { message: t(`${getCharNoDataMessage()}`) },
								}}
								sx={{
									[`.${axisClasses.root}`]: {
										[`.${axisClasses.tick}, .${axisClasses.line}`]: {
											stroke: `${getChartTicklineColor(chartReportTheme)}`,
											strokeWidth: 3,
										},
										[`.${axisClasses.tickLabel}`]: {
											fill: `${getChartTextColor(chartReportTheme)}`,
										},
									},
									[`.MuiChartsLegend-series	`]: {
										stroke: `${getChartAxisHighlight(chartReportTheme)}`,
									},
									[`.MuiChartsAxisHighlight-root `]: {
										stroke: `${getChartAxisHighlight(chartReportTheme)}`,
									},
									[`.css-1f57y8b `]: {
										fill: `${getChartTextColor(chartReportTheme)}`,
									},
									borderRadius: "7px",
									backgroundColor: `${getChartBackgroundColor(chartReportTheme)}`,
								}}
								series={seriesData}
								xAxis={[{ scaleType: "point", data: labels }]}
								height={450}
							/>
						</div>
						<div
							className='generalStyles__width100 generalStyles__textCenter'
							style={{
								fontSize: "20px",
								fontWeight: "bolder",
								backgroundColor: `${getChartBackgroundColor(chartReportTheme)}`,
								color: `${getChartTextColor(chartReportTheme)}`,
								paddingBottom: "10px",
								marginTop: "-5px",
							}}
						>
							<span>
								{filters.chartType === "bar_horizontal" || filters.chartType === "bar_stack_horizontal"
									? leftLabel
									: bottomLabel}
							</span>
							<br />
							<span className='generalStyles__info'>ChartLabel</span>
						</div>
					</Spin>
					{/** ******************************************* */}
					{/** DOWNLOAD REPORT */}
					<ReportsAlertDownloadModal setDownloadReport={setDownloadReport} />
				</Box>
			</div>
		</Spin>
	);
}

export default ChartLine;
