import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Button, Popover, notification } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleInfo, faWarning } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import {
	usePostMonitoringCommandMutation,
	usePostEmulatorCommandMutation,
} from "../../../redux/monitoring/monitoringAPI";
// AUTHENTICATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import ActionButtonPopover from "./ActionButtonPopover";
// INTERFACES
import { ActionButtonInterface } from "../../../interfaces/Actions.interface";
import { RootState } from "../../../app/store";

function ActionButtonToggle({ action, liftId, serverId, carService, layout, isControlPanel }: ActionButtonInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const TIMEOUT = 3;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [localCarService, setLocalCarService] = useState<string>("");
	const [lastCommandSent, setLastCommandSent] = useState<string>("ON");
	const [checkCommandChange1, setCheckCommandChange1] = useState<boolean>(false);
	const [checkCommandChange2, setCheckCommandChange2] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { liftSelected, socketConnection, layoutSelected } = useSelector((state: RootState) => state.monitoring);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		postCommand,
		{
			isLoading: isLoadingPostCommand,
			isSuccess: isSuccessPostCommand,
			isError: isErrorPostCommand,
			error: errorPostCommand,
			reset: resetPostCommand,
		},
	] = usePostMonitoringCommandMutation();
	const [
		postCommandEmulator,
		{
			isLoading: isLoadingPostEmulatorCommand,
			isSuccess: isSuccessPostEmulatorCommand,
			isError: isErrorPostEmulatorCommand,
			reset: resetPostEmulatorCommand,
		},
	] = usePostEmulatorCommandMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickCommand = async (command: string, cmdOff: boolean, type: string, message: string) => {
		const token = await GETJwtToken();
		const BODY = {
			token,
			body: {
				cmd_adapter: isControlPanel ? layoutSelected.typeChannel === "ADAPTER" : layout.typeChannel === "ADAPTER",
				cmd_off: cmdOff,
				lift_id: liftId,
				short_msg: command,
				type: type || "CONTROL",
				message,
			},
			serverId,
		};
		postCommand(BODY);
	};

	const onClickCommandEmulator = async (command: any) => {
		const token = await GETJwtToken();
		const BODY = {
			token,
			body: {
				lift_id: isControlPanel ? liftSelected.id : liftId,
				bank_id: isControlPanel ? layoutSelected.id : layout.id,
				bankName: isControlPanel ? layoutSelected.name : layout.name,
				liftName: liftSelected.name,
				type: "COMMAND",
				command,
			},
			serverId,
		};
		postCommandEmulator(BODY);
	};

	const onClickToggleOn = () => {
		setLastCommandSent("ON");
		if (socketConnection === "EMULATOR") {
			onClickCommandEmulator(action && action.action && action.action.cmd ? action.action.cmd : "");
		} else {
			onClickCommand(
				action && action.action && action.action.cmd ? action.action.cmd : "",
				false,
				action && action.action && action.action.type ? action.action.type : "",
				action && action.action && action.action.label ? action.action.label : ""
			);
		}
	};

	const onClickToggleOff = () => {
		setLastCommandSent("OFF");
		if (socketConnection === "EMULATOR") {
			onClickCommandEmulator(
				action && action.toggle_action && action.toggle_action.cmd ? action.toggle_action.cmd : ""
			);
		} else {
			onClickCommand(
				action && action.toggle_action && action.toggle_action.cmd ? action.toggle_action.cmd : "",
				true,
				action && action.toggle_action && action.toggle_action.type ? action.toggle_action.type : "",
				action && action.toggle_action && action.toggle_action.label ? action.toggle_action.label : ""
			);
		}
	};

	const launchMessage = (errorCommand: boolean) => {
		let errorMessage = "Error Command";
		if (errorCommand && errorPostCommand) {
			const copyError: any = structuredClone(errorPostCommand);
			errorMessage = copyError.data.details || copyError.data.message || "Error";
		}
		notification.open({
			message: (
				<h5 className={errorCommand ? "generalStyles__error" : "generalStyles__success"}>{action.action.label}</h5>
			),
			description: errorCommand ? (
				errorMessage
			) : (
				<span>
					{t("general.command")} {action.action.label} {t("general.sentSuccessfully")}
				</span>
			),
			placement: "top",
			className: `monitoring__notification__${theme}`,
			icon: (
				<FontAwesomeIcon
					icon={errorCommand ? faWarning : faCheckCircle}
					fontSize={18}
					className={`monitoring__modalSettings__icon ${
						errorCommand ? "generalStyles__error" : "generalStyles__success"
					}`}
				/>
			),
		});
	};

	const checkAndSendAlert = () => {
		setCheckCommandChange2(false);
		if (lastCommandSent === "ON" && action && action.action && action.action.valid_states.includes(localCarService)) {
			launchMessage(false);
		} else if (
			lastCommandSent === "OFF" &&
			action &&
			action.toggle_action &&
			action.toggle_action.valid_states.includes(localCarService)
		) {
			launchMessage(false);
		} else {
			launchMessage(true);
		}
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		resetPostCommand();
		resetPostEmulatorCommand();
		if (isSuccessPostEmulatorCommand || isSuccessPostCommand) {
			setTimeout(() => {
				setCheckCommandChange1(true);
				setTimeout(() => {
					setCheckCommandChange1(false);
				}, 500);
			}, 1000);
		}
		if (isErrorPostEmulatorCommand || isErrorPostCommand) {
			launchMessage(true);
		}
	}, [isSuccessPostEmulatorCommand, isErrorPostEmulatorCommand, isSuccessPostCommand, isErrorPostCommand]);

	useEffect(() => {
		setLocalCarService(carService);
	}, [carService]);

	useEffect(() => {
		if (checkCommandChange1) {
			if (lastCommandSent === "ON" && action && action.action && action.action.valid_states.includes(localCarService)) {
				checkAndSendAlert();
			} else if (
				lastCommandSent === "OFF" &&
				action &&
				action.toggle_action &&
				action.toggle_action.valid_states.includes(localCarService)
			) {
				checkAndSendAlert();
			} else {
				setTimeout(() => {
					setCheckCommandChange2(true);
				}, TIMEOUT * 1000);
			}
		}
	}, [checkCommandChange1]);

	useEffect(() => {
		if (checkCommandChange2) {
			checkAndSendAlert();
		}
	}, [checkCommandChange2]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__width100 generalStyles__flexCenter mb-4'>
			<div>
				<div className='generalStyles__flex'>
					<div style={{ width: "30px", paddingTop: "30px", marginLeft: "-15px" }}>
						<Popover
							content={
								<ActionButtonPopover
									action={action.action}
									toggleAction={action.toggle_action}
									timer={0}
									type='TOGGLE'
								/>
							}
							title={action && action.action && action.action.tooltip ? action.action.tooltip : "--"}
						>
							<FontAwesomeIcon icon={faCircleInfo} fontSize={18} className='generalStyles__info generalStyles__mrFix' />
						</Popover>
					</div>
					<Button
						className={
							action && action.action && action.action.valid_states.includes(localCarService)
								? "buttonStyle__18"
								: "buttonStyle__17"
						}
						disabled={action && action.action && action.action.valid_states.includes(localCarService)}
						loading={(isLoadingPostCommand || isLoadingPostEmulatorCommand) && lastCommandSent === "ON"}
						onClick={onClickToggleOn}
						style={{ width: "210px" }}
					>
						<span className='generalStyles__textEllipsis' style={{ maxWidth: "100px" }}>
							{action && action.action && action.action.label ? `${action.action.label}` : "--"}
						</span>{" "}
						<span className='generalStyles__textEllipsis' style={{ marginLeft: "5px" }}>
							ON
						</span>
					</Button>
				</div>
				<div className='generalStyles__flex' style={{ marginTop: "-13px" }}>
					<div style={{ width: "15px" }} />
					<Button
						className={
							action && action.toggle_action && action.toggle_action.valid_states.includes(localCarService)
								? "buttonStyle__18"
								: "buttonStyle__17"
						}
						disabled={action && action.toggle_action && action.toggle_action.valid_states.includes(localCarService)}
						loading={(isLoadingPostCommand || isLoadingPostEmulatorCommand) && lastCommandSent === "OFF"}
						onClick={onClickToggleOff}
						style={{ width: "210px" }}
					>
						<span className='generalStyles__textEllipsis' style={{ maxWidth: "100px" }}>
							{action && action.toggle_action && action.toggle_action.label ? `${action.toggle_action.label}` : "--"}
						</span>
						<span className='generalStyles__textEllipsis' style={{ marginLeft: "5px" }}>
							OFF
						</span>
					</Button>
				</div>
			</div>
		</div>
	);
}

export default ActionButtonToggle;
