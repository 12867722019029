import React from "react";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface TableMapViewMenuItemInterface {
	icon: any;
	text: string;
	onClick: () => void;
}

function TableMapViewMenuItem({ icon, text, onClick }: TableMapViewMenuItemInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div
			className='generalStyles__tableActionBox'
			role='button'
			tabIndex={0}
			onClick={() => {
				onClick();
			}}
			onKeyDown={() => {
				onClick();
			}}
		>
			<span>
				<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={icon} />
				{text}
			</span>
		</div>
	);
}

export default TableMapViewMenuItem;
