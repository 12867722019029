import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Tooltip, Empty, Select } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateLiftSelected,
	updateBankSelected,
	updateTerminalIdLiftSelected,
	updateTerminalResetTable,
	updateTerminalLastShortMessage,
} from "../../../redux/monitoring/monitoringSlice";
// COMPONENTS
import IconDefinitionIcons from "../../tools/iconDefinition/IconDefinitionIcons";
// INTERFACES
import {
	BankFloorInterface,
	LiftInterface,
	LayoutInterface,
	TerminalLiftsInterface,
	TerminalLiftInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function TerminalLift({ iconDefinitionElevator, layouts }: TerminalLiftInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const { Option } = Select;
	const dispatch = useDispatch();
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		car_calls: { front: [], rear: [], front_lock: [], rear_lock: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
			},
			down: {
				front: [],
				rear: [],
			},
			up_assigned: { front: [], rear: [] },
			down_assigned: { front: [], rear: [] },
		},
		faults: false,
		position: 0,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [lifts, setLifts] = useState<TerminalLiftsInterface[]>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { liftSelected, bankSelected, terminalLastShortMessage } = useSelector((state: RootState) => state.monitoring);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickResetTable = () => {
		dispatch(updateTerminalResetTable(true));
		dispatch(updateTerminalLastShortMessage(BLANK_SOCKET_MESSAGE));
		setTimeout(() => {
			dispatch(updateTerminalResetTable(false));
		}, 100);
	};

	const onChangeFilterLift = (newValue: string) => {
		if (newValue) {
			if (layouts) {
				// Save lift selected
				layouts.map((layout: LayoutInterface) => {
					if (layout && layout.lifts) {
						layout.lifts.map((lift: LiftInterface) => {
							if (lift && lift.id === newValue) {
								dispatch(updateLiftSelected(lift));
								dispatch(updateTerminalIdLiftSelected(lift.id));
							}
							return true;
						});
					}
					return true;
				});
			}
		} else {
			dispatch(
				updateLiftSelected({
					id: "",
					bank_id: "",
					control_panel: {},
					direction: "",
					name: "",
					position: 0,
					type: "",
					lift_floors: [],
				})
			);
			dispatch(
				updateBankSelected({
					id: "",
					active: false,
					board: 0,
					columns: 0,
					floor_below_lobby: 0,
					floor_count: 0,
					logical_port: 0,
					name: "",
					number_of_lifts: 0,
					channel_id: "",
					rear_doors: false,
					stops: 0,
					type: "",
					control_panel: {},
					bank_floors: [],
					lifts: [],
				})
			);
			onClickResetTable();
		}
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		const newArr: TerminalLiftsInterface[] = [];
		if (layouts) {
			layouts.map((layout: LayoutInterface) => {
				if (layout && layout.lifts) {
					layout.lifts.map((lift: LiftInterface) => {
						newArr.push({ id: lift.id, name: lift.name });
						return true;
					});
				}
				return true;
			});
		}
		setLifts(newArr);
	}, [layouts]);

	useEffect(() => {
		if (layouts) {
			layouts.map((layout: LayoutInterface) => {
				if (layout && layout.id === liftSelected.bank_id) {
					dispatch(updateBankSelected(layout));
				}
				return true;
			});
		}
	}, [liftSelected]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='terminal__lift__heightFix'>
			<div className={`terminal__container__${theme} terminal__container `}>
				{liftSelected && liftSelected.id ? (
					<>
						<br />
						<div className='terminal__lift__minifiedView '>
							{bankSelected && bankSelected.bank_floors && (
								<div>
									{bankSelected.bank_floors.map((floor: BankFloorInterface, floorIndex: number) => (
										<div className='generalStyles__flex' key={floorIndex}>
											{/** *********************************************************** */}
											{/** FLOORS */}
											<div
												style={{ width: "75px", marginLeft: "10px" }}
												className={`monitoring__card__floor generalStyles__flex  `}
											>
												<div
													className='generalStyles__warning'
													style={{
														width: "25px",
														textAlign: "center",
													}}
												>
													<Tooltip placement='topLeft' title='Floor Index'>
														{floor && floor.floor_index >= 0 ? floor.floor_index : "--"}
													</Tooltip>
												</div>
												<div
													style={{
														width: "25px",
														textAlign: "center",
													}}
												>
													<Tooltip placement='topLeft' title='Floor No'>
														<span>{floor && floor.floor_no >= 0 ? floor.floor_no : "--"}</span>
													</Tooltip>
												</div>
												<div
													style={{
														width: "25px",
														textAlign: "center",
													}}
													className='generalStyles__info'
												>
													<Tooltip placement='topLeft' title='Floor Name'>
														<span>{floor && floor.name ? floor.name.toUpperCase().substring(0, 2) : "--"}</span>
													</Tooltip>
												</div>
											</div>
											{/** *********************************************************** */}
											{/** DOTS AND ELEVATOR CONTAINER */}
											<div
												style={{
													width: "100px",
													position: "relative",
													marginLeft: "10px",
												}}
											>
												{/** LIFTS */}
												{bankSelected && bankSelected.lifts && (
													<div className='generalStyles__spaceEvenly monitoring__elevatorFullView__liftContainer'>
														<div>
															<div
																className={` monitoring__card__liftContainer__${theme} ${
																	floorIndex === 0 ? "monitoring__card__liftContainerBorderTop " : ""
																} ${
																	floorIndex === bankSelected.bank_floors.length - 1
																		? "monitoring__card__liftContainerBorderBottom"
																		: ""
																}`}
															>
																{terminalLastShortMessage &&
																	terminalLastShortMessage.icon &&
																	terminalLastShortMessage.position === floor.floor_no && (
																		<IconDefinitionIcons
																			isMap={false}
																			expanded={false}
																			liftType='ELEVATOR'
																			icon={iconDefinitionElevator[terminalLastShortMessage.icon.name.toLowerCase()]}
																			iconSize={20}
																		/>
																	)}
																{/** HALL CALLS UP */}
																{(terminalLastShortMessage.hall_calls.up_assigned.front.includes(floor.floor_no) ||
																	terminalLastShortMessage.hall_calls.up_assigned.rear.includes(floor.floor_no)) &&
																	floor.floor_no !== terminalLastShortMessage.position && (
																		<IconDefinitionIcons
																			isMap={false}
																			expanded={false}
																			liftType='ELEVATOR'
																			icon='elGoalGreen'
																			iconSize={20}
																		/>
																	)}
																{/** HALL CALLS DOWN */}
																{(terminalLastShortMessage.hall_calls.down_assigned.front.includes(floor.floor_no) ||
																	terminalLastShortMessage.hall_calls.down_assigned.rear.includes(floor.floor_no)) &&
																	floor.floor_no !== terminalLastShortMessage.position && (
																		<IconDefinitionIcons
																			isMap={false}
																			expanded={false}
																			liftType='ELEVATOR'
																			icon='elGoalRed'
																			iconSize={20}
																		/>
																	)}
																{/** FRONT CAR CALLS */}
																{terminalLastShortMessage &&
																	terminalLastShortMessage.car_calls &&
																	terminalLastShortMessage.car_calls.front &&
																	terminalLastShortMessage.car_calls.front.includes(floor.floor_no) &&
																	floor.floor_no !== terminalLastShortMessage.position && (
																		<IconDefinitionIcons
																			isMap={false}
																			expanded={false}
																			liftType='ELEVATOR'
																			icon='elGoal'
																			iconSize={20}
																		/>
																	)}
																{/** REAR CAR CALLS */}
																{terminalLastShortMessage &&
																	terminalLastShortMessage.car_calls &&
																	terminalLastShortMessage.car_calls.rear &&
																	terminalLastShortMessage.car_calls.rear.includes(floor.floor_no) &&
																	floor.floor_no !== terminalLastShortMessage.position && (
																		<IconDefinitionIcons
																			isMap={false}
																			expanded={false}
																			liftType='ELEVATOR'
																			icon='elGoal'
																			iconSize={20}
																		/>
																	)}
															</div>
														</div>
													</div>
												)}
												{/** DOTS STYLE */}
												<div className='monitoring__elevatorFullView__dotsStyleContainer'>
													<div className={`monitoring__card__dotStyle__${theme}__inactive`} />
												</div>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
						<br />
					</>
				) : (
					<div className='terminal__jsonViewer__emptyFixTop'>
						<Empty />
					</div>
				)}
				<div className='generalStyles__flexCenter'>
					<Select
						className='generalStyles__width100 generalStyles__textCenter'
						showSearch
						optionFilterProp='children'
						allowClear={false}
						value={liftSelected.id !== "" ? liftSelected.id : null}
						placeholder={t("general.noLiftSelected")}
						onChange={onChangeFilterLift}
					>
						{lifts.map((item: TerminalLiftsInterface) => (
							<Option key={item.id} value={item.id}>
								{item.name}
							</Option>
						))}
					</Select>
				</div>
			</div>
		</div>
	);
}

export default TerminalLift;
