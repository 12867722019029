import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Button, Tooltip, TimePicker, Switch, Spin, Empty } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// MOMENT DATE FORMAT
import moment from "moment";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faAdd, faCalendarDays, faTrash } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerManageSchedules } from "../../../redux/notifications/notificationSlice";
import { usePutUserMutation } from "../../../redux/notifications/notificationAPI";
// COMPONENTS
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
import DrawerCloseSaveButton from "../../components/drawer/DrawerCloseSaveButton";
import DrawerUserInformation from "../../components/drawer/DrawerUserInformation";
import GlobalAlert2 from "../../home/GlobalAlert2";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { RootState } from "../../../app/store";
import { DaysOfWeekInterface, ScheduleInterface } from "../../../interfaces/Notification.interface";

function NotificationUsersSchedulesDrawer() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [days, setDays] = useState<DaysOfWeekInterface[]>([
		{ day: "monday", day_number: 1, active: true },
		{ day: "tuesday", day_number: 2, active: true },
		{ day: "wednesday", day_number: 3, active: true },
		{ day: "thursday", day_number: 4, active: true },
		{ day: "friday", day_number: 5, active: true },
		{ day: "saturday", day_number: 6, active: true },
		{ day: "sunday", day_number: 7, active: true },
	]);
	const [exclusionRangeTime, setExclusionRangeTime] = useState<string[]>(["00:00", "23:00"]);
	const [schedules, setSchedules] = useState<ScheduleInterface[]>([]);
	const [showIsLoading, setShowIsLoading] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openDrawerManageSchedules, user } = useSelector((state: RootState) => state.notification);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerPutUser, { isLoading, isSuccess, isError, error }] = usePutUserMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickShowIsLoading = () => {
		setShowIsLoading(true);
		setTimeout(() => {
			setShowIsLoading(false);
		}, 1000);
	};

	const onClickSave = async () => {
		// Save favorite theme
		const token = await GETJwtToken();
		const BODY = {
			id: user.id,
			token,
			body: { ...user, schedule: { availability: schedules, exceptions: [] } },
		};
		triggerPutUser(BODY);
	};

	const onClickClose = () => {
		dispatch(updateOpenDrawerManageSchedules(false));
		setSchedules([]);
		setDays([
			{ day: "sunday", day_number: 0, active: true },
			{ day: "monday", day_number: 1, active: true },
			{ day: "tuesday", day_number: 2, active: true },
			{ day: "wednesday", day_number: 3, active: true },
			{ day: "thursday", day_number: 4, active: true },
			{ day: "friday", day_number: 5, active: true },
			{ day: "saturday", day_number: 6, active: true },
		]);
		setExclusionRangeTime(["00:00", "23:00"]);
	};

	const onChangeExclusionTimeRange = (time: any) => {
		// Handle the selected time range
		if (time) {
			const startHour = time[0].hour(); // Get the selected hour for the start
			const endHour = time[1].hour(); // Get the selected hour for the end
			setExclusionRangeTime([
				`${startHour.toString().padStart(2, "0")}:00`,
				`${endHour.toString().padStart(2, "0")}:00`,
			]);
		}
	};

	const onChangeDays = (newValue: boolean, day: string) => {
		const copy = structuredClone(days);
		const index = days.findIndex((object: DaysOfWeekInterface) => object.day === day);
		if (index >= 0) {
			copy[index].active = newValue;
		}
		setDays(copy);
	};

	const addNewSchedule = () => {
		onClickShowIsLoading();
		const copy: ScheduleInterface[] = structuredClone(schedules);
		days.map((day: DaysOfWeekInterface) => {
			if (day.active) {
				copy.push({
					week_day: day.day_number,
					start: parseInt(exclusionRangeTime[0].substring(0, 2), 10),
					end: parseInt(exclusionRangeTime[1].substring(0, 2), 10),
				});
			}
			return true;
		});
		const sortedSchedule = copy.sort((a: ScheduleInterface, b: ScheduleInterface) => a.week_day - b.week_day);
		setSchedules(sortedSchedule);
		setExclusionRangeTime(["00:00", "23:00"]);
	};

	const removeSchedule = (index: number) => {
		onClickShowIsLoading();
		const copy = structuredClone(schedules);
		copy.splice(index, 1);

		setSchedules(copy);
	};

	const buildPreviousSchedules = (availabilities: ScheduleInterface[]) => {
		const copy = structuredClone(schedules);
		availabilities.map((availability: ScheduleInterface) => {
			copy.push(availability);
			return true;
		});
		const sortedSchedule = copy.sort((a: ScheduleInterface, b: ScheduleInterface) => a.week_day - b.week_day);
		setSchedules(sortedSchedule);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (user && user.schedule && user.schedule.availability) {
			buildPreviousSchedules(user.schedule.availability);
		}
	}, [user]);

	useEffect(() => {
		onClickClose();
	}, [isError, isSuccess]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer width='550' placement='right' onClose={onClickClose} closable={false} open={openDrawerManageSchedules}>
			<Spin spinning={isLoading || showIsLoading}>
				{/** USER INFORMATION */}
				<DrawerUserInformation data={user || {}} />
				<br />
				{/** CREATE NEW SCHEDULE */}
				<div>
					<DrawerRowDivider icon={faClock} title={t("general.createNewSchedule")} />
				</div>
				<div className={`drawer__box__${theme}`}>
					{days.map((day: DaysOfWeekInterface) => (
						<div className='generalStyles__flex' key={day.day}>
							<div>
								<p className='drawer__title'>{t(`general.${day.day}`)}</p>
							</div>
							<div className='drawer__textContainer' style={{ width: "230px" }}>
								<div className='generalStyles__flex'>
									<Switch
										checked={day.active}
										onChange={(checked: boolean) => {
											onChangeDays(checked, day.day);
										}}
									/>
								</div>
							</div>
						</div>
					))}
					{/** LIST SCHEDULES */}
					<div className='generalStyles__flex mt-2'>
						<div>
							<p className='drawer__title'>{t("general.schedule")}</p>
						</div>
						<div className='drawer__textContainer generalStyles__width100'>
							<div className='generalStyles__flex'>
								<TimePicker.RangePicker
									format='HH:mm'
									className='generalStyles__width100'
									onChange={onChangeExclusionTimeRange}
									value={[moment(exclusionRangeTime[0], "HH"), moment(exclusionRangeTime[1], "HH")]}
									allowClear={false}
									showNow={false}
									minuteStep={60}
								/>
								<Tooltip title='Add new exclusion time range' placement='topLeft'>
									<Button
										type='primary'
										shape='circle'
										className='generalStyles__mlFix'
										icon={<FontAwesomeIcon onClick={addNewSchedule} icon={faAdd} />}
									/>
								</Tooltip>
							</div>
						</div>
					</div>
				</div>
				<br />
				<div>
					<DrawerRowDivider icon={faCalendarDays} title={t("general.schedules")} />
				</div>
				<div className={`drawer__box__${theme}`}>
					{schedules.length > 0 ? (
						<>
							{schedules.map((schedule: ScheduleInterface, index: number) => (
								<div className='generalStyles__flex'>
									<div
										style={{
											width: "200px",
											paddingTop: "8px",
										}}
									>
										<p className='drawer__title generalStyles__info'>{t(`general.${days[schedule.week_day].day}`)}</p>
									</div>
									<div
										style={{
											width: "200px",
											paddingTop: "8px",
										}}
									>
										<p className='drawer__title'>
											{schedule.start}hr To {schedule.end}hr
										</p>
									</div>
									<div>
										<Button
											className='buttonStyle__9'
											shape='circle'
											icon={
												<FontAwesomeIcon
													onClick={() => {
														removeSchedule(index);
													}}
													icon={faTrash}
												/>
											}
										/>
									</div>
								</div>
							))}
						</>
					) : (
						<Empty description={t("general.noSchedulesAssigned")} />
					)}
				</div>
				{/** ---------------------------------------------------- */}
				{/** COMPONENT CLOSE SAVE BUTTON */}
				<DrawerCloseSaveButton
					onClickClose={onClickClose}
					onClickSave={onClickSave}
					disableSaveButton={schedules.length === 0}
				/>
				<br />
			</Spin>
			<GlobalAlert2
				isError={isError}
				isSuccess={isSuccess}
				requestType='PUT'
				error={error}
				name={t("general.schedule")}
			/>
		</Drawer>
	);
}

export default NotificationUsersSchedulesDrawer;
