import React from "react";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateOpenModalCreateNewRole,
	updateRoleRefreshTable,
} from "../../../redux/roles/roleSlice";
// COMPONENTS
import ButtonRefreshTable from "../../components/buttons/ButtonRefreshTable";
import ButtonCreateItem from "../../components/buttons/ButtonCreateItem";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
// INTERFACES
import { RootState } from "../../../app/store";

function RolesHeader() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { permissions } = useSelector((state: RootState) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalCreateNewRole = () => {
		dispatch(updateOpenModalCreateNewRole(true));
	};

	const onClickReloadPage = () => {
		dispatch(updateRoleRefreshTable(true));
		setTimeout(() => {
			dispatch(updateRoleRefreshTable(false));
		}, 1000);
	};

	return (
		<div className='generalStyles__pageHeader'>
			{validatePermission("roles-create", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<ButtonCreateItem
						onClick={handleOpenModalCreateNewRole}
						label={t("organizationManagement.createNewRole")}
					/>
				</div>
			)}
			{validatePermission("roles-view", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<ButtonRefreshTable
						onClick={onClickReloadPage}
						label={t("organizationManagement.refreshTable")}
					/>
				</div>
			)}
		</div>
	);
}

export default RolesHeader;
