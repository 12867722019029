import React, { useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// COPY TO CLIBBOARD
import { CopyToClipboard } from "react-copy-to-clipboard";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faShield } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenModalPsw } from "../../../redux/users/userSlice";

function UsersModalPsw({ userPsw }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	// eslint-disable-next-line no-unused-vars
	const [copied, setCopied] = useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openModalPsw } = useSelector((state: any) => state.user);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleCloseModal = () => {
		dispatch(updateOpenModalPsw(false));
	};

	const onCopy = () => {
		setCopied(true);
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal onOk={handleCloseModal} closable={false} onCancel={handleCloseModal} open={openModalPsw} footer={null}>
			<div>
				<div className='text-center'>
					<FontAwesomeIcon icon={faShield} className='generalStyles__warning generalStyles__deleteItemIcon' />
					<p className='generalStyles__info'>{t("general.offlinePassword")}</p>
					<div>
						<CopyToClipboard onCopy={onCopy} text={userPsw}>
							<Tooltip title={t("general.copyToClipboard")}>
								<Button
									className='buttonStyle__4'
									icon={<FontAwesomeIcon icon={faCopy} className='generalStyles__warning generalStyles__mrFix' />}
								>
									{userPsw}
								</Button>
							</Tooltip>
						</CopyToClipboard>
					</div>
					<div className='generalStyles__flexEnd mt-4'>
						<div>
							<Button onClick={handleCloseModal} className='buttonStyle__4'>
								{t("general.close")}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default UsersModalPsw;
