import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Spin } from "antd";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useDeleteMapViewMutation } from "../../../redux/mapView/mapViewAPI";
import {
  updateOpenModalDelete,
  updateMapViewItem,
} from "../../../redux/mapView/mapViewSlice";
// COMPONENTS
import ModalDeleteItemContainer from "../../components/modal/ModalDeleteItemContainer";
import GlobalAlert2 from "../../home/GlobalAlert2";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { RootState } from "../../../app/store";

function MapViewDeleteModal() {
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();

  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { mapViewItem, openModalDelete } = useSelector(
    (state: RootState) => state.mapView
  );

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    triggerDeleteMapView,
    { isLoading, isSuccess, isError, error, reset: resetDeleteMapView },
  ] = useDeleteMapViewMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClickClose = () => {
    dispatch(updateOpenModalDelete(false));
  };

  const onClickDelete = async () => {
    const token = await GETJwtToken();
    triggerDeleteMapView({ id: mapViewItem?.id, token });
    dispatch(
      updateMapViewItem({
        id: "",
        name: "",
        file: "",
        setup: "{}",
        org_id: "",
        start: false,
      })
    );
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalDelete) {
      resetDeleteMapView();
    }
  }, [openModalDelete]);

  useEffect(() => {
    if (isSuccess || isError) {
      dispatch(updateOpenModalDelete(false));
    }
  }, [isSuccess, isError]);
  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      onOk={onClickClose}
      closable={false}
      onCancel={onClickClose}
      open={openModalDelete}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <ModalDeleteItemContainer
          name={mapViewItem?.name}
          onClickDelete={onClickDelete}
          onClickClose={onClickClose}
        />
      </Spin>
      {/** ---------------------------------------------------- */}
      {/** GLOBAL ALERT */}
      <GlobalAlert2
        isError={isError}
        isSuccess={isSuccess}
        requestType="DELETE"
        error={error}
        name="MapView"
      />
    </Modal>
  );
}

export default MapViewDeleteModal;
