import React, { useEffect, useState } from "react";
// NAVIGATION
import { useNavigate } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Table, Tag, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import { useLazyGetBanksQuery } from "../../../redux/notifications/notificationAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
// FILTERS
import FilterSearchText from "../../components/filters/FilterSearchText";
// INTERFACES
import { RootState } from "../../../app/store";
import { BankInterface } from "../../../interfaces/Bank.interface";

function NotificationBanksTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const { Column } = Table;
	const navigate = useNavigate();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [banks, setBanks] = useState<BankInterface[]>([]);
	const [total, setTotal] = useState(0);
	const [responseError, setResponseError] = useState<any>();
	const [filterName, setFilterName] = useState("");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetBanks, { data, isLoading, isError, isFetching, error }] = useLazyGetBanksQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getData = async (clearFilters: boolean) => {
		const token = await GETJwtToken();
		const searchAndFilterString =
			filterName && filterName !== "" && !clearFilters
				? `?filter_target=name&filter=${filterName}&page=1&limit=99999`
				: "?page=1&limit=99999";
		triggerGetBanks({
			token,
			searchAndFilterString,
		});
	};

	const onClickCell = (record: BankInterface) => {
		if (record) {
			navigate({
				pathname: "/notification-management/notifications",
				search: `?idBank=${record.id}&idOrganization=${
					record && record.channel && record.channel.server && record.channel.server.org && record.channel.server.org.id
						? record.channel.server.org.id
						: "--"
				}`,
			});
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getData(true);
	}, []);

	useEffect(() => {
		if (data) {
			if (data && data.data) {
				setBanks(data.data);
			}
			if (data && data.total) {
				setTotal(data.total);
			} else {
				setTotal(0);
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			setResponseError(error);
		}
	}, [error]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='generalStyles__mlFix mt-1'>
				<Button
					className='buttonStyle__8'
					icon={<FontAwesomeIcon icon={faArrowsRotate} className='generalStyles__mrFix' />}
					onClick={() => {
						getData(true);
					}}
				>
					{t("edgeManagement.banks.refreshTable")}
				</Button>
			</div>
			<Table
				locale={{
					emptyText: (
						<TableNoDataBox
							isError={isError}
							errorMessage={
								responseError && responseError.data && responseError.data.message ? responseError.data.message : "Error"
							}
							noItemsFound='No data found'
						/>
					),
				}}
				loading={isLoading || isFetching}
				rowClassName={(record, index) => getTableRowClass(index, theme)}
				dataSource={banks}
				size='small'
				className='mt-3'
				pagination={{
					showSizeChanger: true,
					pageSizeOptions: ["10", "20", "50", "100"],
				}}
				rowKey={(record) => record.id}
				scroll={{ x: 900 }}
				footer={() => (
					<div className='generalStyles__flexEnd'>
						<Tag>{total} Records</Tag>
					</div>
				)}
			>
				{/** NAME */}
				<Column
					onCell={(record: BankInterface) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					className='generalStyles__hoverCursor'
					title={t("general.name")}
					dataIndex='name'
					key='name'
					filterIcon={<FontAwesomeIcon icon={faSearch} />}
					filterDropdown={() => (
						<FilterSearchText
							placeHolder={t("general.name")}
							filterValue={filterName}
							setFilterValue={setFilterName}
							refreshTable={() => {
								getData(false);
							}}
							refreshRequireParamenters
						/>
					)}
					sorter={(a: BankInterface, b: BankInterface) => a.name.localeCompare(b.name)}
					render={(text: string) => <div>{text}</div>}
				/>
				{/** ORGANIZATION */}
				<Column
					onCell={(record: BankInterface) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					className='generalStyles__hoverCursor'
					title={t("general.organization")}
					dataIndex='organization'
					key='organization'
					render={(text, record) => (
						<div>
							{record &&
							record.channel &&
							record.channel.server &&
							record.channel.server.org &&
							record.channel.server.org.name
								? record.channel.server.org.name
								: "--"}
						</div>
					)}
				/>
				{/** TYPE -- */}
				<Column
					onCell={(record: BankInterface) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					className='generalStyles__hoverCursor'
					title={t("edgeManagement.lifts.type")}
					dataIndex='type'
					key='type'
					width='100px'
					render={(text) => (
						<>
							<div>
								{text === "ELEVATOR" && (
									<Tag color='blue' className='drawer__typeTag'>
										{text}
									</Tag>
								)}
								{text === "ESCALATOR" && (
									<Tag color='volcano' className='drawer__typeTag'>
										{text}
									</Tag>
								)}
								{text === "MOVINGWALK" && (
									<Tag color='green' className='drawer__typeTag'>
										{text}
									</Tag>
								)}
								{text === "BOT" && (
									<Tag color='magenta' className='drawer__typeTag'>
										{text}
									</Tag>
								)}
								{text === "GPIO" && (
									<Tag color='cyan' className='drawer__typeTag'>
										{text}
									</Tag>
								)}
							</div>
						</>
					)}
				/>
			</Table>
		</div>
	);
}

export default NotificationBanksTable;
