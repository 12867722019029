import React from "react";
// ANT DESIGN COMPONENTS
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import NotificationsTable from "./NotificationsTable";
// INTERFACES
import { RootState } from "../../../app/store";

function NotificationsPage() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className={`layout__contentPage__${theme}`}>
				<NotificationsTable />
			</div>
		</div>
	);
}

export default NotificationsPage;
