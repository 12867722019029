import React, { useEffect, useState } from "react";
// REDUX
import { useSelector } from "react-redux";
// ICONS DARK DOWN SEQUENCE
import downDark1 from "../../../assets/icons/escalator/es-dark-down-1.svg";
import downDark2 from "../../../assets/icons/escalator/es-dark-down-2.svg";
import downDark3 from "../../../assets/icons/escalator/es-dark-down-3.svg";
import downDark4 from "../../../assets/icons/escalator/es-dark-down-4.svg";
import downDark5 from "../../../assets/icons/escalator/es-dark-down-5.svg";
import downDark6 from "../../../assets/icons/escalator/es-dark-down-6.svg";
// ICONS DARK UP SEQUENCE
import upDark1 from "../../../assets/icons/escalator/es-dark-up-1.svg";
import upDark2 from "../../../assets/icons/escalator/es-dark-up-2.svg";
import upDark3 from "../../../assets/icons/escalator/es-dark-up-3.svg";
import upDark4 from "../../../assets/icons/escalator/es-dark-up-4.svg";
import upDark5 from "../../../assets/icons/escalator/es-dark-up-5.svg";
import upDark6 from "../../../assets/icons/escalator/es-dark-up-6.svg";
// ICONS LIGHT DOWN SEQUENCE
import downLight1 from "../../../assets/icons/escalator/es-light-down-1.svg";
import downLight2 from "../../../assets/icons/escalator/es-light-down-2.svg";
import downLight3 from "../../../assets/icons/escalator/es-light-down-3.svg";
import downLight4 from "../../../assets/icons/escalator/es-light-down-4.svg";
import downLight5 from "../../../assets/icons/escalator/es-light-down-5.svg";
import downLight6 from "../../../assets/icons/escalator/es-light-down-6.svg";
// ICONS LIGHT UP SEQUENCE
import upLight1 from "../../../assets/icons/escalator/es-light-up-1.svg";
import upLight2 from "../../../assets/icons/escalator/es-light-up-2.svg";
import upLight3 from "../../../assets/icons/escalator/es-light-up-3.svg";
import upLight4 from "../../../assets/icons/escalator/es-light-up-4.svg";
import upLight5 from "../../../assets/icons/escalator/es-light-up-5.svg";
import upLight6 from "../../../assets/icons/escalator/es-light-up-6.svg";
// ICONS STATUS
import iconLockedDark from "../../../assets/icons/escalator/es-dark-locked.svg";
import iconLockedLight from "../../../assets/icons/escalator/es-light-locked.svg";
import darkStoped from "../../../assets/icons/escalator/es-dark-stopped.svg";
import iconLightStoped from "../../../assets/icons/escalator/es-light-stopped.svg";
// INTERFACES
import { MonitoringIconsEscalatorsInterface } from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function MonitoringIconsEscalators({
  lift,
  shortMessage,
  iconDefinitionEscalator,
  iconSize = 40,
  mapViewClass = "",
}: MonitoringIconsEscalatorsInterface) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const { theme } = useSelector((state: RootState) => state.home);

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [intervalIndex, setIntervalIndex] = useState(1);
  const [liftDirection, setLiftDirection] = useState("STOPED");
  const [liftStatus, setLiftStatus] = useState("STOPED");
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    setTimeout(() => {
      if (intervalIndex === 6) {
        setIntervalIndex(1);
      } else {
        setIntervalIndex(intervalIndex + 1);
      }
    }, 700);
  }, [intervalIndex]);

  useEffect(() => {
    if (shortMessage && shortMessage !== "") {
      if (iconDefinitionEscalator) {
        const newShortMessage = shortMessage.toLowerCase();
        if (iconDefinitionEscalator[newShortMessage] === "esUp") {
          setLiftDirection("UP");
        } else if (iconDefinitionEscalator[newShortMessage] === "esDown") {
          setLiftDirection("DOWN");
        } else if (iconDefinitionEscalator[newShortMessage] === "esStopped") {
          setLiftStatus("STOPED");
        } else if (newShortMessage === "run") {
          setLiftStatus("RUN");
        }
      } else if (shortMessage === "esUp") {
        setLiftDirection("UP");
      } else if (shortMessage === "esDown") {
        setLiftDirection("DOWN");
      } else if (shortMessage === "esStopped") {
        setLiftStatus("STOPED");
      } else if (shortMessage === "esRun") {
        setLiftStatus("RUN");
      }
    }
  }, [shortMessage]);

  useEffect(() => {
    if (lift && lift.direction) {
      const direction = lift.direction.toUpperCase();
      setLiftDirection(direction);
    }
  }, [lift]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div
      className={`${
        mapViewClass || "monitoringView__controlPanelActionIcon__escalator"
      }`}
    >
      {lift && liftDirection && liftStatus ? (
        <>
          {/** **************************************************************** */}
          {/** STOPED DARK / BLUE  */}
          {liftStatus === "STOPED" &&
            (theme === "dark" || theme === "blue") && (
              <div style={{ width: "fit-content", height: "fit-content" }}>
                <img
                  src={darkStoped}
                  alt=""
                  height={iconSize}
                  width={iconSize}
                />
              </div>
            )}
          {/** STOPED LIGHT */}
          {liftStatus === "STOPED" && theme === "light" && (
            <div style={{ width: "fit-content", height: "fit-content" }}>
              <img
                src={iconLightStoped}
                alt=""
                height={iconSize}
                width={iconSize}
              />
            </div>
          )}
          {/** DOWN DARK / BLUE SEQUENCE */}
          {liftStatus === "RUN" &&
            liftDirection === "DOWN" &&
            (theme === "dark" || theme === "blue") && (
              <div style={{ width: "fit-content", height: "fit-content" }}>
                {intervalIndex === 1 && (
                  <img
                    src={downDark1}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 2 && (
                  <img
                    src={downDark2}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 3 && (
                  <img
                    src={downDark3}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 4 && (
                  <img
                    src={downDark4}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 5 && (
                  <img
                    src={downDark5}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 6 && (
                  <img
                    src={downDark6}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
              </div>
            )}
          {/** UP DARK / BLUE SEQUENCE */}
          {liftStatus === "RUN" &&
            liftDirection === "UP" &&
            (theme === "dark" || theme === "blue") && (
              <div style={{ width: "fit-content", height: "fit-content" }}>
                {intervalIndex === 1 && (
                  <img
                    src={upDark1}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 2 && (
                  <img
                    src={upDark2}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 3 && (
                  <img
                    src={upDark3}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 4 && (
                  <img
                    src={upDark4}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 5 && (
                  <img
                    src={upDark5}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 6 && (
                  <img
                    src={upDark6}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
              </div>
            )}
          {/** DOWN LIGHT SEQUENCE */}
          {liftStatus === "RUN" &&
            liftDirection === "DOWN" &&
            theme === "light" && (
              <div style={{ width: "fit-content", height: "fit-content" }}>
                {intervalIndex === 1 && (
                  <img
                    src={downLight1}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 2 && (
                  <img
                    src={downLight2}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 3 && (
                  <img
                    src={downLight3}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 4 && (
                  <img
                    src={downLight4}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 5 && (
                  <img
                    src={downLight5}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 6 && (
                  <img
                    src={downLight6}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
              </div>
            )}
          {/** UP LIGHT SEQUENCE */}
          {liftStatus === "RUN" &&
            liftDirection === "UP" &&
            theme === "light" && (
              <div style={{ width: "fit-content", height: "fit-content" }}>
                {intervalIndex === 1 && (
                  <img
                    src={upLight1}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 2 && (
                  <img
                    src={upLight2}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 3 && (
                  <img
                    src={upLight3}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 4 && (
                  <img
                    src={upLight4}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 5 && (
                  <img
                    src={upLight5}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
                {intervalIndex === 6 && (
                  <img
                    src={upLight6}
                    alt=""
                    height={iconSize}
                    width={iconSize}
                  />
                )}
              </div>
            )}
        </>
      ) : (
        <>
          {/** **************************************************************** */}

          {/** NOT ALLOWED LIGHT */}
          {theme === "light" && (
            <div style={{ width: "fit-content", height: "fit-content" }}>
              <img src={iconLockedLight} alt="" />
            </div>
          )}
          {/** NOT ALLOWED LIGHT */}
          {(theme === "dark" || theme === "blue") && (
            <div style={{ width: "fit-content", height: "fit-content" }}>
              <img src={iconLockedDark} alt="" />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default MonitoringIconsEscalators;
