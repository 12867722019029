import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import AdaptersHeader from "./AdaptersHeader";
import AdaptersCreateEditForm from "./AdaptersCreateEditForm";
import AdaptersDeleteModal from "./AdaptersDeleteModal";
import AdaptersDrawer from "./AdaptersDrawer";
import AdaptersTable from "./AdaptersTable";
import AdaptersAssignActions from "./AdaptersAssignActions";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";
import KeysPage from "./keys/KeysPage";

function AdaptersPage() {
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: RootState) => state.home);
	const { keysAdapter } = useSelector((state: RootState) => state.adapter);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{
				// eslint-disable-next-line no-nested-ternary
				validatePermission("view-edge-management-adapters", permissions) ? (
					keysAdapter.id ? (
						<KeysPage />
					) : (
						<div className={`layout__contentPage__${theme}`}>
							<AdaptersHeader />
							<AdaptersTable />
							<AdaptersCreateEditForm />
							<AdaptersDeleteModal />
							<AdaptersDrawer />
							<AdaptersAssignActions />
						</div>
					)
				) : (
					<UnauthorizedPage />
				)
			}
		</div>
	);
}

export default AdaptersPage;
