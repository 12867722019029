import React from "react";
// COMPONENTS
import MonitoringLiftTopView from "./MonitoringLiftTopView";
// INTERFACES
import {
  MonitoringTopViewInterface,
  LiftInterface,
} from "../../../interfaces/EdgeManagement.interface";

function MonitoringTopView({
  socketMessage,
  layout,
  iconDefinitionElevator,
  iconDefinitionEscalator,
  iconDefinitionMovingwalk,
}: MonitoringTopViewInterface) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {layout && layout.lifts && (
        <div>
          <div
            className={` generalStyles__flex`}
            style={{ justifyContent: "center" }}
          >
            {layout.lifts.map((lift: LiftInterface) => (
              <div>
                <MonitoringLiftTopView
                  key={lift.id}
                  lift={lift}
                  layout={layout}
                  socketMessage={socketMessage}
                  iconDefinitionElevator={iconDefinitionElevator}
                  iconDefinitionEscalator={iconDefinitionEscalator}
                  iconDefinitionMovingwalk={iconDefinitionMovingwalk}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default MonitoringTopView;
