import React from "react";
// ANT DESIGN COMPONENTS
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import NotificationUsersTable from "./NotificationUsersTable";
import NotificationUsersSchedulesDrawer from "./NotificationUsersSchedulesDrawer";
// INTERFACES
import { RootState } from "../../../app/store";

function NotificationUsersPage() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`layout__contentPage__${theme}`}>
			<NotificationUsersTable />
			<NotificationUsersSchedulesDrawer />
		</div>
	);
}

export default NotificationUsersPage;
