import React from "react";
// ANT DESIGN COMPONENTS
import { Divider } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// INTERFACES
import { DrawerRowDividerInterface } from "../../../interfaces/Global.interface";

function DrawerRowDivider({ icon, title }: DrawerRowDividerInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Divider orientation='left' className='generalStyles__drawerDivider'>
				<h5>
					<FontAwesomeIcon icon={icon} className='generalStyles__info generalStyles__mrFix' />
					{title}
				</h5>
			</Divider>
		</div>
	);
}

export default DrawerRowDivider;
