import React from "react";
// ANT DESIGN COMPONENT
import type { RadioChangeEvent } from "antd";
import { Radio, Space } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRARY AND ICONS
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateLanguage } from "../../redux/home/homeSlice";
// STYLES
import usaFlag from "../../assets/i18n/unitedStatesFlag.png";
import spainFlag from "../../assets/i18n/spainFlag.png";
// COMPONENTS
import DrawerRowDivider from "../components/drawer/DrawerRowDivider";

function Internationalization() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// FUNCTIONS ************************************** */
	const onChangeLanguage = (e: RadioChangeEvent) => {
		i18n.changeLanguage(e.target.value);
		dispatch(updateLanguage(e.target.value));
	};

	// ************************************************* */
	// COMPONENT ************************************** */
	return (
		<div>
			<br />
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faLanguage} title={t("profile.language.applicationLanguage")} />
			{/** ************************************************* */}
			<div className={`drawer__box__${theme}`}>
				{/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("profile.language.language")}:</p>
					</div>
					<div className='drawer__textContainer'>
						<div className='drawer__text'>
							<Radio.Group onChange={onChangeLanguage} value={i18n.language}>
								<Space>
									<Radio value='en'>
										<img src={usaFlag} alt='' width='25' height='15' />
									</Radio>
									<Radio value='es'>
										<img src={spainFlag} alt='' width='25' height='15' />
									</Radio>
								</Space>
							</Radio.Group>
						</div>
					</div>
				</div>
			</div>
			<br />
		</div>
	);
}

export default Internationalization;
