import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Tooltip, Tag } from "antd";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateLiftSelected,
	updateLayoutSelected,
	updateModalControlPanelTab,
	updateExistsFaultsInMonitoringView,
	updateExistsFaultsInLiftSelected,
	updatePanelDefaultState,
	updateOpenModalControlPanel,
	updateControlPanelDrawerPlacement,
} from "../../../redux/monitoring/monitoringSlice";
// COMPONENTS
import MonitoringIconsMovingWalks from "../monitoringIcons/MonitoringIconsMovingWalks";
// INTERFACES
import { RootState } from "../../../app/store";
import {
	SocketMessageInterface,
	MonitoringLiftMovingWalksFullViewInterface,
} from "../../../interfaces/EdgeManagement.interface";

function MonitoringLiftMovingWalksFullView({
	lift,
	iconDefinitionMovingwalk,
	socketMessage,
	layout,
}: MonitoringLiftMovingWalksFullViewInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		car_calls: { front: [], rear: [], front_lock: [], rear_lock: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
			},
			down: {
				front: [],
				rear: [],
			},
			up_assigned: { front: [], rear: [] },
			down_assigned: { front: [], rear: [] },
		},
		faults: false,
		position: 0,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [hasFaults, setHasFaults] = useState<boolean>(false);
	const [faultMessage, setFaultMessage] = useState<boolean>(false);
	const [lastSocketMessage, setLastSocketMessage] = useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { faults } = useSelector((state: RootState) => state.monitoring);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getDrawerPlacement = (e: any) => {
		const clickX = e.clientX;
		const screenWidth = window.innerWidth;
		if (clickX > screenWidth / 2) {
			dispatch(updateControlPanelDrawerPlacement("left"));
		} else {
			dispatch(updateControlPanelDrawerPlacement("right"));
		}
	};

	const onClickOpenModalElevator = (e: any) => {
		getDrawerPlacement(e);
		dispatch(updateLiftSelected(lift));
		dispatch(updateLayoutSelected(layout));
		dispatch(
			updateModalControlPanelTab({
				liftType: "MOVINGWALKS",
				tab: "FAULTS",
			})
		);
		dispatch(updateOpenModalControlPanel(true));
		dispatch(updateExistsFaultsInLiftSelected(hasFaults));
		dispatch(updatePanelDefaultState(lastSocketMessage));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (lift && lift.id && lift.id === socketMessage.lift_id) {
			setLastSocketMessage(socketMessage);
		}
	}, [socketMessage]);

	useEffect(() => {
		// FAULT MESSAGES
		if (lastSocketMessage.icon.type.toUpperCase() === "FAULT") {
			setFaultMessage(true);
			setHasFaults(true);
			dispatch(updateExistsFaultsInMonitoringView(true));
		}
	}, [lastSocketMessage]);

	useEffect(() => {
		if (
			faults &&
			faults.liftId &&
			faults.liftId !== "" &&
			lift &&
			faults.type === "LIFT" &&
			faults.liftId === lift.id
		) {
			setHasFaults(false);
			setFaultMessage(false);
		}
		if (faults && faults.type === "ALL" && faults.liftId === "") {
			setHasFaults(false);
			setFaultMessage(false);
		}
	}, [faults]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div
				className={`monitoringView__topViewContainer__${theme} ${
					faultMessage
						? `monitoringView__dangerElevator monitoringView__dangerElevator__top monitoringView__dangerElevator__bottom  `
						: ""
				}`}
				style={{
					height: "50px",
					width: "45px",
					margin: "5px",
					marginTop: "1px",
					textAlign: "center",
				}}
			>
				<div>
					<MonitoringIconsMovingWalks
						lift={lift}
						shortMessage={lastSocketMessage.icon.name}
						iconDefinitionMovingwalk={iconDefinitionMovingwalk}
					/>
				</div>
			</div>
			<div
				role='button'
				tabIndex={0}
				onKeyDown={() => {}}
				onClick={onClickOpenModalElevator}
				style={{
					fontSize: "9px",
					fontWeight: "bolder",
					textAlign: "center",
				}}
			>
				{faultMessage || hasFaults ? (
					<div>
						<div>
							<Tooltip placement='top' title={lift.name}>
								<span className='generalStyles__error'>
									{lift && lift.name ? lift.name.toUpperCase().substring(0, 6) : ""}
								</span>
							</Tooltip>
						</div>
						<div className='generalStyles__flexCenter' style={{ textAlign: "center" }}>
							<div>
								<Tooltip placement='top' title='Fault'>
									<Tag
										style={{
											marginTop: "2px",
											marginLeft: "3px",
											fontSize: "9px",
											width: "43px",
										}}
										color='red'
									>
										FAULT
									</Tag>
								</Tooltip>
							</div>
						</div>
						<div>
							{lastSocketMessage.service_mode.name !== "" && (
								<div>
									<Tooltip placement='top' title={lastSocketMessage.service_mode.description}>
										<Tag
											style={{
												marginLeft: "3px",
												marginTop: "5px",
												width: "43px",
											}}
											color='cyan'
										>
											{lastSocketMessage.service_mode.name.toUpperCase().substring(0, 3)}
										</Tag>
									</Tooltip>
								</div>
							)}
						</div>
					</div>
				) : (
					<div>
						<div>
							<Tooltip placement='top' title={lift.name}>
								<span>{lift && lift.name ? lift.name.toUpperCase().substring(0, 6) : ""}</span>
							</Tooltip>
						</div>
						{lastSocketMessage.service_mode.name !== "" && (
							<div>
								<Tooltip placement='top' title={lastSocketMessage.service_mode.description}>
									<Tag
										style={{
											marginLeft: "3px",
											marginTop: "5px",
											width: "43px",
										}}
										color='cyan'
									>
										{lastSocketMessage.service_mode.name.toUpperCase().substring(0, 3)}
									</Tag>
								</Tooltip>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

export default MonitoringLiftMovingWalksFullView;
