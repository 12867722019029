import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { faElevator } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import DrawerRowDivider from "./DrawerRowDivider";

function DrawerLiftInformation({ data }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: any) => state.home);
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faElevator} title={t("edgeManagement.lifts.liftInformation")} />
			{/** ************************************************* */}
			<div className={`drawer__box__${theme}`}>
				{/* ID */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>ID:</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>{data && data.id ? data.id : "--"}</p>
					</div>
				</div>
				{/* POSITION */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("edgeManagement.lifts.position")}</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>{data && data.position ? data.position : "--"}</p>
					</div>
				</div>
				{/* NAME */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("general.name")}</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>{data && data.name ? data.name : "--"}</p>
					</div>
				</div>
				{/* TYPE */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("edgeManagement.lifts.type")}</p>
					</div>
					<div className='drawer__textContainer'>
						<div className='drawer__text'>
							{data && data.type ? (
								<>
									<div>
										{data.type === "ELEVATOR" && (
											<Tag color='blue' className='drawer__typeTag'>
												{data.type}
											</Tag>
										)}
										{data.type === "ESCALATOR" && (
											<Tag color='volcano' className='drawer__typeTag'>
												{data.type}
											</Tag>
										)}
										{data.type === "MOVINGWALK" && (
											<Tag color='green' className='drawer__typeTag'>
												{data.type}
											</Tag>
										)}{" "}
									</div>
								</>
							) : (
								"--"
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DrawerLiftInformation;
