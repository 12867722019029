import React from "react";
// FONT AWESOME LIBRARY AND ICONS
import { faGears } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import DrawerRowDivider from "../components/drawer/DrawerRowDivider";

function GlobalConfiguration() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: any) => state.home);
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<br />
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faGears} title={t("general.globalConfiguration")} />
			{/** ************************************************* */}
			<div className={`drawer__box__${theme}`}>Configuration</div>
		</div>
	);
}

export default GlobalConfiguration;
