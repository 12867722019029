import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Select, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import { useLazyGetRolesByOrganizationQuery } from "../../../redux/roles/roleAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { FormInputSelectRoleInterface } from "../../../interfaces/Global.interface";
// COMPONENTS
import DrawerRowDivider from "../drawer/DrawerRowDivider";

function FormInputSelectRole({ orgId, inputValue, inputOnChange, inputVariableName }: FormInputSelectRoleInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [roles, setRoles] = useState<any[]>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetRoles, { data }] = useLazyGetRolesByOrganizationQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const launchGetRoles = async () => {
		const token = await GETJwtToken();
		triggerGetRoles({ token, orgId });
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (data && data.data) {
			setRoles(data.data);
		}
	}, [data]);

	useEffect(() => {
		launchGetRoles();
	}, [orgId]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faUserShield} title={t("userManagement.role")} />
			{/** ************************************************* */}
			<div className={`drawer__box__${theme} row`}>
				<div className='col-md-12 mt-3'>
					<div className='generalStyles__flex'>
						<div className='generalStyles__inputFlexNameLarge'>
							<span className='generalStyles__inputFlexRequired'>*</span>
							<span className='drawer__text'>{t("userManagement.role")}</span>
						</div>
						<div className='generalStyles__width100'>
							<Select
								disabled={roles.length === 0}
								showSearch
								optionFilterProp='children'
								filterOption={(input: any, option: any) => option.children.toLowerCase().includes(input.toLowerCase())}
								className='generalStyles__width100'
								size='small'
								value={inputValue}
								onChange={(event: any) => {
									inputOnChange(event, inputVariableName);
								}}
							>
								{roles.map((role: any) => (
									<Option key={role.id} value={role.id}>
										{role.name}
									</Option>
								))}
							</Select>
						</div>
						<div>
							<Tooltip title={t("tooltip.userRole")} placement='top'>
								<FontAwesomeIcon icon={faCircleInfo} className='generalStyles__infoTooltipIcon' />
							</Tooltip>
						</div>{" "}
					</div>
				</div>
			</div>
		</div>
	);
}

export default FormInputSelectRole;
