import React from "react";
// ANT DESIGN COMPONENTS
import { Dropdown, Menu, Space } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faPenToSquare, faTools, faTrash } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	updateMapViewItem,
	updateOpenForm,
	updateFormOptionMapView,
	updateOpenModalDelete,
} from "../../../redux/mapView/mapViewSlice";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
// COMPONENTS
import TableMapViewMenuItem from "../../components/table/TableMapViewMenuItem";
// INTERFACES
import { MapViewInterface } from "../../../interfaces/MapView.interface";

function MapViewTableActions({ selectedPin }: { selectedPin: MapViewInterface }) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const navigate = useNavigate();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalDelete = () => {
		dispatch(updateMapViewItem(selectedPin));
		dispatch(updateOpenModalDelete(true));
	};
	const handleOpenModalEdit = () => {
		dispatch(updateMapViewItem(selectedPin));
		dispatch(updateOpenForm(true));
		dispatch(updateFormOptionMapView("edit"));
	};
	const handleNavigateToConfig = () => {
		dispatch(updateMapViewItem(selectedPin));
		navigate(`/edge-management/map-view/configuration`);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// ROW ACTIONS ************************************ */
	const manageMapView = () => {
		const EDIT = validatePermission("maps-update", permissions)
			? {
					label: <TableMapViewMenuItem icon={faPenToSquare} text={t("general.edit")} onClick={handleOpenModalEdit} />,
					key: "1",
			  }
			: null;
		const DELETE = validatePermission("maps-delete", permissions)
			? {
					label: <TableMapViewMenuItem icon={faTrash} text={t("general.delete")} onClick={handleOpenModalDelete} />,
					key: "2",
			  }
			: null;
		const CONFIG = validatePermission("permission-not-defined", permissions)
			? {
					label: (
						<TableMapViewMenuItem icon={faTools} text={t("general.configuration")} onClick={handleNavigateToConfig} />
					),
					key: "3",
			  }
			: null;
		return [EDIT, DELETE, CONFIG];
	};
	const menu = <Menu items={manageMapView()} />;
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Dropdown overlay={menu} trigger={["click"]}>
				<Space className='generalStyles__hoverCursor'>
					<FontAwesomeIcon icon={faEllipsis} />
				</Space>
			</Dropdown>
		</div>
	);
}

export default MapViewTableActions;
