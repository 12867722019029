import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// REDUX
import { useGetCitiesMutation } from "../../../redux/countriesStatesCitiesAPI";
// INTERFACES
import { FormSelectCityInterface } from "../../../interfaces/Global.interface";

function FormInputSelectCity({
	inputValue,
	inputOnChange,
	inputLabelName,
	inputVariableName,
	country,
	state,
}: FormSelectCityInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [listCities, setListCities] = useState<any[]>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetCities, { data, isLoading, reset: resetGetCities }] =
		useGetCitiesMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */
	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			resetGetCities();
		};
	}, []);

	useEffect(() => {
		if (state && state !== "" && country && country !== "") {
			triggerGetCities({ country, state });
		}
	}, [state]);

	useEffect(() => {
		if (data && data.data && data.data.length > 0) {
			setListCities(data.data);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='col-md-12 mt-3'>
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span>{inputLabelName}</span>
				</div>
				<div className='generalStyles__width100'>
					<Select
						className='generalStyles__width100'
						disabled={listCities.length === 0}
						showSearch
						loading={isLoading}
						optionFilterProp='children'
						filterOption={(input: any, option: any) =>
							option.children.toLowerCase().includes(input.toLowerCase())
						}
						onChange={(event: any) => {
							inputOnChange(event, inputVariableName);
						}}
						size='small'
						value={inputValue}
					>
						{listCities.map((c) => (
							<Option key={c} value={c}>
								{c}
							</Option>
						))}
					</Select>
				</div>
				<div className='generalStyles__infoTooltipIconBlank' />
			</div>
		</div>
	);
}

export default FormInputSelectCity;
