import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Button, Input, Spin, Select } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenFormIconDefinition } from "../../../redux/tools/toolsSlice";
import {
	usePostIconDefinitionMutation,
	usePutIconDefinitionMutation,
} from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import IconDefinitionElevator from "./IconDefinitionElevator";
import IconDefinitionEscalator from "./IconDefinitionEscalator";
import IconDefinitionMovingWalk from "./IconDefinitionMovingWalk";
import GlobalAlert2 from "../../home/GlobalAlert2";

function IconDefinitionForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>({
		message: "",
		shortMessage: "",
		type: "ELEVATOR",
		iconType: "STATUS",
		icon: "",
	});
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { iconDefinition, iconDefinitions, openFormIconDefinition, openFormIconDefinitionAction } = useSelector(
		(state: any) => state.tool
	);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		postIconDefinition,
		{ isSuccess: isSuccessPost, isLoading: isLoadingPost, isError: isErrorPost, error: errorPost, reset: resetPost },
	] = usePostIconDefinitionMutation();
	const [
		putIconDefinition,
		{ isSuccess: isSuccessPut, isLoading: isLoadingPut, isError: isErrorPut, error: errorPut, reset: resetPut },
	] = usePutIconDefinitionMutation();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onFill = () => {
		setData({
			message: iconDefinition.message,
			shortMessage: iconDefinition.short_message,
			type: iconDefinition.lift_type,
			iconType: iconDefinition.icon_type,
			icon: iconDefinition.icon,
		});
	};

	const onClickClose = () => {
		dispatch(updateOpenFormIconDefinition(false));
	};

	const onChangeValue = (newValue: string, variableName: string) => {
		const copy = structuredClone(data);
		copy[variableName] = newValue;
		setData(copy);
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		if (openFormIconDefinitionAction === "create") {
			const BODY = {
				token,
				body: {
					message: data.message,
					short_message: data.shortMessage.toLowerCase(),
					lift_type: data.type,
					icon: data.icon,
					icon_type: data.iconType,
				},
			};
			postIconDefinition(BODY);
		} else if (openFormIconDefinitionAction === "update") {
			const BODY = {
				token,
				id: iconDefinition.id,
				body: {
					message: data.message,
					short_message: data.shortMessage.toLowerCase(),
					lift_type: data.type,
					icon: data.icon,
					icon_type: data.iconType,
				},
			};
			putIconDefinition(BODY);
		}
	};

	const checkShortMessageIsValid = (shortMessage: string) => {
		let isValid = true;
		if (iconDefinitions) {
			//
			iconDefinitions.map((icon: any) => {
				if (icon && icon.short_message && icon.short_message === shortMessage && icon.lift_type === data.type) {
					isValid = false;
				}
				return true;
			});
			//
			if (openFormIconDefinitionAction === "update" && shortMessage === iconDefinition.short_message) {
				isValid = true;
			}
		}
		return isValid;
	};

	const disabledSaveButton = () => {
		let disableButton = false;
		if (
			data.shortMessage === "" ||
			data.message === "" ||
			data.type === "" ||
			data.icon === "" ||
			!checkShortMessageIsValid(data.shortMessage)
		) {
			disableButton = true;
		}
		return disableButton;
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		if (openFormIconDefinition) {
			resetPost();
			resetPut();
			if (openFormIconDefinitionAction === "update") {
				onFill();
			} else {
				setData({
					message: "",
					shortMessage: "",
					type: "ELEVATOR",
					iconType: "STATUS",
					icon: "",
				});
			}
		}
	}, [openFormIconDefinition]);

	useEffect(() => {
		onClickClose();
	}, [isSuccessPost, isErrorPost, isSuccessPut, isErrorPut]);

	useEffect(() => {
		onChangeValue("STATUS", "iconType");
	}, [data.type]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width={isMobile ? "95%" : "35%"}
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openFormIconDefinition}
		>
			<Spin spinning={isLoadingPost || isLoadingPut} tip={t("general.loading")}>
				{/** ************************************************* */}
				{/** ICON DEFINITION FORM */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon icon={faGear} className='generalStyles__info generalStyles__mrFix' />
							{openFormIconDefinitionAction === "create"
								? t("configuration.iconDefinition.createNewIconDefinition")
								: t("configuration.iconDefinition.updateIconDefinition")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme}`}>
					{/** SHORT MESSAGE */}
					<div className='col-md-12'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span className='drawer__text'>Short Message</span>
							</div>
							<div className='generalStyles__width100'>
								<Input
									size='small'
									value={data.shortMessage}
									status={checkShortMessageIsValid(data.shortMessage) ? "" : "error"}
									onChange={(e) => {
										onChangeValue(e.currentTarget.value, "shortMessage");
									}}
								/>
							</div>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
					{/**  MESSAGE */}
					<div className='col-md-12 mt-3'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span className='drawer__text'>Message</span>
							</div>
							<div className='generalStyles__width100'>
								<Input
									size='small'
									value={data.message}
									onChange={(e) => {
										onChangeValue(e.currentTarget.value, "message");
									}}
								/>
							</div>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
					{/** TYPE */}
					<div className='col-md-12 mt-3'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span className='drawer__text'>{t("edgeManagement.lifts.liftType")}</span>
							</div>
							<div className='generalStyles__width100'>
								<Select
									showSearch
									optionFilterProp='children'
									className='generalStyles__width100'
									allowClear={false}
									size='small'
									disabled={openFormIconDefinitionAction === "update"}
									value={data.type}
									onChange={(e) => {
										onChangeValue(e, "type");
									}}
								>
									{["ELEVATOR", "ESCALATOR", "MOVINGWALK"].map((item: string) => (
										<Option key={item} value={item}>
											{item}
										</Option>
									))}
								</Select>
							</div>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
					{/** ICON */}
					{data.type === "ELEVATOR" && (
						<IconDefinitionElevator iconType={data.iconType} icon={data.icon} onChangeValue={onChangeValue} />
					)}
					{data.type === "ESCALATOR" && (
						<IconDefinitionEscalator iconType={data.iconType} icon={data.icon} onChangeValue={onChangeValue} />
					)}
					{data.type === "MOVINGWALK" && (
						<IconDefinitionMovingWalk iconType={data.iconType} icon={data.icon} onChangeValue={onChangeValue} />
					)}
				</div>
				<br />
				{/** ************************************************* */}
				{/** CLOSE AND SUBMIT BUTTONS */}
				<div className='mt-4 generalStyles__width100'>
					<div>
						<div>
							<Button onClick={onClickClose} className='buttonStyle__3'>
								{t("organizationManagement.close")}
							</Button>
							<Button onClick={onClickSave} className='buttonStyle__3' type='primary' disabled={disabledSaveButton()}>
								{t("general.save")}
							</Button>
						</div>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPost}
				isSuccess={isSuccessPost}
				requestType='POST'
				error={errorPost}
				name='Icon Definition'
			/>
			<GlobalAlert2
				isError={isErrorPut}
				isSuccess={isSuccessPut}
				requestType='PUT'
				error={errorPut}
				name='Icon Definition'
			/>
		</Drawer>
	);
}

export default IconDefinitionForm;
