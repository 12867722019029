import React, { useEffect, useState } from "react";
// NAVIGATION
import { useNavigate, useLocation } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Table, Tag, Button, Switch } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	useLazyGetBankNotificationsQuery,
	useLazyGetBankMessagesQuery,
	notificationAPI,
	usePostNotificationMutation,
	usePutNotificationMutation,
} from "../../../redux/notifications/notificationAPI";
import { updateNotification } from "../../../redux/notifications/notificationSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
import GlobalAlert2 from "../../home/GlobalAlert2";
import TableColumnMessageType from "../../components/table/TableColumnMessageType";
// INTERFACES
import { RootState } from "../../../app/store";
import { MessageInterface, NotificationInterface } from "../../../interfaces/Notification.interface";

function NotificationsTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Column } = Table;
	const navigate = useNavigate();
	const location = useLocation();
	const idBank = new URLSearchParams(location.search).get("idBank");
	const idOrganization = new URLSearchParams(location.search).get("idOrganization");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [messages, setMessages] = useState<MessageInterface[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [responseError, setResponseError] = useState<any>();

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetNotifications,
		{
			data: dataNotifications,
			isLoading: isLoadingNotifications,
			isError: isErrorNotifications,
			isFetching: isFetchingNotifications,
			error: errorNotifications,
		},
	] = useLazyGetBankNotificationsQuery();

	const [
		triggerGetMessages,
		{
			data: dataMessages,
			isLoading: isLoadingMessages,
			isError: isErrorMessages,
			isFetching: isFetchingMessages,
			error: errorMessages,
		},
	] = useLazyGetBankMessagesQuery();

	const [
		triggerPostNotification,
		{
			isLoading: isLoadingPostNotification,
			error: errorPostNotification,
			isError: isErrorPostNotification,
			isSuccess: isSuccessPostNotification,
		},
	] = usePostNotificationMutation();

	const [
		triggerPutNotification,
		{
			isLoading: isLoadingPutNotification,
			error: errorPutNotification,
			isError: isErrorPutNotification,
			isSuccess: isSuccessPutNotification,
		},
	] = usePutNotificationMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const buildMessages = (messagesResponse: MessageInterface[]) => {
		setTotal(messagesResponse.length);
		const copyMessages: MessageInterface[] = structuredClone(messagesResponse);
		const newDataMessages: MessageInterface[] = [];
		copyMessages.map((message: MessageInterface) => {
			newDataMessages.push({
				...message,
				notifications: [],
			});
			return true;
		});
		setMessages(newDataMessages);
	};

	const onClickCell = (record: MessageInterface) => {
		if (record) {
			dispatch(updateNotification(record));
			navigate({
				pathname: "/notification-management/notifications-users",
				search: `?idBank=${idBank}&idOrganization=${idOrganization}&idNotification=${record.id}`,
			});
		}
	};

	const postNotifications = async (record: MessageInterface) => {
		const token = await GETJwtToken();
		triggerPostNotification({
			token,
			body: {
				bank_id: idBank,
				cooldown: 1,
				description: record.message.description,
				message_id: record.message.id,
				min_span: 1,
				muted: false,
				name: "",
				type: "SMS",
			},
		});
	};

	const putNotifications = async (record: NotificationInterface, checked: boolean) => {
		const token = await GETJwtToken();
		triggerPutNotification({
			token,
			body: {
				bank_id: idBank,
				cooldown: 1,
				description: record.description,
				message_id: record.message_id,
				min_span: 1,
				muted: !checked,
				name: "",
				type: record.type,
			},
			id: record.id,
		});
	};

	const enableDisableNotification = (checked: boolean, record: MessageInterface) => {
		if (record.notifications.length === 0) {
			postNotifications(record);
		} else {
			record.notifications.map((notification: NotificationInterface) => putNotifications(notification, checked));
		}
	};

	const getNotificationsData = async () => {
		const token = await GETJwtToken();
		triggerGetNotifications({
			token,
			searchAndFilterString: `?bank_id=${idBank}`,
		});
	};

	const getInitialData = async () => {
		try {
			dispatch(notificationAPI.util.resetApiState());
			const token = await GETJwtToken();
			const responseGetMessages: any = await triggerGetMessages({
				token,
				bankId: idBank,
			});
			if (responseGetMessages) {
				getNotificationsData();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error("Error fetching data:", error);
		}
	};

	const buildNotifications = (responseNotifications: NotificationInterface[]) => {
		const copyMessages: MessageInterface[] = structuredClone(messages);
		// Reset notifications object
		copyMessages.map((message: MessageInterface, index: number) => {
			copyMessages[index].notifications = [];
			return true;
		});
		// Build notifications
		responseNotifications.map((notification: NotificationInterface) => {
			const index = copyMessages.findIndex(
				(message: MessageInterface) => message.message_id === notification.message_id
			);
			if (index >= 0) {
				copyMessages[index].notifications.push(notification);
			}
			return true;
		});
		setMessages(copyMessages);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(() => {
		if (dataMessages && dataMessages.data && dataMessages.data.length > 0) {
			buildMessages(dataMessages.data);
		}
	}, [dataMessages]);

	useEffect(() => {
		if (dataNotifications && dataNotifications.data && dataNotifications.data.length > 0) {
			buildNotifications(dataNotifications.data);
		}
	}, [dataNotifications]);

	useEffect(() => {
		if (errorNotifications || errorMessages) {
			setResponseError(errorNotifications || errorMessages);
		}
	}, [errorNotifications, errorMessages]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='generalStyles__mlFix mt-1'>
				<Button
					className='buttonStyle__8'
					icon={<FontAwesomeIcon icon={faArrowsRotate} className='generalStyles__mrFix' />}
					onClick={() => {
						getInitialData();
					}}
				>
					{t("general.refreshTable")}
				</Button>
			</div>
			<Table
				locale={{
					emptyText: (
						<TableNoDataBox
							isError={isErrorNotifications || isErrorMessages}
							errorMessage={
								responseError && responseError.data && responseError.data.message ? responseError.data.message : "Error"
							}
							noItemsFound='No data found'
						/>
					),
				}}
				loading={
					isLoadingNotifications ||
					isFetchingNotifications ||
					isLoadingMessages ||
					isFetchingMessages ||
					isLoadingPostNotification ||
					isLoadingPutNotification
				}
				rowClassName={(record, index) => getTableRowClass(index, theme)}
				dataSource={messages}
				size='small'
				className='mt-3'
				pagination={{
					showSizeChanger: true,
					pageSizeOptions: ["10", "20", "50", "100"],
				}}
				rowKey={(record) => record.id}
				scroll={{ x: 900 }}
				footer={() => (
					<div className='generalStyles__flexEnd'>
						<Tag>{total} Records</Tag>
					</div>
				)}
			>
				{/** DESCRIPTION */}
				<Column
					onCell={(record: MessageInterface) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					className='generalStyles__hoverCursor'
					title={t("general.description")}
					dataIndex='description'
					key='description'
					render={(text: string, record: MessageInterface) => (
						<div>{record && record.message && record.message.description ? record.message.description : "--"}</div>
					)}
				/>
				{/** SHORT MESSAGE */}
				<Column
					onCell={(record: MessageInterface) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					className='generalStyles__hoverCursor'
					title={t("general.shortMessage")}
					dataIndex='shortMessage'
					key='shortMessage'
					render={(text: string, record: MessageInterface) => (
						<div>{record && record.message && record.message.short_message ? record.message.short_message : "--"}</div>
					)}
				/>
				{/** MESSAGE TYPE */}
				<Column
					onCell={(record: MessageInterface) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					className='generalStyles__hoverCursor'
					title={t("general.type")}
					dataIndex='messageType'
					key='messageType'
					render={(text: string, record: MessageInterface) => (
						<div>
							{record && record.message && record.message.message_type && record.message.message_type.name ? (
								<TableColumnMessageType messageType={record.message.message_type.name} />
							) : (
								"--"
							)}
						</div>
					)}
				/>
				{/** STATE */}
				<Column
					title={t("general.state")}
					dataIndex='state'
					key='state'
					render={(text: string, record: MessageInterface) => (
						<Switch
							onChange={(checked) => {
								enableDisableNotification(checked, record);
							}}
							checked={
								record && record.notifications && record.notifications[0] ? !record.notifications[0].muted : false
							}
						/>
					)}
				/>
			</Table>
			<GlobalAlert2
				isError={isErrorPostNotification}
				isSuccess={isSuccessPostNotification}
				requestType='POST'
				error={errorPostNotification}
				name={t("general.notification")}
			/>
			<GlobalAlert2
				isError={isErrorPutNotification}
				isSuccess={isSuccessPutNotification}
				requestType='PUT'
				error={errorPutNotification}
				name={t("general.notification")}
			/>
		</div>
	);
}

export default NotificationsTable;
