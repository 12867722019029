import React from "react";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { faObjectGroup } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import DrawerRowDivider from "./DrawerRowDivider";
import DrawerRowItem from "./DrawerRowItem";
// INTERFACES
import { DrawerGroupInformationInterface } from "../../../interfaces/Groups.interface";
import { RootState } from "../../../app/store";

function DrawerGroupInformation({ data }: DrawerGroupInformationInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: RootState) => state.home);
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faObjectGroup} title={t("general.group")} />
			{/** ************************************************* */}
			<div className={`drawer__box__${theme} mt-3`}>
				{/* ID */}
				<DrawerRowItem title='ID' value={data && data.id ? data.id : "--"} />
				{/* NAME */}
				<DrawerRowItem title={t("general.name")} value={data && data.name ? data.name : "--"} />
				{/* MESSAGE TYPE ID */}
				<DrawerRowItem
					title={t("general.messageType")}
					value={data && data.message_type_id ? data.message_type_id.toString() : "--"}
				/>
				{/* ORGANIZATION ID */}
				<DrawerRowItem
					title={t("organizationManagement.organizationId")}
					value={data && data.organization_id ? data.organization_id : "--"}
				/>
			</div>
		</div>
	);
}

export default DrawerGroupInformation;
