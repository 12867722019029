import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(fetchBaseQuery(), { maxRetries });

export const mapViewAPI = createApi({
  reducerPath: "mapViewAPI",
  baseQuery,
  keepUnusedDataFor: 0,
  tagTypes: ["tagGetMapView"],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    //* *********************************************************** */
    //* GET MAP VIEW DATA */
    getMapView: builder.query({
      query: (body: any) => ({
        url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/maps/?org_id=${body.body.org_id}`,
        headers: { authorization: body.token },
      }),
      providesTags: ["tagGetMapView"],
    }),
    //* *********************************************************** */
    //* PUT MAP VIEW  */
    putMapView: builder.mutation({
      query: (body: any) => {
        // Create a new FormData object
        const formData = new FormData();
        // Append your data to the FormData object
        formData.append("name", body.body.name);
        formData.append("org_id", body.body.orgId);
        formData.append("start", body.body.start);
        formData.append("setup", body.body.setup);
        if (body.body.file) {
          formData.append("file", body.body.file);
        }
        return {
          url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/maps/${body.body.id}`,
          method: "put",
          body: formData,
          headers: {
            authorization: body.token,
          },
        };
      },
    }),
    //* *********************************************************** */
    //* DELETE MAP VIEW  */
    deleteMapView: builder.mutation({
      query: (body: any) => ({
        url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/maps/${body.id}`,
        method: "delete",
        headers: { authorization: body.token },
      }),
      invalidatesTags: ["tagGetMapView"],
    }),
    //* *********************************************************** */
    //* POST MAP VIEW  */
    postMapView: builder.mutation({
      query: (body: any) => {
        // Create a new FormData object
        const formData = new FormData();
        // Append your data to the FormData object
        formData.append("file", body.body.file);
        formData.append("name", body.body.name);
        formData.append("org_id", body.body.orgId);
        formData.append("start", body.body.start);
        formData.append("setup", body.body.setup);
        return {
          url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/maps/`,
          method: "post",
          body: formData,
          headers: {
            authorization: body.token,
          },
        };
      },
    }),
    //* *********************************************************** */
    //* POST MAP VIEW PIN */
    postMapViewPin: builder.mutation({
      query: (body: any) => {
        // Create a new FormData object
        const formData = new FormData();
        // Append your data to the FormData object
        formData.append("setup", JSON.stringify(body.body.setup));
        formData.append("name", body.body.name);
        formData.append("start", body.body.start);
        formData.append("org_id", body.body.org_id);
        return {
          url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/maps/${body.body.id}`,
          method: "put",
          body: formData,
          headers: {
            authorization: body.token,
          },
        };
      },
    }),
    //* *********************************************************** */
  }),
});

export const {
  useLazyGetMapViewQuery,
  usePostMapViewMutation,
  useDeleteMapViewMutation,
  usePutMapViewMutation,
  usePostMapViewPinMutation,
} = mapViewAPI;
