/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-use-before-define */
import React from "react";
// ANT DESIGN COMPONENTS
import { Dropdown, Menu, Tooltip, Spin, message } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faGear,
	faMinimize,
	faExpand,
	faTableCells,
	faSave,
	faElevator,
	faDesktop,
	faCircleCheck,
	faList,
	faTerminal,
	faStar,
	faBuilding,
	faCircleInfo,
	faWifi,
	faServer,
	faCircleExclamation,
	faMap,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateEnableDragAndDrop,
	updateShowFullView,
	updateMonitoringGridType,
	updateShowQueueInfo,
	updateClearQueue,
	updateSocketConnection,
	updateOpenGlobalTerminal,
	updateOpenModalSaveGrid,
	updateOpenModalManageGrids,
	updateFaults,
	updateExistsFaultsInMonitoringView,
} from "../../../redux/monitoring/monitoringSlice";
import { updateUserLogged } from "../../../redux/home/homeSlice";
import { usePutUserMutation } from "../../../redux/users/userAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// COMPONENTS
import OrganizationCascaderSelectMultiple from "../../organizationManagement/organizations/OrganizationCascaderSelectMultiple";
// INTERFACECES
import { RootState } from "../../../app/store";

function MonitoringHeader() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const {
		enableDragAndDrop,
		showFullView,
		cascaderSelectMultipleSelectionOptions,
		monitoringGridType,
		showQueueInfo,
		socketConnection,
		idGridSelected,
		existsFaultsInMonitoringView,
	} = useSelector((state: RootState) => state.monitoring);
	const { userLogged, theme } = useSelector((state: RootState) => state.home);
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [putUser, { isLoading: isLoadingPutUser }] = usePutUserMutation();

	// ************************************************ */
	// DROPDOWN MENU ITEMS **************************** */
	const toolsMenu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<div
							className='monitoring__200pxBox'
							role='button'
							tabIndex={0}
							onKeyDown={() => {}}
							onClick={() => {
								if (cascaderSelectMultipleSelectionOptions && cascaderSelectMultipleSelectionOptions.length > 0) {
									dispatch(updateOpenModalSaveGrid(true));
								}
							}}
						>
							<span>
								<FontAwesomeIcon icon={faSave} className='generalStyles__mrFix generalStyles__info' />
								{t("monitoring.saveGrid")}
							</span>
						</div>
					),
				},
				{
					key: "2",
					label: (
						<div
							className='monitoring__200pxBox'
							role='button'
							tabIndex={0}
							onKeyDown={() => {}}
							onClick={() => {
								if (cascaderSelectMultipleSelectionOptions && cascaderSelectMultipleSelectionOptions.length > 0) {
									dispatch(updateOpenModalManageGrids(true));
								}
							}}
						>
							<span>
								<FontAwesomeIcon icon={faGear} className='generalStyles__mrFix generalStyles__info' />
								{t("monitoring.manageGrids")}
							</span>
						</div>
					),
				},
				{
					key: "3",
					label: (
						<div
							className='monitoring__200pxBox'
							role='button'
							tabIndex={0}
							onKeyDown={() => {}}
							onClick={() => {
								dispatch(updateEnableDragAndDrop(!enableDragAndDrop));
							}}
						>
							{enableDragAndDrop ? (
								<span>
									{" "}
									<FontAwesomeIcon icon={faTableCells} className='generalStyles__mrFix generalStyles__info' />
									{t("monitoring.disableDragAndDrop")}
								</span>
							) : (
								<span>
									{" "}
									<FontAwesomeIcon icon={faTableCells} className='generalStyles__mrFix generalStyles__info' />
									{t("monitoring.enableDragAndDrop")}
								</span>
							)}
						</div>
					),
				},
				{
					key: "4",
					label: (
						<div
							className='montoring__150pxBox'
							role='button'
							tabIndex={0}
							onKeyDown={() => {}}
							onClick={() => {
								dispatch(updateClearQueue(true));
								setTimeout(() => {
									dispatch(updateClearQueue(false));
								}, 100);
							}}
						>
							<span>
								<FontAwesomeIcon icon={faList} className='generalStyles__mrFix generalStyles__info' />
								{t("monitoring.clearQueue")}
							</span>
						</div>
					),
				},
				{
					key: "5",
					label: (
						<div
							className='montoring__150pxBox'
							role='button'
							tabIndex={0}
							onKeyDown={() => {}}
							onClick={() => {
								dispatch(updateOpenGlobalTerminal(true));
							}}
						>
							<span>
								<FontAwesomeIcon icon={faTerminal} className='generalStyles__mrFix generalStyles__info' />
								Terminal
							</span>
						</div>
					),
				},
				{
					key: "6",
					label: (
						<div
							className='montoring__150pxBox'
							role='button'
							tabIndex={0}
							onKeyDown={() => {}}
							onClick={() => {
								dispatch(updateShowQueueInfo(!showQueueInfo));
							}}
						>
							<span>
								{showQueueInfo ? (
									<FontAwesomeIcon icon={faCircleCheck} className='generalStyles__mrFix generalStyles__success' />
								) : (
									<FontAwesomeIcon icon={faTerminal} className='generalStyles__mrFix generalStyles__info' />
								)}
								{showQueueInfo ? <>{t("monitoring.hideQueueInfo")}</> : <>{t("monitoring.showQueueInfo")}</>}
							</span>
						</div>
					),
				},
			]}
		/>
	);

	const monitorMenu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<div
							className='montoring__150pxBox'
							role='button'
							tabIndex={0}
							onKeyDown={() => {}}
							onClick={() => {
								dispatch(updateMonitoringGridType("FULLVIEW"));
							}}
						>
							<span>
								{monitoringGridType === "FULLVIEW" ? (
									<FontAwesomeIcon icon={faCircleCheck} className='generalStyles__mrFix generalStyles__success' />
								) : (
									<FontAwesomeIcon icon={faBuilding} className='generalStyles__mrFix generalStyles__info' />
								)}
								{t("monitoring.expandedView")}
							</span>
						</div>
					),
				},
				{
					key: "2",
					label: (
						<div
							className='montoring__150pxBox'
							role='button'
							tabIndex={0}
							onKeyDown={() => {}}
							onClick={() => {
								dispatch(updateMonitoringGridType("TOPVIEW"));
							}}
						>
							<span>
								{monitoringGridType === "TOPVIEW" ? (
									<FontAwesomeIcon icon={faCircleCheck} className='generalStyles__mrFix generalStyles__success' />
								) : (
									<FontAwesomeIcon icon={faElevator} className='generalStyles__mrFix generalStyles__info' />
								)}
								{t("monitoring.overHeadView")}
							</span>
						</div>
					),
				},
				{
					key: "3",
					label: (
						<div
							className='montoring__150pxBox'
							role='button'
							tabIndex={0}
							onKeyDown={() => {}}
							onClick={() => {
								dispatch(updateMonitoringGridType("STATUSVIEW"));
							}}
						>
							<span>
								{monitoringGridType === "STATUSVIEW" ? (
									<FontAwesomeIcon icon={faCircleCheck} className='generalStyles__mrFix generalStyles__success' />
								) : (
									<FontAwesomeIcon icon={faCircleInfo} className='generalStyles__mrFix generalStyles__info' />
								)}
								{t("monitoring.statusView")}
							</span>
						</div>
					),
				},
				{
					key: "4",
					label: validatePermission("monitor-grid-type-map-view", permissions) ? (
						<div
							className='montoring__150pxBox'
							role='button'
							tabIndex={0}
							onKeyDown={() => {}}
							onClick={() => {
								dispatch(updateMonitoringGridType("MAPVIEW"));
							}}
						>
							<span>
								{monitoringGridType === "MAPVIEW" ? (
									<FontAwesomeIcon icon={faCircleCheck} className='generalStyles__mrFix generalStyles__success' />
								) : (
									<FontAwesomeIcon icon={faMap} className='generalStyles__mrFix generalStyles__info' />
								)}
								{t("monitoring.mapView")}
							</span>
						</div>
					) : null,
				},
			]}
		/>
	);

	const connectionMenu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<div
							className='montoring__150pxBox'
							role='button'
							tabIndex={0}
							onKeyDown={() => {}}
							onClick={() => {
								if (process.env.REACT_APP_EDGE_SERVER_CONNECTION === "EDGE") {
									dispatch(updateSocketConnection("EDGE"));
								} else if (process.env.REACT_APP_EDGE_SERVER_CONNECTION === "OFFLINE") {
									dispatch(updateSocketConnection("OFFLINE"));
								}
							}}
						>
							<span>
								{socketConnection === "EDGE" || socketConnection === "OFFLINE" ? (
									<FontAwesomeIcon icon={faCircleCheck} className='generalStyles__mrFix generalStyles__success' />
								) : (
									<FontAwesomeIcon icon={faServer} className='generalStyles__mrFix generalStyles__info' />
								)}
								{process.env.REACT_APP_EDGE_SERVER_CONNECTION === "EDGE" && "Edge Server"}
								{process.env.REACT_APP_EDGE_SERVER_CONNECTION === "OFFLINE" && "Offline Server"}
							</span>
						</div>
					),
				},
				{
					key: "2",
					label: (
						<div
							className='montoring__150pxBox'
							role='button'
							tabIndex={0}
							onKeyDown={() => {}}
							onClick={() => {
								dispatch(updateSocketConnection("EMULATOR"));
							}}
						>
							<span>
								{socketConnection === "EMULATOR" ? (
									<FontAwesomeIcon icon={faCircleCheck} className='generalStyles__mrFix generalStyles__success' />
								) : (
									<FontAwesomeIcon icon={faCircleExclamation} className='generalStyles__mrFix generalStyles__info' />
								)}
								Emulator
							</span>
						</div>
					),
				},
			]}
		/>
	);
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const saveFavoriteGrid = async () => {
		// Save favorite theme
		const token = await GETJwtToken();
		const BODY = {
			id: userLogged.id,
			token,
			body: { ...userLogged, pref_grid_id: idGridSelected },
		};
		const response = await putUser(BODY).unwrap();
		if (response && response.data && response.data) {
			dispatch(updateUserLogged(response.data));
		}
	};

	const onClickFullView = () => {
		dispatch(updateShowFullView(!showFullView));
	};

	const onClickClearFaults = () => {
		dispatch(updateExistsFaultsInMonitoringView(false));
		dispatch(
			updateFaults({
				liftId: "",
				type: "ALL",
			})
		);
		message.success({
			type: "success",
			content: `${t("monitoring.faultsCleared")}`,
		});
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={isLoadingPutUser}>
			<div className={`generalStyles__spaceBetween mb-3 monitoring__headerContainer__${theme}`}>
				<div className='generalStyles__flex'>
					{/** ******************************************* */}
					{/** CASCADER SELECT */}
					<div>
						<OrganizationCascaderSelectMultiple />
					</div>
					{/** ************************************************** */}
					{/** SAVE AS FAVORITE */}
					<div className='generalStyles__mlFix'>
						<div
							className='monitoring__barIcon'
							role='button'
							tabIndex={0}
							onKeyDown={() => {}}
							onClick={() => {
								saveFavoriteGrid();
							}}
						>
							<Tooltip
								title={
									userLogged && userLogged.pref_grid_id && userLogged.pref_grid_id === idGridSelected
										? ""
										: t("monitoring.saveAsFavorite")
								}
							>
								<FontAwesomeIcon
									className={`${
										userLogged && userLogged.pref_grid_id && userLogged.pref_grid_id === idGridSelected
											? "generalStyles__warning"
											: `generalStyles__themeIcon1__${theme}`
									}`}
									icon={faStar}
								/>
							</Tooltip>
						</div>
					</div>
					{/** ************************************************** */}
					{/** REFRESH ICON */}
					{existsFaultsInMonitoringView && (
						<div>
							<div
								className='monitoring__barIcon'
								role='button'
								tabIndex={0}
								onKeyDown={() => {}}
								onClick={onClickClearFaults}
							>
								<Tooltip title={t("monitoring.clearList")}>
									<FontAwesomeIcon className='generalStyles__error' icon={faCircleExclamation} />
								</Tooltip>
							</div>
						</div>
					)}
				</div>
				<div className='generalStyles__flex'>
					{/** ************************************************** */}
					{/** MONITOR ICON */}
					<div>
						<div className='monitoring__barIcon' role='button' tabIndex={0} onKeyDown={() => {}}>
							<Dropdown overlay={monitorMenu} placement='bottomLeft'>
								<FontAwesomeIcon className={`generalStyles__themeIcon1__${theme}`} icon={faDesktop} />
							</Dropdown>
						</div>
					</div>
					{/** ************************************************** */}
					{/** GEAR ICON */}
					<div>
						<div className='monitoring__barIcon' role='button' tabIndex={0} onKeyDown={() => {}}>
							<Dropdown overlay={toolsMenu} placement='bottomLeft'>
								<FontAwesomeIcon className={`generalStyles__themeIcon1__${theme}`} icon={faGear} />
							</Dropdown>
						</div>
					</div>
					{/** ************************************************** */}
					{/** CONNECTION ICON */}
					{validatePermission("monitor-emulator", permissions) && (
						<div>
							<div className='monitoring__barIcon' role='button' tabIndex={0} onKeyDown={() => {}}>
								<Dropdown overlay={connectionMenu} placement='bottomLeft'>
									<FontAwesomeIcon className={`generalStyles__themeIcon1__${theme}`} icon={faWifi} />
								</Dropdown>
							</div>
						</div>
					)}
					{/** ************************************************** */}
					{/** EXPAND ICON */}
					<div>
						<div
							role='button'
							tabIndex={0}
							onClick={onClickFullView}
							onKeyDown={() => {}}
							className='monitoring__barIcon'
						>
							<FontAwesomeIcon
								className={`generalStyles__themeIcon1__${theme}`}
								icon={showFullView ? faMinimize : faExpand}
							/>
						</div>
					</div>
				</div>
			</div>
		</Spin>
	);
}

export default MonitoringHeader;
