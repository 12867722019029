import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  MapViewDirections,
  MapViewTypes,
} from "../../../interfaces/MapView.interface";
import GlobalAlert2 from "../../home/GlobalAlert2";
import {
  updateMapViewPinArray,
  updateOpenSelectMode,
} from "../../../redux/mapView/mapViewSlice";
import MapViewPin from "./MapViewPin";
// REDUX
import GETJwtToken from "../../../redux/authentication/authentication";
import { usePostMapViewPinMutation } from "../../../redux/mapView/mapViewAPI";
import useMousePosition from "./useMousePosition";

function MapViewMap() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const divRef = React.useRef<HTMLDivElement>(null);
  const [svgContent, setSvgContent] = React.useState<any>(null);
  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [buttonProperties, setButtonProperties] = React.useState({
    width: 0,
    height: 0,
  });
  const { x, y } = useMousePosition();
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { openSelectMode, selectedPin, mapViewPinArray, mapViewItem } =
    useSelector((state: any) => state.mapView);
  const { theme } = useSelector((state: RootState) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    triggerPostMapViewPin,
    {
      isSuccess: isSuccessPostMapView,
      isError: isErrorPostMapView,
      error: errorPostMapView,
    },
  ] = usePostMapViewPinMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const getDirection = (direction: string) => {
    if (direction === MapViewDirections.UP) return "top";
    if (direction === MapViewDirections.DOWN) return "bottom";
    if (direction === MapViewDirections.LEFT) return "left";
    if (direction === MapViewDirections.RIGHT) return "right";
    return "top";
  };

  const getTypeInLowerCase = (type: string) => {
    if (type === MapViewTypes.LIFT) return "elevator";
    if (type === MapViewTypes.ESCALATOR) return "escalator";
    if (type === MapViewTypes.MOVINGWALK) return "movingwalk";
    if (type === MapViewTypes.MAP) return "map";
    return "elevator";
  };
  const handleResize = () => {
    const width = divRef.current?.offsetWidth || 0;
    const height = divRef.current?.offsetHeight || 0;
    if (width === 0 || height === 0) return;
    setButtonProperties({
      width,
      height,
    });
  };

  const getLiftIcon = (type: string) => {
    if (type === MapViewTypes.LIFT) return "elClose";
    if (type === MapViewTypes.ESCALATOR) return "esLocked";
    if (type === MapViewTypes.MOVINGWALK) return "weLocked";
    if (type === MapViewTypes.MAP) return "map";
    return "elClose";
  };

  const getXMargin = (direction: MapViewDirections) => {
    switch (direction) {
      case MapViewDirections.LEFT:
        return -8;
      case MapViewDirections.RIGHT:
        return 96;
      case MapViewDirections.UP:
        return 20;
      case MapViewDirections.DOWN:
        return 20;
      default:
        return 0;
    }
  };

  const getYMargin = (direction: MapViewDirections) => {
    switch (direction) {
      case MapViewDirections.DOWN:
        return 80;
      case MapViewDirections.LEFT:
        return 18;
      case MapViewDirections.RIGHT:
        return 18;
      case MapViewDirections.UP:
        return -8;
      default:
        return 0;
    }
  };

  const handleClickMap = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!openSelectMode) return;
    const offsetX = e.nativeEvent.offsetX - getXMargin(selectedPin.direction);
    const offsetY = e.nativeEvent.offsetY - getYMargin(selectedPin.direction);

    const relativeX = offsetX / buttonProperties.width;
    const relativeY = offsetY / buttonProperties.height;

    // save in redux
    dispatch(
      updateMapViewPinArray([
        ...mapViewPinArray,
        {
          ...selectedPin,
          x: relativeX,
          y: relativeY,
        },
      ])
    );
    // Save map view in the backend
    const token = await GETJwtToken();
    const body = {
      id: mapViewItem.id,
      name: mapViewItem.name,
      org_id: mapViewItem.org_id,
      start: mapViewItem.start,
      setup: {
        pins: [
          ...mapViewPinArray,
          {
            ...selectedPin,
            x: relativeX,
            y: relativeY,
          },
        ],
      },
    };
    triggerPostMapViewPin({
      token,
      body,
    });
    // turn of select mode
    dispatch(updateOpenSelectMode(!openSelectMode));
  };

  const fetchSvg = (svgUrl: string) => {
    // Replace with your S3 UR
    fetch(svgUrl, {
      method: "GET",
      headers: {
        "Content-Type": "image/svg+xml",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.text();
      })
      .then((text) => {
        setSvgContent(text);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("Error fetching the SVG:", error);
      });
  };

  // ************************************************ */
  // USE EFFECT ************************************* */

  React.useEffect(() => {
    const pins = mapViewItem?.setup?.pins ?? [];
    dispatch(
      updateMapViewPinArray(
        pins?.map((pin: any) => ({
          ...pin,
        }))
      )
    );
    handleResize();
  }, [mapViewItem]);

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    handleResize();
  }, [divRef.current?.clientWidth, divRef.current?.clientHeight]);

  React.useEffect(() => {
    if (!mapViewItem?.s3_url) return;
    fetchSvg(mapViewItem.s3_url);
  }, [mapViewItem.s3_url]);

  React.useEffect(() => {
    if (divRef.current) {
      divRef.current.innerHTML = svgContent;
      handleResize();
    }
  }, [svgContent]);

  React.useEffect(() => {
    if (!svgContent) return;
    // replace the first ocurrence of width and height
    let modifiedSvg = svgContent;
    modifiedSvg = modifiedSvg.replace(/width="[^"]+"/, `width="100%"`);
    modifiedSvg = modifiedSvg.replace(/height="[^"]+"/, `height="100%"`);
    setSvgContent(modifiedSvg);
    switch (theme) {
      case "light":
        modifiedSvg = modifiedSvg.replace(/fill="[^"]+"/g, 'fill="#000000"'); // Cambia los colores a blanco
        modifiedSvg = modifiedSvg.replace(
          /fill:\s*#[0-9a-fA-F]{3,6};?/g,
          "fill:#000000;"
        );
        modifiedSvg = modifiedSvg.replace(
          /stroke:\s*#[0-9a-fA-F]{3,6};?/g,
          "stroke:#f0f2f5;"
        );
        break;
      case "dark":
        modifiedSvg = modifiedSvg.replace(/fill="[^"]+"/g, 'fill="#ffffff"'); // Cambia los colores a negro
        modifiedSvg = modifiedSvg.replace(
          /fill:\s*#[0-9a-fA-F]{3,6};?/g,
          "fill:#ffffff;"
        );
        modifiedSvg = modifiedSvg.replace(
          /stroke:\s*#[0-9a-fA-F]{3,6};?/g,
          "stroke:#000000;"
        );
        break;
      case "blue":
        modifiedSvg = modifiedSvg.replace(/fill="[^"]+"/g, 'fill="#ffffff"');
        modifiedSvg = modifiedSvg.replace(
          /fill:\s*#[0-9a-fA-F]{3,6};?/g,
          "fill:#ffffff;"
        );
        modifiedSvg = modifiedSvg.replace(
          /stroke:\s*#[0-9a-fA-F]{3,6};?/g,
          "stroke:#0c1a28;"
        );
        break;
      default:
        modifiedSvg = modifiedSvg.replace(/fill="[^"]+"/g, 'fill="#FFFFFF"');
        break;
    }
    setSvgContent(modifiedSvg);
  }, [svgContent, theme]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <>
      {/** BODY */}
      <div
        style={{
          position: "relative",
        }}
      >
        {/** CUSTOM CURSOR */}
        {selectedPin && openSelectMode && (
          <div
            style={{
              position: "absolute",
              top: y ? y - getYMargin(selectedPin.direction) : 0,
              left: x ? x - getXMargin(selectedPin.direction) : 0,
              pointerEvents: "none",
            }}
          >
            <MapViewPin
              direction={getDirection(selectedPin.direction)}
              liftType={getTypeInLowerCase(selectedPin.type)}
              orientation={
                selectedPin.direction === "DOWN" ||
                selectedPin.direction === "UP"
                  ? "vertical"
                  : "horizontal"
              }
              icon={getLiftIcon(selectedPin.type)}
              carName={selectedPin.text}
              isCursor
            />
          </div>
        )}
        {/** MAP */}
        <div
          style={{
            filter: openSelectMode && "brightness(0.8)",
            width: "100%",
            height: "fit-content",
          }}
          onClick={handleClickMap}
          role="button"
          tabIndex={0}
          aria-label="map"
          onKeyDown={() => {}}
        >
          {svgContent && <div ref={divRef} />}
        </div>
        {/** Pins */}
        {mapViewPinArray?.map((lift: any) => {
          if (!lift.x || !lift.y) return null;
          return (
            <div
              key={lift.id}
              style={{
                position: "absolute",
                top: lift.y * buttonProperties.height,
                left: lift.x * buttonProperties.width,
                pointerEvents: openSelectMode ? "none" : "auto",
              }}
            >
              <MapViewPin
                direction={getDirection(lift.direction)}
                liftType={getTypeInLowerCase(lift.type)}
                orientation={
                  lift.direction === "DOWN" || lift.direction === "UP"
                    ? "vertical"
                    : "horizontal"
                }
                icon={getLiftIcon(lift.type)}
                carName={lift.text}
                isCursor={false}
              />
            </div>
          );
        })}
      </div>
      <GlobalAlert2
        isError={isErrorPostMapView}
        isSuccess={isSuccessPostMapView}
        requestType="POST"
        error={errorPostMapView}
        name="Action"
      />
    </>
  );
}

export default MapViewMap;
