import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";

function TableColumnMessageType({ messageType }: { messageType: string }) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getTagColor = () => {
		const type = messageType.toLowerCase();
		if (type.includes("fault")) {
			return "red";
		}
		if (type.includes("status")) {
			return "green";
		}
		if (type.includes("service")) {
			return "purple";
		}
		if (type.includes("traffic")) {
			return "orange";
		}
		return "blue";
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Tag color={getTagColor()} className='drawer__typeTag'>
				{messageType}
			</Tag>
		</div>
	);
}

export default TableColumnMessageType;
