import React, { useState, useEffect } from "react";
// AMPLIFY
import { Auth } from "aws-amplify";
// REACT BOOTSTRAP
import Collapse from "react-bootstrap/Collapse";
// ANT DESIGN COMPONENTS
import { Input, Button } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faKey, faLock, faCircleCheck, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch } from "react-redux";
import { updateAmplifyActiveComponent, updateIsLoading } from "../../redux/login/loginSlice";
// COMPONENTS
import CustomAmplifyBackToLogin from "./CustomAmplifyBackToLogin";

library.add(faEnvelope, faKey, faLock, faCircleCheck, faCircleExclamation);

function CustomAmplifyForgotPassword() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState(false);
	const [verificationCode, setVerificationCode] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordValid, setNewPasswordValid] = useState(false);
	const [backToLoginView, setBackToLoginView] = useState(false);
	const [newPasswordContains8Characters, setNewPasswordContains8Characters] = useState(false);
	const [newPasswordContainsNumber, setNewPasswordContainsNumber] = useState(false);
	const [newPasswordContainsSpecialCharacter, setNewPasswordContainsSpecialCharacter] = useState(false);
	const [newPasswordContainsUpperCase, setNewPasswordContainsUpperCase] = useState(false);
	const [newPasswordContainsLowerCase, setNewPasswordContainsLowerCase] = useState(false);
	const [loginErrorMessage, setLoginErrorMessage] = useState<any>("");
	const [sendCodeSuccessMessage, setSendCodeSuccessMessage] = useState(false);
	const [openCollapse, setOpenCollapse] = useState(false);
	const [openCollapseValidateNewPassword, setOpenCollapseValidateNewPassword] = useState(false);

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const handleChangeSendCodeSuccessMessage = () => {
		setSendCodeSuccessMessage(true);
		setTimeout(() => {
			setSendCodeSuccessMessage(false);
		}, 3000);
	};

	const handleSubmitSendCode = async () => {
		try {
			dispatch(updateIsLoading(true));
			setLoginErrorMessage("");
			await Auth.forgotPassword(email);
			setOpenCollapse(true);
			dispatch(updateIsLoading(false));
			setVerificationCode("");
			setNewPassword("");
			handleChangeSendCodeSuccessMessage();
		} catch (error) {
			dispatch(updateIsLoading(false));
			setLoginErrorMessage(error);
		}
	};

	const handleSubmitResetPassword = async () => {
		try {
			dispatch(updateIsLoading(true));
			setLoginErrorMessage("");
			await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
			dispatch(updateIsLoading(false));
			setEmail("");
			setVerificationCode("");
			setNewPassword("");
			setLoginErrorMessage("");
			setEmailError(false);
			setNewPasswordValid(false);
			setBackToLoginView(true);
		} catch (error) {
			dispatch(updateIsLoading(false));
			setLoginErrorMessage(error);
		}
	};

	const handleSubmitSendCodeEnter = (event: any) => {
		if (event.key === "Enter") {
			handleSubmitSendCode();
		}
	};

	const handleSubmitResetPasswordEnter = (event: any) => {
		if (event.key === "Enter") {
			handleSubmitResetPassword();
		}
	};

	const validateEmail = (e: string) =>
		String(e)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);

	const validateStringContains8Characters = (e: string) => e.length >= 8;

	const validateStringContainsNumber = (e: string) => String(e).toLowerCase().match(/\d/);

	const validateStringContainsSpecialCharacter = (e: string) =>
		String(e)
			.toLowerCase()
			// eslint-disable-next-line no-useless-escape
			.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/);

	const validateStringContainsUpperCase = (e: string) => String(e).match(/.*[A-Z].*/);

	const validateStringContainsLowerCase = (e: string) => String(e).match(/.*[a-z]/);

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (email.length === 0) {
			setOpenCollapse(false);
			setVerificationCode("");
			setNewPassword("");
			setNewPasswordValid(false);
		}
		const emailValid = validateEmail(email);
		if (emailValid) {
			setEmailError(false);
		} else {
			setEmailError(true);
		}
	}, [email]);

	useEffect(() => {
		// OPEN COLLAPSE VALIDATE PASSWORD
		if (newPassword.length > 0) {
			setOpenCollapseValidateNewPassword(true);
		} else {
			setOpenCollapseValidateNewPassword(false);
		}
		// validate 8 characters
		if (validateStringContains8Characters(newPassword)) {
			setNewPasswordContains8Characters(true);
		} else {
			setNewPasswordContains8Characters(false);
		}
		// validate a number in string
		if (validateStringContainsNumber(newPassword)) {
			setNewPasswordContainsNumber(true);
		} else {
			setNewPasswordContainsNumber(false);
		}
		// validate a special character in string
		if (validateStringContainsSpecialCharacter(newPassword)) {
			setNewPasswordContainsSpecialCharacter(true);
		} else {
			setNewPasswordContainsSpecialCharacter(false);
		}
		// validate an upercase in string
		if (validateStringContainsUpperCase(newPassword)) {
			setNewPasswordContainsUpperCase(true);
		} else {
			setNewPasswordContainsUpperCase(false);
		}
		// validate a lowerCase in string
		if (validateStringContainsLowerCase(newPassword)) {
			setNewPasswordContainsLowerCase(true);
		} else {
			setNewPasswordContainsLowerCase(false);
		}
	}, [newPassword]);

	useEffect(() => {
		// validate valid password
		if (
			newPasswordContains8Characters &&
			newPasswordContainsSpecialCharacter &&
			newPasswordContainsNumber &&
			newPasswordContainsUpperCase &&
			newPasswordContainsLowerCase
		) {
			setNewPasswordValid(true);
		} else {
			setNewPasswordValid(false);
		}
	}, [
		newPasswordValid,
		newPasswordContains8Characters,
		newPasswordContainsSpecialCharacter,
		newPasswordContainsNumber,
		newPasswordContainsUpperCase,
		newPasswordContainsLowerCase,
	]);

	// ************************************************ */
	// COMPONENT ************************************** */

	return (
		<div>
			{!backToLoginView ? (
				<>
					{/* INPUT - EMAIL */}
					<Input
						className='loginStyles__mobileInput'
						placeholder='Email'
						status={emailError && email.length > 0 ? "error" : ""}
						onChange={(e) => {
							setEmail(e.target.value);
						}}
						onKeyDown={handleSubmitSendCodeEnter}
						prefix={<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faEnvelope} />}
					/>
					<Collapse in={openCollapse}>
						<div id='example-collapse-text'>
							{/* INPUT - VERIFICATION CODE */}
							<Input
								className='loginStyles__mobileInput mt-3'
								placeholder='Verification Code'
								onChange={(e) => {
									setVerificationCode(e.target.value);
								}}
								prefix={<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faKey} />}
								value={verificationCode}
							/>
							{/* INPUT - NEW PASSWORD */}
							<Input.Password
								className='loginStyles__mobileInput mt-3'
								placeholder='New Password'
								onChange={(e) => {
									setNewPassword(e.target.value);
								}}
								prefix={<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faLock} />}
								onKeyDown={handleSubmitResetPasswordEnter}
								value={newPassword}
							/>
						</div>
					</Collapse>
					{/* PASSWORD VALIDATOR */}
					<Collapse in={openCollapseValidateNewPassword}>
						<div className='loginStyles__validateNewPasswordContainer'>
							<span>
								{newPasswordContains8Characters ? (
									<FontAwesomeIcon className='generalStyles__success generalStyles__mrFix' icon={faCircleCheck} />
								) : (
									<FontAwesomeIcon className='generalStyles__error generalStyles__mrFix' icon={faCircleExclamation} />
								)}
								Contains at least 8 characters
							</span>
							<br />
							<span>
								{newPasswordContainsNumber ? (
									<FontAwesomeIcon className='generalStyles__success generalStyles__mrFix' icon={faCircleCheck} />
								) : (
									<FontAwesomeIcon className='generalStyles__error generalStyles__mrFix' icon={faCircleExclamation} />
								)}
								Contains at least 1 number
							</span>
							<br />
							<span>
								{newPasswordContainsSpecialCharacter ? (
									<FontAwesomeIcon className='generalStyles__success generalStyles__mrFix' icon={faCircleCheck} />
								) : (
									<FontAwesomeIcon className='generalStyles__error generalStyles__mrFix' icon={faCircleExclamation} />
								)}
								Contains at least 1 special character
							</span>
							<br />
							<span>
								{newPasswordContainsUpperCase ? (
									<FontAwesomeIcon className='generalStyles__success generalStyles__mrFix' icon={faCircleCheck} />
								) : (
									<FontAwesomeIcon className='generalStyles__error generalStyles__mrFix' icon={faCircleExclamation} />
								)}
								Contains at least 1 uppercase letter
							</span>
							<br />
							<span>
								{newPasswordContainsLowerCase ? (
									<FontAwesomeIcon className='generalStyles__success generalStyles__mrFix' icon={faCircleCheck} />
								) : (
									<FontAwesomeIcon className='generalStyles__error generalStyles__mrFix' icon={faCircleExclamation} />
								)}
								Contains at least 1 lowercase letter
							</span>
							<br />
						</div>
					</Collapse>
					{/** BUTTON - RESET PASSWORD - SAVE NEW PASSWORD - SEND CODE AGAIN */}
					{!openCollapse ? (
						<>
							<div className='generalStyles__flex mt-4'>
								<div>
									<Button type='primary' onClick={handleSubmitSendCode} disabled={email.length === 0 || emailError}>
										Send Code
									</Button>
								</div>
								<div className='generalStyles__mlFix'>
									<Button
										type='default'
										onClick={() => {
											dispatch(updateAmplifyActiveComponent("sign-in"));
										}}
									>
										<span>Sign in With Another Account</span>
									</Button>
								</div>
							</div>
						</>
					) : (
						<>
							<div className='generalStyles__flex mt-3'>
								<div>
									<Button
										type='primary'
										disabled={verificationCode.length === 0 || !newPasswordValid}
										onClick={handleSubmitResetPassword}
									>
										Save New Password
									</Button>
								</div>
								<div>
									<Button
										type='default'
										className='generalStyles__mlFix'
										onClick={handleSubmitSendCode}
										disabled={email.length === 0 || emailError}
									>
										Send New Code
									</Button>
								</div>
							</div>
						</>
					)}

					<p className='loginStyle__errorMessage'>{loginErrorMessage.message}</p>
					{sendCodeSuccessMessage && (
						<p className='loginStyle__successMessage'>
							<FontAwesomeIcon className='generalStyles__success generalStyles__mrFix' icon={faCircleCheck} />
							Verification Code Seding Successfully
						</p>
					)}
				</>
			) : (
				<>
					{" "}
					<CustomAmplifyBackToLogin />
				</>
			)}
		</div>
	);
}

export default CustomAmplifyForgotPassword;
