import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Select } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import { useLazyGetMessagesQuery } from "../../../redux/messages/messagesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function AssignMessage({ messageId, setMessageId }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [messages, setMessages] = useState<any>([]);
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetMessages, { data }] = useLazyGetMessagesQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const initData = async () => {
		const token = await GETJwtToken();
		triggerGetMessages({
			token,
			page: 1,
			limit: 9999,
		});
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		if (data && data.data) {
			setMessages(data.data);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Divider orientation='left' className='generalStyles__drawerDivider'>
				<h5>
					<FontAwesomeIcon icon={faServer} className='generalStyles__info generalStyles__mrFix' />
					{t("key.assignToMessage")}
				</h5>
			</Divider>
			<div className={`drawer__box__${theme}`}>
				{/** ---------------------------------------------------- */}
				{/** MESSAGES */}
				<div className='col-md-12'>
					<div className='generalStyles__flex'>
						<div className='generalStyles__inputFlexNameLarge'>
							<span className='generalStyles__inputFlexRequired'>*</span>
							<span>{t("header.message")}</span>
						</div>
						<div className='generalStyles__width100'>
							<Select
								className='generalStyles__width100'
								size='small'
								onChange={(e: any) => {
									setMessageId(e);
								}}
								value={messageId}
								showSearch
								options={messages.map((item: any) => ({
									value: item.id,
									label: item.short_message,
								}))}
								filterOption={(input, option) =>
									(option?.label?.toString().toUpperCase() ?? "").includes(input.toUpperCase())
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label?.toString().toUpperCase() ?? "")
										.toLowerCase()
										.localeCompare((optionB?.label?.toString().toUpperCase() ?? "").toLowerCase())
								}
							/>
						</div>
						<div className='generalStyles__infoTooltipIconBlank' />
					</div>
				</div>
			</div>
		</div>
	);
}

export default AssignMessage;
