import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Button, Input, Select, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenFormSpecialSwitch } from "../../../redux/tools/toolsSlice";
import {
	useLazyGetAllSpecialSwitchesCatalogQuery,
	usePostSpecialSwitchMutation,
	usePutSpecialSwitchMutation,
} from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

const { Option } = Select;

function SpecialSwitchesForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>({
		shortName: "",
		name: "",
		catalogId: "",
	});
	const [specialSwitchesCatalog, setSpecialSwitchesCatalog] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { specialSwitch, openFormSpecialSwitch, openFormSpecialSwitchAction } = useSelector((state: any) => state.tool);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetSpecialSwitchesCatalog,
		{ data: dataSpecialSwitchesCatalog, isLoading: isLoadingGetSpecialSwitchesCatalogData },
	] = useLazyGetAllSpecialSwitchesCatalogQuery();

	const [
		postSpecialSwitchCatalog,
		{ isSuccess: isSuccessPost, isLoading: isLoadingPost, isError: isErrorPost, error: errorPost, reset: resetPost },
	] = usePostSpecialSwitchMutation();
	const [
		putSpecialSwitchCatalog,
		{ isSuccess: isSuccessPut, isLoading: isLoadingPut, isError: isErrorPut, error: errorPut, reset: resetPut },
	] = usePutSpecialSwitchMutation();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenFormSpecialSwitch(false));
	};

	const onChangeName = (newValue: string) => {
		const copy = structuredClone(data);
		copy.name = newValue;
		setData(copy);
	};

	const onChangeShortName = (newValue: string) => {
		const copy = structuredClone(data);
		copy.shortName = newValue;
		setData(copy);
	};

	const onChangeCatalogId = (newValue: string) => {
		const copy = structuredClone(data);
		copy.catalogId = newValue;
		setData(copy);
	};

	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetSpecialSwitchesCatalog({
			token,
		});
	};

	const onFill = () => {
		setData({
			shortName: specialSwitch.short_message,
			name: specialSwitch.message,
			catalogId: specialSwitch.special_catalog_id,
		});
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		if (openFormSpecialSwitchAction === "create") {
			const BODY = {
				token,
				body: {
					short_message: data.shortName,
					message: data.name,
					special_catalog_id: data.catalogId,
					bit: 0,
					byte: 0,
				},
			};
			postSpecialSwitchCatalog(BODY);
		} else if (openFormSpecialSwitchAction === "update") {
			const BODY = {
				token,
				id: specialSwitch.id,
				body: {
					short_message: data.shortName,
					message: data.name,
					bit: 0,
					byte: 0,
					special_catalog_id: data.catalogId,
				},
			};
			putSpecialSwitchCatalog(BODY);
		}
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getInitialData();
		if (openFormSpecialSwitch) {
			resetPost();
			resetPut();
			if (openFormSpecialSwitchAction === "update") {
				onFill();
			} else {
				setData({ shortName: "", name: "", catalogId: "" });
			}
		}
	}, [openFormSpecialSwitch]);

	useEffect(() => {
		if (dataSpecialSwitchesCatalog && dataSpecialSwitchesCatalog.data) {
			setSpecialSwitchesCatalog(dataSpecialSwitchesCatalog.data);
		}
	}, [dataSpecialSwitchesCatalog]);

	useEffect(() => {
		onClickClose();
	}, [isSuccessPost, isErrorPost, isSuccessPut, isErrorPut]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width={isMobile ? "95%" : "35%"}
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openFormSpecialSwitch}
		>
			<Spin spinning={isLoadingGetSpecialSwitchesCatalogData || isLoadingPost || isLoadingPut}>
				{/** ************************************************* */}
				{/** SPECIAL SWITCH FORM */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon icon={faGear} className='generalStyles__info generalStyles__mrFix' />
							{openFormSpecialSwitchAction === "create"
								? t("configuration.specialSwitchesConfiguration.createNewSpecialSwitch")
								: t("configuration.specialSwitchesConfiguration.showRootConfiguration")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme}`}>
					{/** NAME */}
					<div className='col-md-12'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span className='drawer__text'>{t("general.name")}</span>
							</div>
							<div className='generalStyles__width100'>
								<Input
									size='small'
									value={data.name}
									onChange={(e) => {
										onChangeName(e.currentTarget.value);
									}}
								/>
							</div>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
					{/** SHORT NAME */}
					<div className='col-md-12 mt-3'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span className='drawer__text'>{t("general.shortName")}</span>
							</div>
							<div className='generalStyles__width100'>
								<Input
									size='small'
									value={data.shortName}
									onChange={(e) => {
										onChangeShortName(e.currentTarget.value);
									}}
								/>
							</div>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
				</div>
				<br />
				{/** ************************************************* */}
				{/** SPECIAL SWITCH CATALOG CONFIGURATION */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon icon={faGear} className='generalStyles__info generalStyles__mrFix' />
							{t("general.configuration")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme}`}>
					{/** CATALOG */}
					<div className='col-md-12 mt-3'>
						<div className='generalStyles__flex'>
							<div className='generalStyles__inputFlexName'>
								<span className='generalStyles__inputFlexRequired'>*</span>
								<span>{t("general.configuration")}</span>
							</div>
							<div className='generalStyles__width100'>
								<Select
									size='small'
									className='generalStyles__width100'
									value={data.catalogId}
									onChange={onChangeCatalogId}
									style={{ marginLeft: "-7px", marginBottom: "5px" }}
								>
									{specialSwitchesCatalog.map((item: any) => (
										<Option key={item.id} value={item.id}>
											{item.name}
										</Option>
									))}
								</Select>
							</div>
							<div className='generalStyles__infoTooltipIconBlank' />
						</div>
					</div>
				</div>
				{/** ************************************************* */}
				{/** CLOSE AND SUBMIT BUTTONS */}
				<div className='mt-4 generalStyles__width100'>
					<div>
						<Button onClick={onClickClose} className='buttonStyle__3'>
							{t("organizationManagement.close")}
						</Button>
						<Button
							onClick={onClickSave}
							className='buttonStyle__3'
							type='primary'
							disabled={data.shortName === "" || data.name === "" || data.catalogId === ""}
						>
							{t("general.save")}
						</Button>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPost}
				isSuccess={isSuccessPost}
				requestType='POST'
				error={errorPost}
				name='Special Switch'
			/>
			<GlobalAlert2
				isError={isErrorPut}
				isSuccess={isSuccessPut}
				requestType='PUT'
				error={errorPut}
				name='Special Switch'
			/>
		</Drawer>
	);
}

export default SpecialSwitchesForm;
