import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { DatePicker } from "antd";
// MOMENT DATE FORMAT
import moment from "moment";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterDateQuarter() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters, blockFilters } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChange = (date: any, dateString: any) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		// eslint-disable-next-line no-unused-vars
		const [yearStart, quarterStart] = dateString.split("-Q");
		// eslint-disable-next-line no-unused-vars
		const [yearEnd, quarterEnd] = dateString.split("-Q");
		// Determine the first month of the quarter
		let firstMonthOfQuarter: any;
		// eslint-disable-next-line default-case
		switch (parseInt(quarterStart, 10)) {
			case 1: // Q1
				firstMonthOfQuarter = 1; // January
				break;
			case 2: // Q2
				firstMonthOfQuarter = 4; // April
				break;
			case 3: // Q3
				firstMonthOfQuarter = 7; // July
				break;
			case 4: // Q4
				firstMonthOfQuarter = 10; // October
				break;
		}
		let lastMonthOfQuarter: any;
		// eslint-disable-next-line default-case
		switch (parseInt(quarterEnd, 10)) {
			case 1: // Q1
				lastMonthOfQuarter = 3; // March
				break;
			case 2: // Q2
				lastMonthOfQuarter = 6; // June
				break;
			case 3: // Q3
				lastMonthOfQuarter = 9; // October
				break;
			case 4: // Q4
				lastMonthOfQuarter = 12; // December
				break;
		}
		copy.startDate = moment()
			.month(firstMonthOfQuarter - 1)
			.startOf("month")

			.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		copy.endDate = moment()
			.month(lastMonthOfQuarter - 1)
			.endOf("month")

			.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		dispatch(updateFilters(copy));
	};

	const generateDefaultQuarterDate = () => {
		const currentQuarter = moment().quarter();
		let firstMonthOfQuarter: any;
		let lastMonthOfQuarter: any;
		// eslint-disable-next-line default-case
		switch (currentQuarter) {
			case 1: // Q1
				firstMonthOfQuarter = "1"; // January
				lastMonthOfQuarter = "3"; // March
				break;
			case 2: // Q2
				firstMonthOfQuarter = "4"; // April
				lastMonthOfQuarter = "6"; // June
				break;
			case 3: // Q3
				firstMonthOfQuarter = "7"; // July
				lastMonthOfQuarter = "9"; // September
				break;
			case 4: // Q4
				firstMonthOfQuarter = "10"; // October
				lastMonthOfQuarter = "12"; // December
				break;
		}
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.startDate = moment()
			.month(firstMonthOfQuarter - 1)
			.startOf("month")

			.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		copy.endDate = moment()
			.month(lastMonthOfQuarter - 1)
			.endOf("month")

			.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		generateDefaultQuarterDate();
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************* */}
			{/** BODY */}
			<DatePicker
				picker='quarter'
				className='generalStyles__width100'
				style={{ height: "24px" }}
				onChange={onChange}
				value={moment(filters.startDate)}
				allowClear={false}
				disabled={blockFilters}
			/>
		</div>
	);
}

export default ReportsFilterDateQuarter;
