import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Button, Select, Input, InputNumber, Switch, Spin, Drawer } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faUserShield,
	faBuildingCircleCheck,
	faUserLock,
	faCircleCheck,
	faCircleExclamation,
	faGear,
	faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateOpenModalCreateNewConfigurationFile,
	updateDataBlockData,
} from "../../../redux/configurationFiles/configurationFilesSlice";
import {
	usePostConfigurationMutation,
	usePostDataBlockCompleteMutation,
	useLazyGetVendorsQuery,
} from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import ConfigurationFilesDataBlock from "./ConfigurationFilesDataBlock";
import GlobalAlert2 from "../../home/GlobalAlert2";

library.add(faUserShield, faBuildingCircleCheck, faUserLock);

function ConfigurationFilesCreateForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [numberOfLifts, setNumberOfLifts] = useState(1);
	const [numberOfStops, setNumberOfStops] = useState(1);
	const [numberOfFloors, setNumberOfFloors] = useState(2);
	const [rearDoors, setRearDoors] = useState(true);
	const [type, setType] = useState("ELEVATOR");
	const [name, setName] = useState("");
	const [cleanDataBlockData, setCleanDataBlockData] = useState(false);
	const [vendorsCatalog, setVendorsCatalog] = useState<any>([]);
	const [vendor, setVendor] = useState("");
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openModalCreateNewConfigurationFile, dataBlocksData } = useSelector((state: any) => state.configurationFile);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		postConfiguration,
		{
			isSuccess: isSuccessPostConfiguration,
			isLoading: isLoadingPostConfiguration,
			isError: isErrorPostConfiguration,
			error: errorPostConfiguration,
		},
	] = usePostConfigurationMutation();

	const [postDataBlock] = usePostDataBlockCompleteMutation();

	const [triggerGetVendors, { data: dataVendors, isLoading: isLoadingVendors }] = useLazyGetVendorsQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const cleanData = () => {
		setNumberOfLifts(1);
		setNumberOfStops(1);
		setRearDoors(true);
		setType("ELEVATOR");
		setName("");
		setVendor("");
		dispatch(updateDataBlockData([]));
		setCleanDataBlockData(true);
		setTimeout(() => {
			setCleanDataBlockData(false);
		}, 500);
	};

	const onClickClose = () => {
		dispatch(updateOpenModalCreateNewConfigurationFile(false));
		cleanData();
	};

	const triggerPostDataBlock = async (configurationID: string) => {
		if (dataBlocksData && dataBlocksData.length > 0) {
			const token = await GETJwtToken();
			dataBlocksData.map((dataBlock: any) => {
				const copy = structuredClone(dataBlock);
				copy.configuration_id = configurationID;
				const BODY = {
					token,
					body: copy,
				};
				postDataBlock(BODY);
				return true;
			});
		}
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		const BODY = {
			token,
			body: {
				lift_type: type,
				lifts: numberOfLifts,
				name,
				rear_door: type === "ELEVATOR" ? rearDoors : false,
				stops: type === "ELEVATOR" ? numberOfStops : 0,
				vendor_id: vendor,
			},
		};
		const response = await postConfiguration(BODY).unwrap();
		if (response && response.data && response.data.id) {
			triggerPostDataBlock(response.data.id);
		}
	};

	const onChangeName = (newValue: string) => {
		setName(newValue);
	};

	const onChangeLifts = (newValue: number) => {
		setNumberOfLifts(newValue);
	};

	const onChangeFloorCount = (newValue: number) => {
		setNumberOfFloors(newValue);
	};

	const onChangeRearDoors = (newValue: boolean) => {
		setRearDoors(newValue);
	};

	const onChangeType = (newValue: string) => {
		setType(newValue);
	};

	const onChangeVendor = (newValue: string) => {
		setVendor(newValue);
	};

	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetVendors({
			token,
		});
	};

	const disableSaveButton = () => {
		let disableButton = false;
		if (vendor === "" || name === "") {
			disableButton = true;
		}
		return disableButton;
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(() => {
		if (!openModalCreateNewConfigurationFile) {
			cleanData();
		}
	}, [openModalCreateNewConfigurationFile]);

	useEffect(() => {
		setNumberOfStops(numberOfFloors);
	}, [numberOfFloors, numberOfLifts]);

	useEffect(() => {
		onClickClose();
	}, [isSuccessPostConfiguration, isErrorPostConfiguration]);

	useEffect(() => {
		if (dataVendors && dataVendors.data) {
			setVendorsCatalog(dataVendors.data);
		}
	}, [dataVendors]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='550'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openModalCreateNewConfigurationFile}
		>
			<div>
				<Spin spinning={isLoadingPostConfiguration || isLoadingVendors}>
					{/** ---------------------------------------------------- */}
					{/** CONFIGURATION FILES INIT DATA */}
					<div>
						<Divider orientation='left' className='generalStyles__drawerDivider'>
							<h5>
								<FontAwesomeIcon icon={faGear} className='generalStyles__info generalStyles__mrFix' />
								{t("configuration.configurationFiles.createNewConfigurationFile")}
							</h5>
						</Divider>
					</div>
					<div className='container'>
						<div className={`drawer__box__${theme}`}>
							<div className='row'>
								{/** NAME */}
								<div className='col-md-12'>
									<div className='generalStyles__flex'>
										<div className='generalStyles__inputFlexName'>
											<span className='generalStyles__inputFlexRequired'>*</span>
											<span>{t("configuration.configurationFiles.name")}</span>
										</div>
										<div className='generalStyles__width100'>
											<Input
												className='generalStyles__input'
												value={name}
												onChange={(e) => {
													onChangeName(e.target.value);
												}}
												size='small'
											/>
										</div>
										<div className='generalStyles__infoTooltipIconBlank' />
									</div>
								</div>
								{/** TYPE */}
								<div className='col-md-12 mt-3'>
									<div className='generalStyles__flex'>
										<div className='generalStyles__inputFlexName'>
											<span className='generalStyles__inputFlexRequired'>*</span>
											<span>{t("configuration.configurationFiles.type")}</span>
										</div>
										<div className='generalStyles__width100'>
											<Select value={type} onChange={onChangeType} className='generalStyles__width100' size='small'>
												{["ELEVATOR", "ESCALATOR", "MOVINGWALK"].map((item: any) => (
													<Option key={item} value={item}>
														{item}
													</Option>
												))}
											</Select>
										</div>
										<div className='generalStyles__infoTooltipIconBlank' />
									</div>
								</div>
								{/** LIFTS */}
								<div className='col-md-12 mt-3'>
									<div className='generalStyles__flex'>
										<div className='generalStyles__inputFlexName'>
											<span className='generalStyles__inputFlexRequired'>*</span>
											<span>{t("configuration.configurationFiles.lifts")}</span>
										</div>
										<div className='generalStyles__width100'>
											<InputNumber
												min={1}
												max={999}
												precision={0}
												value={numberOfLifts}
												onChange={(e: any) => {
													onChangeLifts(e);
												}}
												size='small'
												className='generalStyles__width100'
												style={{ width: "100%" }}
											/>
										</div>
										<div className='generalStyles__infoTooltipIconBlank' />
									</div>
								</div>
								{type === "ELEVATOR" && (
									<>
										{/** FLOOR COUNT */}
										<div className='col-md-12 mt-3'>
											<div className='generalStyles__flex'>
												<div className='generalStyles__inputFlexName'>
													<span className='generalStyles__inputFlexRequired'>*</span>
													<span>{t("edgeManagement.banks.floors")}</span>
												</div>
												<div className='generalStyles__width100'>
													<InputNumber
														min={2}
														max={999}
														precision={0}
														value={numberOfFloors}
														onChange={(e: any) => {
															onChangeFloorCount(e);
														}}
														size='small'
														className='generalStyles__width100'
														style={{ width: "100%" }}
													/>
												</div>
												<div className='generalStyles__infoTooltipIconBlank' />
											</div>
										</div>
									</>
								)}
								{/** REAR DOORS */}
								{type === "ELEVATOR" && (
									<div className='col-12 mt-3'>
										<div className='generalStyles__flex'>
											<div className='generalStyles__inputFlexName'>
												<span className='generalStyles__inputFlexRequired'>*</span>
												<span>{t("configuration.configurationFiles.rearDoors")}</span>
											</div>
											<div className='generalStyles__width100'>
												<Switch
													onChange={onChangeRearDoors}
													checked={rearDoors}
													checkedChildren={<FontAwesomeIcon icon={faCircleCheck} />}
													unCheckedChildren={<FontAwesomeIcon icon={faCircleExclamation} />}
												/>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
						<br />
					</div>
					{/** ---------------------------------------------------- */}
					{/** VENDOR */}
					<div>
						<Divider orientation='left' className='generalStyles__drawerDivider'>
							<h5>
								<FontAwesomeIcon icon={faUserCircle} className='generalStyles__info generalStyles__mrFix' />
								{t("configuration.vendor.vendor")}
							</h5>
						</Divider>
					</div>
					<div className='container'>
						<div className={`drawer__box__${theme}`}>
							<div className='col-md-12 mt-3'>
								<div className='generalStyles__flex'>
									<div className='generalStyles__inputFlexName'>
										<span className='generalStyles__inputFlexRequired'>*</span>
										<span>{t("configuration.vendor.vendor")}</span>
									</div>
									<div className='generalStyles__width100'>
										<Select className='generalStyles__width100' size='small' value={vendor} onChange={onChangeVendor}>
											{vendorsCatalog.map((item: any) => (
												<Option key={item.id} value={item.id}>
													{item.name}
												</Option>
											))}
										</Select>
									</div>
									<div className='generalStyles__infoTooltipIconBlank' />
								</div>
							</div>
						</div>
					</div>
					{/** ---------------------------------------------------- */}
					{/** DATA BLOCKS */}
					<br />
					<div className='container'>
						<ConfigurationFilesDataBlock numberOfLifts={numberOfLifts} cleanDataBlockData={cleanDataBlockData} />
					</div>
					{/** ---------------------------------------------------- */}
					{/** CLOSE AND SUBMIT BUTTONS */}
					<div className='mt-5'>
						<div>
							<Button type='default' onClick={onClickClose} className='buttonStyle__3'>
								{t("organizationManagement.close")}
							</Button>
							<Button onClick={onClickSave} className='buttonStyle__3' type='primary' disabled={disableSaveButton()}>
								{t("general.save")}
							</Button>
						</div>
					</div>
					<br />
				</Spin>
			</div>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostConfiguration}
				isSuccess={isSuccessPostConfiguration}
				requestType='POST'
				error={errorPostConfiguration}
				name='Configuration'
			/>
		</Drawer>
	);
}

export default ConfigurationFilesCreateForm;
