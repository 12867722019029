import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import VendorsTable from "./VendorsTable";
import VendorsForm from "./VendorsForm";
import VendorsDeleteModal from "./VendorsDeleteModal";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

// eslint-disable-next-line arrow-body-style
const VendorsPage = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("view-tool-management-vendors", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<VendorsTable />
					<VendorsForm />
					<VendorsDeleteModal />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default VendorsPage;
