import React from "react";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { faMessage } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import DrawerRowDivider from "./DrawerRowDivider";
import DrawerRowItem from "./DrawerRowItem";
// INTERFACES
import { DrawerMessageTypeInformationInterface } from "../../../interfaces/MessageType.interface";
import { RootState } from "../../../app/store";

function DrawerMessageTypeInformation({ data }: DrawerMessageTypeInformationInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: RootState) => state.home);
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faMessage} title={t("sideBar.messageType")} />
			{/** ************************************************* */}
			<div className={`drawer__box__${theme} mt-3`}>
				{/* ID */}
				<DrawerRowItem title='ID' value={data && data.id ? data.id : "--"} />
				{/* NAME */}
				<DrawerRowItem title={t("general.name")} value={data && data.name ? data.name : "--"} />
			</div>
		</div>
	);
}

export default DrawerMessageTypeInformation;
