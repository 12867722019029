import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// REDUX
import { useGetCountriesMutation } from "../../../redux/countriesStatesCitiesAPI";
// INTERFACES
import { FormSelectCountryInterface } from "../../../interfaces/Global.interface";

function FormInputSelectCountry({
	inputValue,
	inputOnChange,
	inputLabelName,
	inputVariableName,
}: FormSelectCountryInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [listCountries, setListCountries] = useState<any[]>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetCountries, { data: countries }] = useGetCountriesMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		triggerGetCountries({});
	}, []);

	useEffect(() => {
		if (countries && countries.data && countries.data.length > 0) {
			setListCountries(countries.data);
		}
	}, [countries]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='col-md-12 mt-3'>
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span>{inputLabelName}</span>
				</div>
				<div className='generalStyles__width100'>
					<Select
						className='generalStyles__width100'
						showSearch
						optionFilterProp='children'
						onChange={(event: any) => {
							inputOnChange(event, inputVariableName);
						}}
						filterOption={(input: any, option: any) =>
							option.children.toLowerCase().includes(input.toLowerCase())
						}
						size='small'
						value={inputValue}
					>
						{listCountries.map((c) => (
							<Option key={c.name} value={c.name}>
								{c.name}
							</Option>
						))}
					</Select>
				</div>
				<div className='generalStyles__infoTooltipIconBlank' />
			</div>
		</div>
	);
}

export default FormInputSelectCountry;
