import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Select, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { useLazyGetDataBlockTypesQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
import { updateDataBlockData } from "../../../redux/configurationFiles/configurationFilesSlice";
// COMPONENTS
import ConfigurationFilesSelectOffsets from "./ConfigurationFilesSelectOffsets";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function ConfigurationFilesDataBlock({ numberOfLifts, cleanDataBlockData }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);
	const dispatch = useDispatch();
	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [listSelectedDataBlocks, setListSelectedDataBlocks] = useState([]);
	const [dataBlocks, setDataBlocks] = useState<any[]>([]);
	const [selectedItemsDataBlocks, setSelectedItemsDataBlocks] = useState<string[]>([]);
	const filteredOptionsDataBlocks = dataBlocks.filter((o) => !selectedItemsDataBlocks.includes(o));
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetDataBlockTypes, { data: dataBlockTypes, isLoading }] = useLazyGetDataBlockTypesQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const buildSelectedDataBlocksObj = (items: any) => {
		if (items.length > 0) {
			const newArr: any = [];
			items.map((item: any) => {
				newArr.push({
					name: item,
					data: [],
				});
				return true;
			});
			setListSelectedDataBlocks(newArr);
		} else {
			dispatch(updateDataBlockData([]));
		}
	};

	const onChangeDataBlockSelect = (newSelectedItemsObj: any) => {
		setSelectedItemsDataBlocks(newSelectedItemsObj);
		buildSelectedDataBlocksObj(newSelectedItemsObj);
	};

	const onChangeOffsets = (data: any) => {
		if (data && data.dataBlock && listSelectedDataBlocks) {
			const index = listSelectedDataBlocks.findIndex((x: any) => x.name === data.dataBlock);
			if (index >= 0) {
				const copy: any = structuredClone(listSelectedDataBlocks);
				copy[index].data = data.data;
				setListSelectedDataBlocks(copy);
			}
		}
	};

	const buildDataBlockObject = () => {
		const dataBlocksData: any = [];
		if (listSelectedDataBlocks && listSelectedDataBlocks.length > 0) {
			listSelectedDataBlocks.map((block: any) => {
				// Find index to get data block id
				const index = dataBlocks.findIndex((x: any) => x.name === block.name);
				// Data block Object
				const blockData: any = {
					banks: [],
					configuration_id: "",
					data_block_type_id: dataBlocks[index].id,
					definitions: [],
					offsets: [],
				};
				// Build offsets per data block
				for (let indexData = 0; indexData < numberOfLifts; indexData += 1) {
					block.data.map((blockOffset: any, indexBlockOffset: number) => {
						blockData.offsets.push({
							byte_count: blockOffset[`total${blockOffset.name}`],
							data_block_id: dataBlocks[index].id,
							definitions: [],
							definitions2delete: [],
							exceptions: "",
							initial_value: 0,
							last_value: 0,
							type: blockOffset.name,
							name: `Car ${indexBlockOffset + 1}`,
							offset_type_id: blockOffset.offsetTypeId,
							position: indexData + 1,
						});
						return true;
					});
				}
				dataBlocksData.push(blockData);
				return true;
			});
		}
		dispatch(updateDataBlockData(dataBlocksData));
	};

	const initData = async () => {
		const token = await GETJwtToken();
		triggerGetDataBlockTypes({
			token,
		});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		if (dataBlockTypes && dataBlockTypes.data) {
			setDataBlocks(dataBlockTypes.data);
		}
	}, [dataBlockTypes]);

	useEffect(() => {
		buildDataBlockObject();
	}, [listSelectedDataBlocks]);

	useEffect(() => {
		if (cleanDataBlockData) {
			setListSelectedDataBlocks([]);
			setSelectedItemsDataBlocks([]);
		}
	}, [cleanDataBlockData]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={isLoading}>
			{/** ************************************************* */}
			{/** DATA BLOCKS */}
			<div>
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon icon={faCube} className='generalStyles__info generalStyles__mrFix' />
							{t("configuration.dataBlock.dataBlock")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme}`}>
					{/* OPTIONS DATA BLOCKS */}
					<div className='generalStyles__flex'>
						<div>
							<p className='drawer__title mt-2'>{t("configuration.dataBlock.dataBlock")}</p>
						</div>
						{/** SELECT DATA BLOCKS */}
						<div className='drawer__textContainer generalStyles__width100'>
							<Select
								mode='multiple'
								value={selectedItemsDataBlocks}
								onChange={onChangeDataBlockSelect}
								className='generalStyles__width100'
								disabled={isLoading}
								options={filteredOptionsDataBlocks.map((item) => ({
									value: item.name,
									label: item.name,
								}))}
							/>
						</div>
					</div>
				</div>
				{/** ************************************************* */}
				{/** OFFSETS */}
				{selectedItemsDataBlocks.map((item: string, index: number) => (
					<div key={index}>
						<ConfigurationFilesSelectOffsets dataBlock={item} onChangeOffsets={onChangeOffsets} />
					</div>
				))}
			</div>
		</Spin>
	);
}

export default ConfigurationFilesDataBlock;
