import React from "react";
// ANT DESIGN COMPONENTS
import { Button, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// INTEFACES
import { ButtonSaveInterface } from "../../../interfaces/Components.interface";

function ButtonSave({ onClick, disabledButton }: ButtonSaveInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Tooltip title={disabledButton ? t("general.formIsNotValid") : ""}>
			<Button
				style={{ width: "100%" }}
				className={`${
					disabledButton ? "buttonStyle__inactive__1__dark" : "buttonStyle__17 "
				} generalStyles__width100`}
				onClick={() => {
					onClick();
				}}
				disabled={disabledButton}
			>
				{t("general.save")}
			</Button>
		</Tooltip>
	);
}

export default ButtonSave;
