import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import NotificationSchedulesTable from "./NotificationSchedulesTable";
import NotificationSchedulesDrawerManageSchedules from "./NotificationSchedulesDrawerManageSchedules";
// INTERFACES
import { RootState } from "../../../app/store";

function NotificationSchedulesPage() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`layout__contentPage__${theme} `}>
			<NotificationSchedulesTable />
			<NotificationSchedulesDrawerManageSchedules />
		</div>
	);
}

export default NotificationSchedulesPage;
