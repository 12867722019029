import React from "react";
// ANT DESIGN COMPONENTS
import { Button } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenModalCreateEditBank, updateClearFilters, updateFormAction } from "../../../redux/banks/bankSlice";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";

library.add(faPlus, faArrowsRotate);

function BanksHeader() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalCreateNewBank = () => {
		dispatch(updateOpenModalCreateEditBank(true));
		dispatch(updateFormAction("CREATE"));
	};

	const onClickCleanFilters = () => {
		dispatch(updateClearFilters(true));
		setTimeout(() => {
			dispatch(updateClearFilters(false));
		}, 1000);
	};

	return (
		<div className='generalStyles__pageHeader'>
			{validatePermission("banks-create", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<Button
						type='primary'
						icon={<FontAwesomeIcon icon={faPlus} className='generalStyles__mrFix' />}
						onClick={handleOpenModalCreateNewBank}
					>
						{t("edgeManagement.banks.createNewBank")}
					</Button>
				</div>
			)}
			{validatePermission("banks-view", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<Button
						className='buttonStyle__8'
						icon={<FontAwesomeIcon icon={faArrowsRotate} className='generalStyles__mrFix' />}
						onClick={() => {
							onClickCleanFilters();
						}}
					>
						{t("edgeManagement.banks.refreshTable")}
					</Button>
				</div>
			)}
		</div>
	);
}

export default BanksHeader;
