import React from "react";
// ANT DESIGN COMPONENTS
import { Button } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// INTEFACES
import { ButtonRefreshInterface } from "../../../interfaces/Components.interface";

function ButtonRefreshTable({ onClick, label }: ButtonRefreshInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Button
			className='buttonStyle__8 generalStyles__width150px'
			icon={<FontAwesomeIcon icon={faArrowsRotate} className='generalStyles__mrFix' />}
			onClick={() => {
				onClick();
			}}
		>
			{label}
		</Button>
	);
}

export default ButtonRefreshTable;
