import React, { useEffect } from "react";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateServerBreadcrumb,
	updateIdServerSelected,
	updateNameServerSelected,
} from "../../../redux/servers/serverSlice";
import {
	updateIdBankSelected,
	updateNameBankSelected,
	updateBankBreadcrumb,
} from "../../../redux/banks/bankSlice";
// COMPONENTS
import ServersTable from "./ServersTable";
import ServersHeader from "./ServersHeader";
import ServersCreateForm from "./ServersCreateForm";
import ServersDrawer from "./ServersDrawer";
import ServersDeleteMOdal from "./ServersDeleteModal";
import ServersEditForm from "./ServersEditForm";
import BanksTable from "../banks/BanksTable";
import BanksCreateForm from "../banks/BanksCreateForm";
import BanksHeader from "../banks/BanksHeader";
import LiftsTable from "../lifts/LiftsTable";
import LiftsHeader from "../lifts/LiftsHeader";
import LiftsCreateForm from "../lifts/LiftsCreateForm";
import BanksDrawer from "../banks/BanksDrawer";
import LiftsDrawer from "../lifts/LiftsDrawer";
import BanksDeleteModal from "../banks/BanksDeleteModal";
import BanksFloorNames from "../banks/BanksFloorNames";
import BanksDrawerLiftsPosition from "../banks/BanksDrawerLiftsPosition";
import BanksAssignActions from "../banks/BanksAssignActions";
import LiftsDeleteModal from "../lifts/LiftsDeleteModal";
import LiftsEditForm from "../lifts/LiftsEditForm";
import LiftsActivateDeactivate from "../lifts/LiftsActivateDeactivate";
import LiftsConfiguration from "../lifts/LifstConfiguration";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

const ServersPage = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);
	const { serverBreadcrumb } = useSelector((state: any) => state.server);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		dispatch(updateServerBreadcrumb([]));
		dispatch(updateBankBreadcrumb([]));
		dispatch(updateIdServerSelected(""));
		dispatch(updateIdBankSelected(""));
		dispatch(updateNameBankSelected(""));
		dispatch(updateNameServerSelected(""));
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("view-edge-management-servers", permissions) ? (
				<div>
					<div className={`layout__contentPage__${theme}`}>
						{serverBreadcrumb.length === 0 && (
							<>
								<ServersHeader />
								<ServersTable />
							</>
						)}
						{serverBreadcrumb.length === 1 && (
							<>
								<BanksHeader />
								<BanksTable renderComponent='serverPage' />
							</>
						)}
						{serverBreadcrumb.length === 2 && (
							<>
								<LiftsHeader />
								<LiftsTable renderComponent='bankPage' />
							</>
						)}
						<ServersCreateForm />
						<ServersDrawer />
						<BanksDrawer />
						<ServersDeleteMOdal />
						<ServersEditForm />
						<BanksCreateForm renderComponent='serverPage' />
						<BanksDeleteModal />
						<BanksFloorNames />
						<BanksDrawerLiftsPosition />
						<BanksAssignActions />
						<LiftsDrawer />
						<LiftsCreateForm />
						<LiftsDeleteModal />
						<LiftsEditForm />
						<LiftsActivateDeactivate />
						<LiftsConfiguration />
					</div>
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default ServersPage;
