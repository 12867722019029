import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import MapViewMenu from "./MapViewMenu";
import MapViewMap from "./MapViewMap";

// ANT DESIGN COMPONENTS

function MapViewConfigurationPage() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const navigate = useNavigate();

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { mapViewItem, mapViewPinArray } = useSelector(
    (state: any) => state.mapView
  );
  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  if (!mapViewItem || !mapViewPinArray) {
    navigate("/edge-management/map-view");
    return null;
  }

  // ************************************************ */
  // USE EFFECT ************************************* */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Row>
      {/** ************************* */}
      {/** BODY */}
      <Col span={18}>
        <MapViewMap />
      </Col>
      {/** ************************* */}
      {/** MENU */}
      <Col span={6}>
        <MapViewMenu />
      </Col>
    </Row>
  );
}

export default MapViewConfigurationPage;
