import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import PermissionsTable from "./PermissionsTable";
import PermissionsForm from "./PermissionsForm";
import PermissionsDeleteModal from "./PermissionsDeleteModal";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

function PermissionsPage() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("view-tool-management-permissions", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<PermissionsTable />
					<PermissionsForm />
					<PermissionsDeleteModal />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
}

export default PermissionsPage;
