import React from "react";
// ANT DESIGN COMPONENTS
import { Tooltip } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark, faInfoCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
// INTERFACES
import { MonitoringIconsStatusInterface } from "../../../interfaces/EdgeManagement.interface";

function MonitoringIconsStatus({ liftStatus, hasFaults, carService }: MonitoringIconsStatusInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div style={{ marginLeft: "1px" }}>
			{/** ********************************************************** */}
			{/** ACTIONS ************************************************** */}
			{/** BLANK STATUS */}
			{liftStatus === "" && (
				<FontAwesomeIcon
					icon={faCircleXmark}
					fontSize={13}
					style={{
						marginRight: "4px",
						marginLeft: "4px",
						color: "gray",
					}}
				/>
			)}
			{/** SUCCESS STATUS */}
			{liftStatus === "SUCCESS" && carService === "" && !hasFaults && (
				<FontAwesomeIcon
					icon={faCircleCheck}
					fontSize={13}
					style={{
						marginRight: "4px",
						marginLeft: "4px",
						color: "#7adc41",
					}}
				/>
			)}
			{/** SERVICE STATUS */}
			{carService !== "" && !hasFaults && (
				<Tooltip placement='top' title={carService}>
					<FontAwesomeIcon
						icon={faInfoCircle}
						fontSize={13}
						style={{
							marginRight: "4px",
							marginLeft: "4px",
							color: "#2968c0",
						}}
					/>
				</Tooltip>
			)}
			{/** SUCCESS ERROR */}
			{(liftStatus === "ERROR" || hasFaults) && (
				<FontAwesomeIcon
					className='generalStyles__error'
					icon={faWarning}
					fontSize={13}
					style={{
						marginRight: "4px",
						marginLeft: "4px",
					}}
				/>
			)}
		</div>
	);
}

export default MonitoringIconsStatus;
