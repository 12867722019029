import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import OrganizationTable from "./OrganizationsTable";
import OrganizationsHeader from "./OrganizationsHeader";
import OrganizationCreateEditForm from "./OrganizationCreateEditForm";
import OrganizationDrawer from "./OrganizationDrawer";
import OrganizationsDrawerHolidays from "./OrganizationsDrawerHolidays";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";

const OrganizationPage = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { permissions } = useSelector((state: RootState) => state.user);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission(
				"view-organization-management-organizations",
				permissions
			) ? (
				<div className={`layout__contentPage__${theme}`}>
					<OrganizationsHeader />
					<OrganizationTable />
					<OrganizationCreateEditForm />
					<OrganizationDrawer />
					<OrganizationsDrawerHolidays />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default OrganizationPage;
