/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Alert, Button, Spin, Table, Dropdown, Space, Menu, Empty, Tag } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faTrash, faPenToSquare, faBan, faSearch } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateIconDefinition,
	updateOpenDrawerIconDefinition,
	updateShowIconDefinitionTable,
	updateOpenFormIconDefinition,
	updateOpenFormIconDefinitionAction,
	updateOpenModalDeleteIconDefinition,
	updateIconDefinitions,
} from "../../../redux/tools/toolsSlice";
import { useLazyGetIconDefinitionsQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
// COMPONENTS
import IconDefinitionHeader from "./IconDefinitionHeader";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// FILTERS
import FilterSelectLiftType from "../../components/filters/FilterSelectLiftType";
// COMPONENTS
import IconDefinitionIcons from "./IconDefinitionIcons";

const { Column } = Table;

function IconDefinitionTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [filterLiftType, setFilterLiftType] = useState("");
	// eslint-disable-next-line no-unused-vars
	const [selectedItem, setSelectedItem] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<any>([]);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	const [
		triggerGetIconDefinition,
		{ data: dataIconDefinition, isLoading: isLoadingIconDefinition, isFetching: isFetchingIconDefinition },
	] = useLazyGetIconDefinitionsQuery();

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { iconDefinitionRefreshTable, showIconDefinitionTable } = useSelector((state: any) => state.tool);

	const manageActions = () => {
		const BLANK = {
			label: (
				<div className='generalStyles__tableActionBox'>
					<span>
						<FontAwesomeIcon className='generalStyles__error generalStyles__mrFix' icon={faBan} />
						{t("general.noActions")}
					</span>
				</div>
			),
			key: "0",
		};

		const EDIT = validatePermission("permission-not-defined", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalEdit();
							}}
							onKeyDown={() => {
								handleOpenModalEdit();
							}}
						>
							<span>
								<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faPenToSquare} />
								{t("organizationManagement.edit")}
							</span>
						</div>
					),
					key: "2",
			  }
			: null;

		const DELETE = validatePermission("permission-not-defined", permissions)
			? {
					label: (
						<div
							className='generalStyles__tableActionBox'
							role='button'
							tabIndex={0}
							onClick={() => {
								handleOpenModalDelete();
							}}
							onKeyDown={() => {
								handleOpenModalDelete();
							}}
						>
							<span>
								<FontAwesomeIcon className='generalStyles__info generalStyles__mrFix' icon={faTrash} />
								{t("organizationManagement.delete")}
							</span>
						</div>
					),
					key: "3",
			  }
			: null;

		// RETURN OBJECT WITH VALID ACTIONS
		if (EDIT || DELETE) {
			return [EDIT, DELETE];
		}
		return [BLANK];
	};

	const menu = <Menu items={manageActions()} />;

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickShowForm = () => {
		setIsLoading(true);
		dispatch(updateShowIconDefinitionTable(true));
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const handleOpenModalEdit = () => {
		dispatch(updateIconDefinition(selectedItem));
		dispatch(updateOpenFormIconDefinition(true));
		dispatch(updateOpenFormIconDefinitionAction("update"));
	};

	const handleOpenModalDelete = () => {
		dispatch(updateIconDefinition(selectedItem));
		dispatch(updateOpenModalDeleteIconDefinition(true));
	};

	const onCellClick = (record: any) => {
		if (record) {
			dispatch(updateIconDefinition(record));
			dispatch(updateOpenDrawerIconDefinition(true));
		}
	};

	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetIconDefinition({
			token,
		});
	};

	const refreshTable = async () => {
		getInitialData();
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getInitialData();
	}, [showIconDefinitionTable]);

	useEffect(() => {
		if (iconDefinitionRefreshTable) {
			refreshTable();
		}
	}, [iconDefinitionRefreshTable]);

	useEffect(() => {
		if (dataIconDefinition && dataIconDefinition.data) {
			setData(dataIconDefinition.data);
			dispatch(updateIconDefinitions(dataIconDefinition.data));
		}
	}, [dataIconDefinition]);

	// ************************************************ */
	// COMPONENT ************************************** */

	return (
		<div>
			{/** ----------------------------------------------------- */}
			{/** SHOW ALERT */}
			{!showIconDefinitionTable && (
				<div className='mb-4'>
					<Alert
						message={t("general.warning")}
						description={t("configuration.iconDefinition.warningDescription")}
						type='warning'
						showIcon
						closable
					/>
					<Button className='buttonStyle__2 configuration__editRootPermisionsButton' onClick={onClickShowForm}>
						{t("configuration.iconDefinition.showRootConfiguration")}
					</Button>
				</div>
			)}
			{/** ----------------------------------------------------- */}
			{/** ICON DEFINITION FORM */}
			{showIconDefinitionTable && (
				<Spin spinning={isLoading || isLoadingIconDefinition}>
					{validatePermission("permission-not-defined", permissions) ? (
						<>
							<IconDefinitionHeader />
							<Table
								rowClassName={(record, index) => getTableRowClass(index, theme)}
								dataSource={data}
								pagination={{
									defaultPageSize: 10,
									showSizeChanger: true,
									pageSizeOptions: ["10", "25", "50", "100"],
								}}
								size='small'
								className='mt-3'
								loading={isFetchingIconDefinition}
							>
								{/** TYPE -- */}
								<Column
									onCell={(record) => ({
										onClick: () => {
											onCellClick(record);
										},
									})}
									title={t("edgeManagement.lifts.type")}
									dataIndex='type'
									key='type'
									width='200px'
									filterIcon={<FontAwesomeIcon icon={faSearch} />}
									filterDropdown={() => (
										<FilterSelectLiftType
											placeHolder={t("general.liftType")}
											setFilterValue={setFilterLiftType}
											filterValue={filterLiftType}
											refreshTable={() => {
												refreshTable();
											}}
										/>
									)}
									render={(text, record: any) => (
										<>
											<div>
												{record.lift_type === "ELEVATOR" && (
													<Tag color='blue' className='drawer__typeTag'>
														{record.lift_type}
													</Tag>
												)}
												{record.lift_type === "ESCALATOR" && (
													<Tag color='orange' className='drawer__typeTag'>
														{record.lift_type}
													</Tag>
												)}
												{record.lift_type === "MOVINGWALK" && (
													<Tag color='green' className='drawer__typeTag'>
														{record.lift_type}
													</Tag>
												)}{" "}
											</div>
										</>
									)}
								/>
								{/** ICON */}
								<Column
									title='Icon'
									dataIndex='icon'
									key='icon'
									width='100px'
									onCell={(record) => ({
										onClick: () => {
											onCellClick(record);
										},
									})}
									render={(text, record: any) => (
										<div className='generalStyles__mlFix'>
											{true && (
												<IconDefinitionIcons
													isMap={false}
													expanded={false}
													liftType={record.lift_type}
													icon={record.icon}
													iconSize={20}
												/>
											)}
										</div>
									)}
								/>
								{/** ICON NAME */}
								<Column
									title='Icon Name'
									dataIndex='name'
									key='name'
									onCell={(record) => ({
										onClick: () => {
											onCellClick(record);
										},
									})}
									render={(text, record: any) => <div className='generalStyles__info'>{record.icon || "--"}</div>}
								/>

								{/** SHORT MESSAGE */}
								<Column
									title='Short Message'
									dataIndex='shortMessage'
									key='shortMessage'
									onCell={(record) => ({
										onClick: () => {
											onCellClick(record);
										},
									})}
									render={(text, record: any) => (
										<div className='generalStyles__info'>{record.short_message || "--"}</div>
									)}
								/>
								{/** MESSAGE */}
								<Column
									title='Message'
									dataIndex='message'
									key='message'
									onCell={(record) => ({
										onClick: () => {
											onCellClick(record);
										},
									})}
									render={(text) => <div>{text || "--"}</div>}
								/>
								{/** ACTIONS */}
								<Column
									title={t("edgeManagement.banks.actions")}
									key='action'
									render={(_: any, record: any) => (
										<Dropdown overlay={menu} trigger={["click"]}>
											<Space
												className='generalStyles__hoverCursor'
												onClick={() => {
													setSelectedItem(record);
												}}
											>
												<FontAwesomeIcon icon={faEllipsis} />
											</Space>
										</Dropdown>
									)}
								/>
							</Table>
						</>
					) : (
						<>
							<div className='generalStyles__noAccessToListTable'>
								<Empty
									description={t("organizationManagement.listNotAvailableOrganizations")}
									image={Empty.PRESENTED_IMAGE_SIMPLE}
								/>
							</div>
						</>
					)}
				</Spin>
			)}
		</div>
	);
}

export default IconDefinitionTable;
