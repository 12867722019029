import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { InputNumber } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterLimitNumber() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [inputValue, setInputValue] = useState<number>(10);
	const [debouncedValue, setDebouncedValue] = useState<number>(10);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters, blockFilters } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeLimitNumber = (newValue: number) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.limitNumber = newValue;
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(inputValue);
		}, 500);

		return () => {
			clearTimeout(handler);
		};
	}, [inputValue]);

	useEffect(() => {
		if (debouncedValue) {
			onChangeLimitNumber(debouncedValue);
		}
	}, [debouncedValue]);
	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<InputNumber
				placeholder={t("general.limit")}
				min={1}
				max={999}
				defaultValue={3}
				size='small'
				value={inputValue}
				addonBefore={t("general.limit")}
				onChange={(e: any) => {
					setInputValue(e);
				}}
				disabled={blockFilters}
			/>
		</div>
	);
}

export default ReportsFilterLimitNumber;
