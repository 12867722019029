import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
// ANT DESIGN COMPONENTS
import { Table, Empty, Tag, Spin, Tooltip, Button } from "antd";
// HTML CANVAS
import html2canvas from "html2canvas";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// TIMEZONE
import moment from "moment-timezone";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateOpenDrawerRows,
	updateReportRow,
	updateFilters,
	updateBlockFilters,
	updateOpenDrawerFilters,
} from "../../redux/reports/reportsSlice";
// AUTHORIZATION
import validatePermission from "../../utils/validatePermissions";
import GETJwtToken from "../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../utils/utils";
// COMPONENTS
import TableNoDataBox from "../components/table/TableNoDataBox";
import ReportsDrawer from "./drawer/ReportsDrawer";
import ReportsAlertDownloadModal from "./ReportsAlertDownloadModal";
// INTERFACES
import {
	ReportsTableInterface,
	ReportsListOfColumnsInterface,
	ReportSliceFiltersInterface,
} from "../../interfaces/Reports.interface";
import { RootState } from "../../app/store";

function ReportsTable({
	response,
	triggerGetReport,
	isLoading,
	isError,
	error,
	displayExpandibleRowTable,
	groupColumns,
	generateTableRequestBody,
	csvUrl,
}: ReportsTableInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();
	const CUSTOM_COLUM_NAMES: any = {
		up_time_target: "AVAILABILITY (%)",
		unavailability: "UNAVAILABILITY (%)",
		start_timestamp: "START TIME",
		end_timestamp: "END TIME",
		lift_name: "CAR",
		bank_name: "BANK",
		floor_name: "FLOOR",
		average_time: "AVERAGE TIME (s)",
	};
	const { Column, ColumnGroup } = Table;
	const tableRef = useRef(null);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>([]);
	const [downloadReport, setDownloadReport] = useState<boolean>(false);
	const [total, setTotal] = useState(0);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: RootState) => state.user);
	const {
		filters,
		openPdfViewer,
		chartReportTheme,
		reportRefreshTable,
		openDrawerFilters,
	} = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	/** Take all the values that comes from report API call and create
  a list with all the available columns */
	const extractKeys = (dinamicColumns: any) =>
		dinamicColumns.reduce((acc: any, obj: any) => {
			// Obtener las claves del objeto actual
			const objKeys = Object.keys(obj);

			// Agregar claves que no estén en el array acumulador
			objKeys.forEach((key) => {
				if (!acc.includes(key)) {
					acc.push(key);
				}
			});

			return acc;
		}, []);

	const buildListOfColumnsArray = () => {
		const newArr: ReportsListOfColumnsInterface[] = [];
		Object.keys(data[0]).forEach((key: any) => {
			const isArrayValidation = Array.isArray(data[0][key]);
			if (!(typeof data[0][key] === "object" || isArrayValidation)) {
				newArr.push({
					label: key,
					value: key,
				});
			}
			return true;
		});
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.tableListColumns = newArr;
		if (
			csvUrl ===
			`${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/service/statistics`
		) {
			const keys = extractKeys(data);
			copy.tableSelectedColumns = keys;
		} else {
			copy.tableSelectedColumns = filters.defaultColumns;
		}
		dispatch(updateFilters(copy));
	};

	const dinamicColumnTitle = (title: string) => {
		if (CUSTOM_COLUM_NAMES[title]) {
			return CUSTOM_COLUM_NAMES[title];
		}
		if (
			title === "0 - 10" ||
			title === "11 - 30" ||
			title === "31 - 50" ||
			title === "51 - 70" ||
			title === "71 - 90" ||
			title === "11 - 20" ||
			title === "31 - 40" ||
			title === "51 - 60" ||
			title === "41 - 50" ||
			title === "61 - 70" ||
			title === "71 - 80" ||
			title === "81 - 90" ||
			title === "91 - 120" ||
			title === "121 - 150" ||
			title === "151 - 210" ||
			title === "211 - 300" ||
			title === "300+" ||
			title === "90+"
		) {
			return `${title} (s)`;
		}
		return title.toUpperCase().replaceAll("_", " ");
	};

	const getData = async () => {
		const token = await GETJwtToken();
		const body = generateTableRequestBody({ downloadReport: "false" });
		triggerGetReport({
			token,
			body,
		});
	};

	const buildAndDownloadReport = async () => {
		const token: string = await GETJwtToken();
		setTimeout(() => {
			setDownloadReport(false);
		}, 1000);
		// Generate body
		const body = generateTableRequestBody({ downloadReport: "true" });
		// Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
		// eslint-disable-next-line no-return-await
		return await axios
			.post(csvUrl, body, {
				responseType: "blob",
				headers: {
					Authorization: token,
					"Content-type": "application/json",
				},
			})
			.then((res: any) => {
				const data1 = new Blob([res?.data], {
					type: "application / vnd. MS Excel",
				});
				const csvURL = window.URL.createObjectURL(data1);
				const tempLink = document.createElement("a");
				tempLink.href = csvURL;
				tempLink.setAttribute(
					"download",
					`Liftnet ${t("general.report")} ${moment().format(
						"MMMM Do YYYY, h:mm:ss a"
					)}.csv`
				);
				tempLink.click();
			});
	};

	const refreshTable = async () => {
		getData();
		dispatch(updateOpenDrawerFilters(false));
	};

	const formatDateColumn = (value: string) =>
		moment(value).format("MMMM Do YYYY, h:mm:ss a");
	const buildDataObject = (newData: any) => {
		const newArr: any = [];
		const formatDates = [
			"created_at",
			"synced_at",
			"start_timestamp",
			"end_timestamp",
			"timestamp",
			"out_of_service_timestamp",
		];
		newData.map((record: any) => {
			const newObject = { ...record };
			// Format dates
			formatDates.map((d: string) => {
				if (record[d]) {
					if (record[d] !== "0001-01-01T00:00:00Z" && record[d] !== "-") {
						newObject[d] = formatDateColumn(record[d]);
					} else {
						newObject[d] = "--";
					}
				}
				return true;
			});
			if (record.msg) {
				Object.keys(record.msg).forEach((key: any) => {
					newObject[key] = record.msg[key];
					return true;
				});
			}
			newArr.push(newObject);
			return true;
		});
		setData(newArr);
	};

	const onCellNameClick = (record: any) => {
		if (record) {
			dispatch(updateOpenDrawerRows(true));
			dispatch(updateReportRow(record));
		}
	};

	const captureChartAsImage = () => {
		if (tableRef.current) {
			html2canvas(tableRef.current).then((canvas) => {
				const imgData: any = canvas.toDataURL("image/png");
				const copy: ReportSliceFiltersInterface = structuredClone(filters);
				copy.urlChartImage = imgData;
				dispatch(updateFilters(copy));
			});
		}
	};

	const customExpandIcon = ({ expanded, onExpand, record }: any) => (
		<Tooltip title={t("general.interruptions")}>
			<Button
				onClick={(e) => onExpand(record, e)}
				style={{ cursor: "pointer" }}
				shape='circle'
			>
				{expanded ? "-" : "+"}
			</Button>
		</Tooltip>
	);

	const expandedRowRender = (record: any) => (
		<Table
			className='customTable'
			dataSource={record.interruptions}
			rowKey={(record2: any) => record2.id}
			size='small'
			pagination={false}
		>
			{/** DATE -- */}
			<Column
				title={t("general.date")}
				dataIndex='date'
				key='date'
				render={(text) => <div>{text || "--"}</div>}
			/>
			{/** DURATION -- */}
			<Column
				title={t("general.duration")}
				dataIndex='duration'
				key='duration'
				render={(text) => <div>{text || "--"}</div>}
			/>
			{/** FLOOR NAME -- */}
			<Column
				title={t("general.floorName")}
				dataIndex='floor_name'
				key='floor_name'
				render={(text) => <div>{text || "--"}</div>}
			/>
			{/** MESSAGE -- */}
			<Column
				title={t("general.message")}
				dataIndex='message'
				key='message'
				render={(text) => <div>{text || "--"}</div>}
			/>
		</Table>
	);

	const launchRequest = () => {
		if (
			filters.startDate !== "" &&
			filters.endDate !== "" &&
			filters.timezoneCode !== "" &&
			!isLoading
		) {
			// Check if server id is valid
			if (
				csvUrl ===
					`${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/all-banks/out-of-service` ||
				csvUrl ===
					`${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/all-banks/performance` ||
				csvUrl ===
					`${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/all-banks/escalators-use` ||
				csvUrl ===
					`${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/frequency/lift` ||
				csvUrl ===
					`${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/frequency/fault`
			) {
				if (filters.serverId !== "") {
					refreshTable();
				}
			} else {
				// eslint-disable-next-line no-lonely-if
				if (filters.bankId !== "") {
					refreshTable();
				}
			}
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (data && data.length > 0) {
			buildListOfColumnsArray();
		}
	}, [data]);

	useEffect(() => {
		if (response) {
			if (response.data) {
				buildDataObject(response.data);
			} else if (response.results) {
				buildDataObject(response.results);
			} else {
				buildDataObject([]);
			}
			// Save total
			if (response.total) {
				setTotal(response.total);
			}
		}
	}, [response]);

	useEffect(() => {
		if (isError) {
			setData([]);
		}
	}, [isError]);

	useEffect(() => {
		if (!openDrawerFilters) {
			launchRequest();
		}
	}, [
		filters.startDate,
		filters.endDate,
		filters.serverId,
		filters.bankId,
		filters.weeksNumber,
		filters.limitNumber,
	]);

	useEffect(() => {
		if (downloadReport) {
			buildAndDownloadReport();
		}
	}, [downloadReport]);

	useEffect(() => {
		if (openPdfViewer) {
			captureChartAsImage();
		}
	}, [openPdfViewer]);

	useEffect(() => {
		dispatch(updateBlockFilters(isLoading));
	}, [isLoading]);

	useEffect(() => {
		if (reportRefreshTable) {
			launchRequest();
		}
	}, [reportRefreshTable]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={openPdfViewer}>
			<div
				style={{ opacity: openPdfViewer ? 0 : 1 }}
				className={`${
					chartReportTheme === "light" ? "custom-light-table" : ""
				} `}
			>
				{validatePermission("permission-not-defined", permissions) ? (
					<>
						{/** ******************************************* */}
						{/** TABLE */}
						<Table
							locale={{
								emptyText: (
									<TableNoDataBox
										isError={isError}
										errorMessage={
											error && error.data && error.data.message
												? error.data.message
												: "Error"
										}
										noItemsFound='No data found'
									/>
								),
							}}
							loading={isLoading}
							ref={tableRef}
							rowClassName={(record, index) =>
								getTableRowClass(index, chartReportTheme)
							}
							dataSource={data}
							size='small'
							className='mt-3'
							pagination={{
								showSizeChanger: true,
								pageSizeOptions: ["10", "20", "50", "100"],
							}}
							scroll={{ x: 900 }}
							footer={() => (
								<div className='generalStyles__flexEnd'>
									<Tag
										style={{
											color: chartReportTheme === "light" ? "black" : "",
										}}
									>
										{total} Records
									</Tag>
								</div>
							)}
							expandable={
								displayExpandibleRowTable
									? {
											// eslint-disable-next-line object-shorthand
											expandedRowRender: expandedRowRender,
											// eslint-disable-next-line object-shorthand
											expandIcon: customExpandIcon,
									  }
									: {}
							}
						>
							{/** DINAMIC COLUMNS */}
							{filters.tableSelectedColumns.map(
								(column: string, indexColumn: number) => (
									<Column
										onCell={(record) => ({
											onClick: () => {
												onCellNameClick(record);
											},
										})}
										title={dinamicColumnTitle(column)}
										dataIndex={column}
										key={indexColumn}
										sorter={(a: any, b: any) => {
											if (a && b && column && a[column] && b[column]) {
												return a[column].localeCompare(b[column]);
											}
											return 0;
										}}
										render={(text: string) => <div>{text}</div>}
									/>
								)
							)}
							{/** DINAMIC GROUP COLUMNS */}
							{groupColumns && groupColumns.length > 0 && (
								<>
									{groupColumns.map((column: any, indexColumn: number) => (
										<ColumnGroup title={column.name}>
											{column && column.data && (
												<>
													{column.data.map((item: string) => (
														<Column
															title={item.substring(4)}
															dataIndex={item}
															key={indexColumn}
															align='center'
														/>
													))}
												</>
											)}
										</ColumnGroup>
									))}
								</>
							)}
						</Table>
					</>
				) : (
					<>
						<div className='generalStyles__noAccessToListTable'>
							<Empty
								description={t(
									"organizationManagement.listNotAvailableOrganizations"
								)}
								image={Empty.PRESENTED_IMAGE_SIMPLE}
							/>
						</div>
					</>
				)}
				{/** ******************************************* */}
				{/** REPORT DRAWER BY ROW */}
				<ReportsDrawer />
				{/** ******************************************* */}
				{/** DOWNLOAD REPORT */}
				<ReportsAlertDownloadModal setDownloadReport={setDownloadReport} />
			</div>
		</Spin>
	);
}

export default ReportsTable;
