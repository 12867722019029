import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";

function TableColumnAuthType({ authType }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{authType === "PASSWORD" && (
				<Tag color='cyan' className='drawer__typeTag'>
					{authType}
				</Tag>
			)}
			{authType === "APIKEY" && (
				<Tag color='purple' className='drawer__typeTag'>
					{authType}
				</Tag>
			)}
			{authType === "" && (
				<Tag color='red' className='drawer__typeTag'>
					N/A
				</Tag>
			)}{" "}
		</div>
	);
}

export default TableColumnAuthType;
