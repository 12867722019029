import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faUserShield,
	faBuildingCircleCheck,
	faUserLock,
	faCheckCircle,
	faBan,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerBank } from "../../../redux/banks/bankSlice";
// COMPONENTS
import DrawerBankInformation from "../../components/drawer/DrawerBankInformation";
import DrawerChannelInformation from "../../components/drawer/DrawerChannelInformation";
import DrawerServerInformation from "../../components/drawer/DrawerServerInformation";
import DrawerOrganizationInformation from "../../components/drawer/DrawerOrganizationInformation";
import DrawerAdapterInformation from "../../components/drawer/DrawerAdapterInformation";
import DrawerLiftsAssigned from "../../components/drawer/DrawerLiftsAssigned";
import DrawerBanksActions from "../../components/drawer/DrawerBanksActions";

library.add(faUserShield, faBuildingCircleCheck, faUserLock, faCheckCircle, faBan);

function BanksDrawer() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>({});

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { bank, openDrawerBank } = useSelector((state: any) => state.bank);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerBank(false));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openDrawerBank) {
			if (bank) {
				setData(bank);
			}
		}
	}, [openDrawerBank]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer width='650' placement='right' onClose={onClickClose} closable={false} open={openDrawerBank}>
			{data && (
				<div>
					{/** ************************************************* */}
					{/** BANK INFORMATION */}
					<DrawerBankInformation data={bank || {}} />
					{/** ************************************************* */}
					{/** ORGANIZATION INFORMATION */}
					<DrawerOrganizationInformation
						data={bank && bank.channel && bank.channel.server && bank.channel.server.org ? bank.channel.server.org : {}}
					/>
					{/** ************************************************* */}
					{/** SERVER INFORMATION */}
					<DrawerServerInformation data={bank && bank.channel && bank.channel.server ? bank.channel.server : {}} />
					{/** ************************************************* */}
					{/** CHANNELS INFORMATION */}
					<DrawerChannelInformation data={bank && bank.channel ? bank.channel : []} />
					{/** ************************************************* */}
					{/** ADAPTER INFORMATION */}
					<DrawerAdapterInformation data={bank && bank.adapter ? bank.adapter : []} />
					{/** ************************************************* */}
					{/** LIFTS ASSIGNED */}
					<DrawerLiftsAssigned data={bank && bank.lifts ? bank.lifts : []} />
					{/** ************************************************* */}
					{/** BANK ACTIONS ASSIGNED */}
					<DrawerBanksActions data={bank && bank.actions ? bank.actions : []} />
				</div>
			)}
			{/** ************************************************* */}
			{/** CLOSE AND SUBMIT BUTTONS */}
			<div className='mt-4 generalStyles__width100'>
				<div>
					<Button type='default' onClick={onClickClose} className='buttonStyle__5'>
						{t("organizationManagement.close")}
					</Button>
				</div>
			</div>
		</Drawer>
	);
}

export default BanksDrawer;
