import React from "react";
// ANT DESIGN COMPONENTS
import { Tag } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCheckCircle, faCircleExclamation, faUser } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import DrawerRowDivider from "./DrawerRowDivider";

function DrawerUserInformation({ data }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: any) => state.home);
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<br />
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faUser} title={t("userManagement.userInformation")} />
			{/** ************************************************* */}
			{/** USER INFORMATION */}
			<div className={`drawer__box__${theme}`}>
				{/* USER LOGGED ID */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.userId")}</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>{data.id ? data.id : "--"}</p>
					</div>
				</div>
				{/* FIRST NAME */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.firstName")}</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>{data.first_name ? data.first_name : "--"}</p>
					</div>
				</div>
				{/* MIDDLE NAME */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.middleName")}</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>{data.middle_name ? data.middle_name : "--"}</p>
					</div>
				</div>
				{/* LAST NAME */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.lastName")}</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>{data.last_name ? data.last_name : "--"}</p>
					</div>
				</div>
				{/* EMAIL */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.email")}</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>{data.email ? data.email : "--"}</p>
					</div>
				</div>
				{/* ADDRESS */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.address")}</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data.contact_info && data.contact_info[0] && data.contact_info[0].address
								? data.contact_info[0].address
								: "--"}
						</p>
					</div>
				</div>
				{/* PHONE */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.phone")}</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data.contact_info && data.contact_info[0] && data.contact_info[0].phone
								? data.contact_info[0].phone
								: "--"}
						</p>
					</div>
				</div>
				{/* PHONE CODE */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.dialCode")}</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data.contact_info && data.contact_info[0] && data.contact_info[0].phone_code
								? data.contact_info[0].phone_code
								: "--"}
						</p>
					</div>
				</div>
				{/* STATUS */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>{t("userManagement.status")}</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data.status ? (
								<>
									{data.status === "ACTIVE" && (
										<Tag
											color='success'
											icon={<FontAwesomeIcon icon={faCheckCircle} className='generalStyles__mrFix' />}
											className='drawer__tag'
										>
											Active
										</Tag>
									)}
									{data.status === "NEW" && (
										<Tag
											color='warning'
											icon={<FontAwesomeIcon icon={faCircleExclamation} className='generalStyles__mrFix' />}
											className='drawer__tag'
										>
											New user
										</Tag>
									)}
									{data.status === "INACTIVE" && (
										<Tag
											color='error'
											icon={<FontAwesomeIcon icon={faBan} className='generalStyles__mrFix' />}
											className='drawer__tag'
										>
											Inactive
										</Tag>
									)}
								</>
							) : (
								"--"
							)}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DrawerUserInformation;
